import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface ModalsState {
  isNewVendorOrderModalVisible: boolean
  isEditVendorOrderModalVisible: boolean
  isDeleteOrderModalVisible: boolean
  isDeleteOrderModalForVendorVisible: boolean
  isNewOrderModalVisible: boolean
  isOrderFulfillmentModalVisible: boolean
  isAddTechnicianModalVisible: boolean
  isEditTechnicianModalVisible: boolean
  isEditOrderModalVisible: boolean
  isAutoRestockModalVisible: boolean
  isTransferModalVisible: boolean
  isMissingModalForWarehouseVisible: boolean
  isMissingModalForVendorVisible: boolean
  isMissingModalForContractorVisible: boolean
  isTermsOfUseModalVisible: boolean
  isDisableContractorModalVisible: boolean
  isAddContractorModalVisible: boolean
  isEditContractorModalVisible: boolean
  isAlertsFreezeModalVisible: boolean
  isAlertsUnfreezeModalVisible: boolean
  isAlertsAcknowledgeModalVisible: boolean
  isDeleteMessageTemplateModalVisible: boolean
  isShippingOrderModalVisible: boolean
  isShippingWarningModalVisible: boolean
  isDamageDeviceModalForWarehouseVisible: boolean
  isDamageDeviceModalForVendorVisible: boolean
  isUndamageDeviceModalForVendorVisible: boolean
  isAddWarehouseModalVisible: boolean
  isEditWarehouseModalVisible: boolean
  isAddUserModalVisible: boolean
  isAddDeviceModalVisible: boolean
  isEditUserModalVisible: boolean
  isTransferDeviceModalVisible: boolean
  isReturnToWarehouseModalVisible: boolean
  isWarrantyModalVisible: boolean
  isAlertWarrantyModalVisible: boolean
  isDeviceHistoryLogModalVisible: boolean
  isPackingOrderModalVisible: boolean
  isAccountSettingsModalVisible: boolean
  isEditCustomerModalVisible: boolean
  isPerformanceTestActionsModalVisible: boolean
  isPerformanceTestConfirmModalVisible: boolean
  isPerformanceTestCancelModalVisible: boolean
  isPerformanceTestFailModalVisible: boolean
  isPerformanceTestReportModalVisible: boolean
  isEditColumnsModalVisible: boolean
  isStatsReportModalVisible: boolean
}

const initialState: ModalsState = {
  isNewVendorOrderModalVisible: false,
  isEditVendorOrderModalVisible: false,
  isDeleteOrderModalForVendorVisible: false,
  isDeleteOrderModalVisible: false,
  isNewOrderModalVisible: false,
  isOrderFulfillmentModalVisible: false,
  isAddTechnicianModalVisible: false,
  isEditTechnicianModalVisible: false,
  isEditOrderModalVisible: false,
  isAutoRestockModalVisible: false,
  isTransferModalVisible: false,
  isMissingModalForWarehouseVisible: false,
  isMissingModalForVendorVisible: false,
  isMissingModalForContractorVisible: false,
  isTermsOfUseModalVisible: false,
  isDisableContractorModalVisible: false,
  isAddContractorModalVisible: false,
  isEditContractorModalVisible: false,
  isAlertsFreezeModalVisible: false,
  isAlertsUnfreezeModalVisible: false,
  isAlertsAcknowledgeModalVisible: false,
  isDeleteMessageTemplateModalVisible: false,
  isShippingOrderModalVisible: false,
  isShippingWarningModalVisible: false,
  isDamageDeviceModalForWarehouseVisible: false,
  isDamageDeviceModalForVendorVisible: false,
  isUndamageDeviceModalForVendorVisible: false,
  isAddWarehouseModalVisible: false,
  isEditWarehouseModalVisible: false,
  isAddUserModalVisible: false,
  isAddDeviceModalVisible: false,
  isEditUserModalVisible: false,
  isTransferDeviceModalVisible: false,
  isReturnToWarehouseModalVisible: false,
  isWarrantyModalVisible: false,
  isAlertWarrantyModalVisible: false,
  isDeviceHistoryLogModalVisible: false,
  isPackingOrderModalVisible: false,
  isAccountSettingsModalVisible: false,
  isEditCustomerModalVisible: false,
  isPerformanceTestActionsModalVisible: false,
  isPerformanceTestConfirmModalVisible: false,
  isPerformanceTestCancelModalVisible: false,
  isPerformanceTestFailModalVisible: false,
  isPerformanceTestReportModalVisible: false,
  isEditColumnsModalVisible: false,
  isStatsReportModalVisible: false,
}

export const modalsSlise = createSlice({
  name: "modals",
  initialState,
  reducers: {
    showDeleteOrderModal: (state, action: PayloadAction<boolean>) => {
      state.isDeleteOrderModalVisible = action.payload
    },
    showDeleteOrderModalForVendor: (state, action: PayloadAction<boolean>) => {
      state.isDeleteOrderModalForVendorVisible = action.payload
    },
    showNewOrderModal: (state, action: PayloadAction<boolean>) => {
      state.isNewOrderModalVisible = action.payload
    },
    showNewVendorOrderModal: (state, action: PayloadAction<boolean>) => {
      state.isNewVendorOrderModalVisible = action.payload
    },
    showEditVendorOrderModal: (state, action: PayloadAction<boolean>) => {
      state.isEditVendorOrderModalVisible = action.payload
    },
    showOrderFulfillmentModal: (state, action: PayloadAction<boolean>) => {
      state.isOrderFulfillmentModalVisible = action.payload
    },
    showAddTechnicianModal: (state, action: PayloadAction<boolean>) => {
      state.isAddTechnicianModalVisible = action.payload
    },
    showEditTechnicianModal: (state, action: PayloadAction<boolean>) => {
      state.isEditTechnicianModalVisible = action.payload
    },
    showEditOrderModal: (state, action: PayloadAction<boolean>) => {
      state.isEditOrderModalVisible = action.payload
    },
    showAutoRestockModal: (state, action: PayloadAction<boolean>) => {
      state.isAutoRestockModalVisible = action.payload
    },
    showTransferModal: (state, action: PayloadAction<boolean>) => {
      state.isTransferModalVisible = action.payload
    },
    showMissingModalForWarehouse: (state, action: PayloadAction<boolean>) => {
      state.isMissingModalForWarehouseVisible = action.payload
    },
    showMissingModalForVendor: (state, action: PayloadAction<boolean>) => {
      state.isMissingModalForVendorVisible = action.payload
    },
    showMissingModalForContractor: (state, action: PayloadAction<boolean>) => {
      state.isMissingModalForContractorVisible = action.payload
    },
    showTermsOfUseModal: (state, action: PayloadAction<boolean>) => {
      state.isTermsOfUseModalVisible = action.payload
    },
    showDisableContractorModal: (state, action: PayloadAction<boolean>) => {
      state.isDisableContractorModalVisible = action.payload
    },
    showAddContractorModal: (state, action: PayloadAction<boolean>) => {
      state.isAddContractorModalVisible = action.payload
    },
    showEditContractorModal: (state, action: PayloadAction<boolean>) => {
      state.isEditContractorModalVisible = action.payload
    },
    showAlertsFreezeModal: (state, action: PayloadAction<boolean>) => {
      state.isAlertsFreezeModalVisible = action.payload
    },
    showAlertsUnfreezeModal: (state, action: PayloadAction<boolean>) => {
      state.isAlertsUnfreezeModalVisible = action.payload
    },
    showAlertsAcknowledgeModal: (state, action: PayloadAction<boolean>) => {
      state.isAlertsAcknowledgeModalVisible = action.payload
    },
    showDeleteMessageTemplateModal: (state, action: PayloadAction<boolean>) => {
      state.isDeleteMessageTemplateModalVisible = action.payload
    },
    showShippingOrderModal: (state, action: PayloadAction<boolean>) => {
      state.isShippingOrderModalVisible = action.payload
    },
    showShippingWarningModal: (state, action: PayloadAction<boolean>) => {
      state.isShippingWarningModalVisible = action.payload
    },
    showDamageDeviceModalForWarehouse: (state, action: PayloadAction<boolean>) => {
      state.isDamageDeviceModalForWarehouseVisible = action.payload
    },
    showDamageDeviceModalForVendor: (state, action: PayloadAction<boolean>) => {
      state.isDamageDeviceModalForVendorVisible = action.payload
    },
    showUndamageDeviceModalForVendor: (state, action: PayloadAction<boolean>) => {
      state.isUndamageDeviceModalForVendorVisible = action.payload
    },
    showAddWarehouseModal: (state, action: PayloadAction<boolean>) => {
      state.isAddWarehouseModalVisible = action.payload
    },
    showEditWarehouseModal: (state, action: PayloadAction<boolean>) => {
      state.isEditWarehouseModalVisible = action.payload
    },
    showAddUserModal: (state, action: PayloadAction<boolean>) => {
      state.isAddUserModalVisible = action.payload
    },
    showAddDeviceModal: (state, action: PayloadAction<boolean>) => {
      state.isAddDeviceModalVisible = action.payload
    },
    showEditUserModal: (state, action: PayloadAction<boolean>) => {
      state.isEditUserModalVisible = action.payload
    },
    showTransferDeviceModal: (state, action: PayloadAction<boolean>) => {
      state.isTransferDeviceModalVisible = action.payload
    },
    showReturnToWarehouseModal: (state, action: PayloadAction<boolean>) => {
      state.isReturnToWarehouseModalVisible = action.payload
    },
    showWarrantyModal: (state, action: PayloadAction<boolean>) => {
      state.isWarrantyModalVisible = action.payload
    },
    showDeviceHistoryLogModal: (state, action: PayloadAction<boolean>) => {
      state.isDeviceHistoryLogModalVisible = action.payload
    },
    showPackingOrderModal: (state, action: PayloadAction<boolean>) => {
      state.isPackingOrderModalVisible = action.payload
    },
    showAccountSettingsModal: (state, action: PayloadAction<boolean>) => {
      state.isAccountSettingsModalVisible = action.payload
    },
    showEditCustomerModal: (state, action: PayloadAction<boolean>) => {
      state.isEditCustomerModalVisible = action.payload
    },
    showAlertWarrantyModal: (state, action: PayloadAction<boolean>) => {
      state.isAlertWarrantyModalVisible = action.payload
    },
    showPerformanceTestActionsModal: (state, action: PayloadAction<boolean>) => {
      state.isPerformanceTestActionsModalVisible = action.payload
    },
    showPerformanceTestConfirmModal: (state, action: PayloadAction<boolean>) => {
      state.isPerformanceTestConfirmModalVisible = action.payload
    },
    showPerformanceTestCancelModal: (state, action: PayloadAction<boolean>) => {
      state.isPerformanceTestCancelModalVisible = action.payload
    },
    showPerformanceTestFailModal: (state, action: PayloadAction<boolean>) => {
      state.isPerformanceTestFailModalVisible = action.payload
    },
    showPerformanceTestReportModal: (state, action: PayloadAction<boolean>) => {
      state.isPerformanceTestReportModalVisible = action.payload
    },
    showEditColumnsModal: (state, action: PayloadAction<boolean>) => {
      state.isEditColumnsModalVisible = action.payload
    },
    showStatsReportModal: (state, action: PayloadAction<boolean>) => {
      state.isStatsReportModalVisible = action.payload
    },
  },
})

export const {
  showDeleteOrderModal,
  showDeleteOrderModalForVendor,
  showNewOrderModal,
  showNewVendorOrderModal,
  showEditVendorOrderModal,
  showOrderFulfillmentModal,
  showAddTechnicianModal,
  showEditTechnicianModal,
  showEditOrderModal,
  showTransferModal,
  showMissingModalForWarehouse,
  showMissingModalForVendor,
  showMissingModalForContractor,
  showTermsOfUseModal,
  showDisableContractorModal,
  showAutoRestockModal,
  showAddContractorModal,
  showEditContractorModal,
  showAlertsFreezeModal,
  showAlertsUnfreezeModal,
  showAlertsAcknowledgeModal,
  showDeleteMessageTemplateModal,
  showShippingOrderModal,
  showShippingWarningModal,
  showDamageDeviceModalForWarehouse,
  showDamageDeviceModalForVendor,
  showUndamageDeviceModalForVendor,
  showAddWarehouseModal,
  showEditWarehouseModal,
  showAddUserModal,
  showEditUserModal,
  showAddDeviceModal,
  showTransferDeviceModal,
  showReturnToWarehouseModal,
  showWarrantyModal,
  showDeviceHistoryLogModal,
  showPackingOrderModal,
  showAccountSettingsModal,
  showEditCustomerModal,
  showAlertWarrantyModal,
  showPerformanceTestActionsModal,
  showPerformanceTestConfirmModal,
  showPerformanceTestCancelModal,
  showPerformanceTestFailModal,
  showPerformanceTestReportModal,
  showEditColumnsModal,
  showStatsReportModal,
} = modalsSlise.actions

export default modalsSlise.reducer
