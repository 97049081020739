import { toast } from "react-toastify"
import ConfirmationModal from "../../../modals/ConfirmationModal/ConfirmationModal"
import { useLazyChangeContractorsDevicesStatusQuery } from "../../../store/api/contractorsApi"
import { useLazyGetDevicesQuery } from "../../../store/api/devicesApi"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { ReactComponent as IconTransfer } from "../../../assets/svg/modal-icon-trasfer.svg"
import { setSelectedDeviceIDs } from "../../../store/tempDataSlise"
import { ApiTypes } from "../../../types"
import useUrlQueryParams from "../../../hooks/useUrlQueryParams"

interface PreinstalledConfirmationModalProps {
  selectedDevices: ApiTypes.Model.Device[]
  onCancel: () => void
}

const PreinstalledConfirmationModal = ({ onCancel, selectedDevices }: PreinstalledConfirmationModalProps) => {
  const [triggerChangeContractorsDevicesStatus, { isFetching }] = useLazyChangeContractorsDevicesStatusQuery()
  const [triggerGetDevices] = useLazyGetDevicesQuery()
  const dispatch = useAppDispatch()
  const { urlQueryParams } = useUrlQueryParams()

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      const res: any = await triggerChangeContractorsDevicesStatus({
        devices: selectedDevices.map((device) => device.sn),
        new_status: "preinstalled",
      })
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("The status of the selected device(s) was changed successfully!")
        dispatch(setSelectedDeviceIDs([]))
        triggerGetDevices(urlQueryParams)
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ConfirmationModal
      title="Pre-Installed"
      message="Are you sure you want to change the status of the device to Pre-Installed?"
      isFetching={isFetching}
      icon={<IconTransfer />}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}

export default PreinstalledConfirmationModal
