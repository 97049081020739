import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconOrderConfirmation } from "../../assets/svg/modal-icon-order-confirmation.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import styles from "./index.module.scss"
import { useLazyFulfillOrderQuery } from "../../store/api/ordersApi"
import {
  useLazyGetWarehouseWidgetInfoQuery,
  useLazyGetOrderStatusWidgetQuery,
  useLazyGetVendorOrdersQuery,
} from "../../store/api/vendorApi"
import { setCurrentOrder } from "../../store/tempDataSlise"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { toast } from "react-toastify"
import dayjs from "dayjs"
import { checkRoleType } from "../../utils/checkRoleType"
import useUrlQueryParams from "../../hooks/useUrlQueryParams"

interface Props {
  onCancel: () => void
}

export const OrderFulfillmentModal = ({ onCancel }: Props) => {
  const [triggerFulfillOrder, { isFetching }] = useLazyFulfillOrderQuery()
  const [triggerGetWarehouseWidgetInfo] = useLazyGetWarehouseWidgetInfoQuery()
  const [triggerGetOrderStatusWidget] = useLazyGetOrderStatusWidgetQuery()
  const [triggerGetVendorOrders] = useLazyGetVendorOrdersQuery()
  const dispatch = useAppDispatch()
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const { urlQueryParams } = useUrlQueryParams()
  const currentOrder = useAppSelector((state) => state.tempData.currentOrder)

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      const res: any = await triggerFulfillOrder(currentOrder?.id!)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Order has been successfully fulfilled!")
        triggerGetVendorOrders(urlQueryParams)
        dispatch(setCurrentOrder(undefined))
        onCancel()

        if (checkRoleType(userProfile?.type) === "vendor-admin") {
          triggerGetWarehouseWidgetInfo(urlQueryParams)
          triggerGetOrderStatusWidget()
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
          <ModalHeader
            title="Order fulfillment confirmation"
            subtitle="Are you sure you want to confirm the fulfillment of the order?"
          >
            <IconOrderConfirmation />
          </ModalHeader>
          <div className={styles.modalContent}>
            <ul className={styles.orderItemsList}>
              <li className={styles.orderItem}>
                <span className={styles.orderItemName}>Order quantity:</span>
                <span className={styles.orderItemValue}>{currentOrder?.quantity}</span>
              </li>
              <li className={styles.orderItem}>
                <span className={styles.orderItemName}>Order creations date:</span>
                <span className={styles.orderItemValue}>{dayjs(currentOrder?.create_date).format("MM-DD-YYYY")}</span>
              </li>
            </ul>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
