import Joi from "joi"
import { fullUrlPattern } from "./regularExpressions"

// AUTH
export const signInSchema = Joi.object({
  email: Joi.string().required().messages({
    "string.email": "This email is not valid. Please check for spelling errors and try again.",
    "string.empty": "Email or Username is required",
  }),

  password: Joi.string().min(8).max(32).required().messages({
    "string.empty": "Password is required",
    "string.length":
      "Password must be at least 8 characters long, contain both upper and lower case characters, and include at least one number. Please ensure your password meets these requirements.",
  }),
})

export const forgotPassword = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": "This email is not valid. Please check for spelling errors and try again.",
      "string.empty": "Email is required",
    }),
})

export const changePassword = Joi.object({
  newPassword: Joi.string()
    .min(8)
    .max(32)
    .pattern(new RegExp("[a-z]"), "one lowercase letter") // Requires at least one lowercase letter
    .pattern(new RegExp("[A-Z]"), "one uppercase letter") // Requires at least one uppercase letter
    .pattern(new RegExp("[0-9]"), "one digit") // Requires at least one digit
    .required()
    .messages({
      "string.empty": "Password is required",
      "string.min":
        "Password must be at least 8 characters long, contain both upper and lower case characters, and include at least one number. Please ensure your password meets these requirements.",
      "string.max": "This password is too long. Please use no more than 32 characters.",
      "string.pattern.name": "{#label} must contain at least {#name}",
    }),

  confirmationPassword: Joi.string().valid(Joi.ref("newPassword")).required().messages({
    "any.only": "The passwords don't match",
  }),
})

// TECHNICIAN
export const createTechnicianSchema = Joi.object({
  fullName: Joi.string().required().messages({
    "string.empty": "Full Name is required",
  }),

  userName: Joi.string()
    .required()
    .pattern(/^[^\s@]+$/)
    .messages({
      "string.empty": "Username is required",
      "string.pattern.base": "Username should not contain spaces or @ symbol",
    }),

  password: Joi.string().min(8).max(32).required().messages({
    "string.empty": "Password is required",
    "string.length": "This password is too short. Please use at least 8 characters and try again.",
  }),
})

export const updateTechnicianSchema = Joi.object({
  fullName: Joi.string().required().messages({
    "string.empty": "Full Name is required",
  }),

  userName: Joi.string()
    .required()
    .pattern(/^[^\s@]+$/)
    .messages({
      "string.empty": "Username is required",
      "string.pattern.base": "Username should not contain spaces or @ symbol",
    }),

  password: Joi.string().empty("").min(8).max(32).messages({
    "string.empty": "Password is required",
    "string.length": "This password is too short. Please use at least 8 characters and try again.",
  }),

  isDisable: Joi.boolean().optional(),
})

export const warrantySchema = Joi.object({
  oldSN: Joi.string().required().messages({
    "string.empty": "Old S/N is required",
  }),
  newSN: Joi.string().required().messages({
    "string.empty": "New S/N is required",
  }),
})

// CONTRACTOR
export const contractorSchema = Joi.object({
  name: Joi.string().required().messages({
    "string.empty": "Company Name is required",
  }),

  brand: Joi.string().required().messages({
    "string.empty": "Brand Name is required",
  }),

  parentGroup: Joi.array().min(1).required().messages({
    "array.min": "Parent Group is required",
  }),

  address1: Joi.string().required().messages({
    "string.empty": "Address 1 is required",
  }),

  address2: Joi.string().empty("").allow(null),

  zip: Joi.array().min(1).required().messages({
    "array.min": "Zip code is required",
  }),

  state: Joi.array().min(1).required().messages({
    "array.min": "State is required",
  }),

  city: Joi.array().min(1).required().messages({
    "array.min": "City is required",
  }),

  country: Joi.array().min(1).required().messages({
    "array.min": "Country is required",
  }),

  url: Joi.string().pattern(fullUrlPattern).required().messages({
    "string.empty": "Company web URL is required",
    "string.pattern.base": "{#label} must be a valid uri",
  }),

  schedule: Joi.string().pattern(fullUrlPattern).required().messages({
    "string.empty": "Schedule URL is required",
    "string.pattern.base": "{#label} must be a valid uri",
  }),

  fullName: Joi.string().required().messages({
    "string.empty": "Full Name is required",
  }),

  phone: Joi.string()
    .pattern(/^\(\d{3}\)\d{3}-\d{4}$/)
    .required()
    .messages({
      "string.empty": "Phone number is required",
      "string.pattern.base": "Phone number must be in a valid format (e.g., (778)994-4241)",
    }),

  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": "This email is not valid. Please check for spelling errors and try again.",
      "string.empty": "Email is required",
    }),

  isCustom: Joi.boolean().empty(),

  appStoreLink: Joi.when("isCustom", {
    is: true,
    then: Joi.string().pattern(fullUrlPattern).required().messages({
      "string.empty": "App Store Link is required",
      "string.pattern.base": "{#label} must be a valid uri",
    }),
    otherwise: Joi.any(),
  }),

  googlePlayLink: Joi.when("isCustom", {
    is: true,
    then: Joi.string().pattern(fullUrlPattern).required().messages({
      "string.empty": "Google Play Store Link is required",
      "string.pattern.base": "{#label} must be a valid uri",
    }),
    otherwise: Joi.any(),
  }),

  phoneForSupport: Joi.boolean().empty(),

  phoneForSupportCall: Joi.when("phoneForSupport", {
    is: false,
    then: Joi.string()
      .allow("", null)
      .pattern(/^\(\d{3}\)\d{3}-\d{4}$/)
      .messages({
        "string.pattern.base": "Phone number for support call must be in a valid format (e.g., (778)994-4241)",
      }),
  }),

  phoneForSupportText: Joi.when("phoneForSupport", {
    is: false,
    then: Joi.string()
      .allow("", null)
      .pattern(/^\(\d{3}\)\d{3}-\d{4}$/)
      .messages({
        "string.pattern.base": "Phone number for support text must be in a valid format (e.g., (778)994-4241)",
      }),
  }),

  isDisable: Joi.boolean().empty(),
})

export const contractorSTIntegrationSchema = Joi.object({
  stClientID: Joi.string().required().messages({
    "string.empty": "Client ID is required",
  }),
  stClientSecret: Joi.string().required().messages({
    "string.empty": "Client Secret is required",
  }),
  stAppKey: Joi.string().required().messages({
    "string.empty": "App Key is required",
  }),
  stTenant: Joi.string().required().messages({
    "string.empty": "Tenant ID is required",
  }),
})

export const changeContractorPassword = Joi.object({
  currentPassword: Joi.string().min(8).max(32).required().messages({
    "string.empty": "Password is required",
    "string.min": "This password is too short. Please use at least 8 characters and try again.",
    "string.max": "This password is too long. Please use no more than 32 characters.",
  }),

  password: Joi.string()
    .min(8)
    .max(32)
    .pattern(new RegExp("[a-z]"), "one lowercase letter") // Requires at least one lowercase letter
    .pattern(new RegExp("[A-Z]"), "one uppercase letter") // Requires at least one uppercase letter
    .pattern(new RegExp("[0-9]"), "one digit") // Requires at least one digit
    .required()
    .messages({
      "string.empty": "Password is required",
      "string.min": "This password is too short. Please use at least 8 characters and try again.",
      "string.max": "This password is too long. Please use no more than 32 characters.",
      "string.pattern.name": "{#label} must contain at least {#name}",
    }),

  passwordConfirmation: Joi.string().valid(Joi.ref("password")).required().messages({
    "any.only": "The passwords don't match",
  }),
})

export const changeContractorProfile = Joi.object({
  fullName: Joi.string().required().messages({
    "string.empty": "Full Name is required",
  }),

  phone1: Joi.string()
    .pattern(/^\(\d{3}\)\d{3}-\d{4}$/)
    .required()
    .messages({
      "string.empty": "Phone number is required",
      "string.pattern.base": "Phone number must be in a valid format (e.g., (778)994-4241)",
    }),

  phoneForSupport: Joi.boolean().empty(),

  phoneForSupportCall: Joi.when("phoneForSupport", {
    is: false,
    then: Joi.string()
      .allow("", null)
      .pattern(/^\(\d{3}\)\d{3}-\d{4}$/)
      .messages({
        "string.pattern.base": "Phone number for support call must be in a valid format (e.g., (778)994-4241)",
      }),
  }),

  phoneForSupportText: Joi.when("phoneForSupport", {
    is: false,
    then: Joi.string()
      .allow("", null)
      .pattern(/^\(\d{3}\)\d{3}-\d{4}$/)
      .messages({
        "string.pattern.base": "Phone number for support text must be in a valid format (e.g., (778)994-4241)",
      }),
  }),

  image: Joi.any(),

  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": "This email is not valid. Please check for spelling errors and try again.",
      "string.empty": "Email is required",
    }),
})

export const changeContractorAdditionalInfo = Joi.object({
  companyName: Joi.string().required().messages({
    "string.empty": "Company Name is required",
  }),
  brandName: Joi.string().required().messages({
    "string.empty": "Brand Name is required",
  }),
  address1: Joi.string().required().messages({
    "string.empty": "Address is required",
  }),
  address2: Joi.string().empty("").allow(null),
  schedulerUrl: Joi.string().uri().required().messages({
    "string.empty": "Scheduler URL is required",
  }),
  url: Joi.string().uri().required().messages({
    "string.empty": "URL is required",
  }),
  zip: Joi.alternatives().try(Joi.number().required(), Joi.array().min(1).required()).messages({
    "string.empty": "Zip is required",
    "array.min": "Zip code is required",
  }),
  state: Joi.alternatives().try(Joi.number().required(), Joi.array().min(1).required()).messages({
    "string.empty": "State is required",
    "array.min": "State is required",
  }),
  country: Joi.alternatives().try(Joi.number().required(), Joi.array().min(1).required()).messages({
    "string.empty": "Country is required",
    "array.min": "Country is required",
  }),
  city: Joi.alternatives().try(Joi.number().required(), Joi.array().min(1).required()).messages({
    "string.empty": "City is required",
    "array.min": "City is required",
  }),
  logo: Joi.any(),
})

// INSTALL DEVICES
export const addCustomerSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": "This email is not valid. Please check for spelling errors and try again.",
      "string.empty": "Email is required",
    }),

  fullName: Joi.string().required().messages({
    "string.empty": "Full Name is required",
  }),

  phone: Joi.string()
    .pattern(/^\(\d{3}\)\d{3}-\d{4}$/)
    .required()
    .messages({
      "string.empty": "Phone number is required",
      "string.pattern.base": "Phone number must be in a valid format (e.g., (778)994-4241)",
    }),

  jobId: Joi.any().empty("").allow(null),
})

export const addDeviceSchema = Joi.object({
  _id: Joi.string().empty(""),

  deviceName: Joi.string().required(),

  serialNumber: Joi.string().required().messages({
    "string.empty": "S/N is required",
  }),

  address1: Joi.string().required().messages({
    "string.empty": "Address 1 is required",
  }),

  address2: Joi.string().empty(""),

  zip: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "Zip is required",
  }),

  state: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "State is required",
  }),

  city: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "City is required",
  }),

  residentialType: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "Residential Type is required",
  }),

  deviceLocation: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "Device Location is required",
  }),

  installationType: Joi.string(),
})

// MESSAGE TEMPLATES
export const createMessageTemplateSchema = Joi.object({
  title: Joi.string().required().max(64).messages({
    "string.empty": "Title is required",
    "string.max": "Title must be less than or equal to 64 characters",
  }),
  message: Joi.string().required().max(550).messages({
    "string.empty": "Message is required",
    "string.max": "Message must be less than or equal to 550 characters",
  }),
  frequency: Joi.string().required().messages({
    "string.empty": "Frequency is required",
  }),
})

// ADD WAREHOUSE
export const addWarehouseSchema = Joi.object({
  name: Joi.string().required().messages({
    "string.empty": "Name is required",
  }),
  zip: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "Zip is required",
  }),
  state: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "State is required",
  }),
  city: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "City is required",
  }),
  address: Joi.string().required().messages({
    "string.empty": "Address is required",
  }),

  phone: Joi.string()
    .pattern(/^\(\d{3}\)\d{3}-\d{4}$/)
    .required()
    .messages({
      "string.empty": "Phone number is required",
      "string.pattern.base": "Phone number must be in a valid format (e.g., (778)994-4241)",
    }),
})

// EDIT WAREHOUSE
export const editWarehouseSchema = Joi.object({
  name: Joi.string().required().messages({
    "string.empty": "Name is required",
  }),
  zip: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "Zip is required",
  }),
  state: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "State is required",
  }),
  city: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "City is required",
  }),
  address: Joi.string().required().messages({
    "string.empty": "Address is required",
  }),
  phone: Joi.string()
    .pattern(/^\(\d{3}\)\d{3}-\d{4}$/)
    .required()
    .messages({
      "string.empty": "Phone number is required",
      "string.pattern.base": "Phone number must be in a valid format (e.g., (778)994-4241)",
    }),
  isDisable: Joi.boolean().empty(),
})

// CUSTOMER
export const editCustomerCommonSchema = Joi.object({
  fullName: Joi.string().empty(""),
  homeownerID: Joi.number().empty(""),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": "This email is not valid. Please check for spelling errors and try again.",
      "string.empty": "Email is required",
    }),
  phone: Joi.string()
    .empty("")
    .pattern(/^\(\d{3}\)\d{3}-\d{4}$/)
    .messages({
      "string.pattern.base": "Phone number must be in a valid format (e.g., (778)994-4241)",
    }),
  membership: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "Device Membership is required",
  }),
})

export const editCustomerDeviceSchema = Joi.object({
  _id: Joi.string().empty(""),

  id: Joi.number().required(),

  deviceName: Joi.string().required(),

  serialNumber: Joi.string().required().messages({
    "string.empty": "S/N is required",
  }),

  address1: Joi.string().required().messages({
    "string.empty": "Address 1 is required",
  }),

  address2: Joi.string().empty(""),

  zip: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "Zip is required",
  }),

  state: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "State is required",
  }),

  city: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "City is required",
  }),

  country: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "Country is required",
  }),

  residentialType: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "Residential Type is required",
  }),

  deviceLocation: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "Device Location is required",
  }),

  installationType: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "Device Installation Type is required",
  }),
})

// USER
export const editUserSchema = Joi.object({
  fullName: Joi.string().required().messages({
    "string.empty": "Full Name is required",
  }),

  phone: Joi.string()
    .pattern(/^\(\d{3}\)\d{3}-\d{4}$/)
    .required()
    .messages({
      "string.empty": "Phone number is required",
      "string.pattern.base": "Phone number must be in a valid format (e.g., (778)994-4241)",
    }),

  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": "This email is not valid. Please check for spelling errors and try again.",
      "string.empty": "Email is required",
    }),

  useRole: Joi.alternatives().try(Joi.array().required().min(1)).messages({
    "array.min": "State is required",
  }),

  isDisable: Joi.boolean().empty(),
})

export const createUserSchema = Joi.object({
  fullName: Joi.string().required().messages({
    "string.empty": "Full Name is required",
  }),

  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": "This email is not valid. Please check for spelling errors and try again.",
      "string.empty": "Email is required",
    }),

  phone: Joi.string()
    .pattern(/^\(\d{3}\)\d{3}-\d{4}$/)
    .required()
    .messages({
      "string.empty": "Phone number is required",
      "string.pattern.base": "Phone number must be in a valid format (e.g., (778)994-4241)",
    }),

  useRole: Joi.array().required().min(1).messages({
    "any.required": "Role is required",
    "array.min": "Role is required",
  }),

  warehouse: Joi.when("useRole", {
    is: Joi.array().items(
      Joi.object({
        value: Joi.number().valid(8),
        label: Joi.string().valid("Warehouse Manager"),
      }),
    ),
    then: Joi.array().required().min(1).messages({
      "any.required": "Warehouse is required for Warehouse Managers",
      "array.min": "Warehouse is required for Warehouse Managers",
    }),
    otherwise: Joi.array(),
  }),
})
