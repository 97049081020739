import { FormEvent, SetStateAction, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { v4 as uuidv4 } from "uuid"
import ModalSectionTitle from "../../../components/common/ModalSectionTitle/ModalSectionTitle"
import { UITypes } from "../../../types"
import { InputNew } from "../../../components/form/InputNew"
import { InputTenantID } from "../../../components/form/InputTenantID"
import { contractorSTIntegrationSchema, onFormValidate } from "../../../utils/onValidate"
import { useLazyCheckServiceTitanQuery, useLazyGetSTMembershipsQuery } from "../../../store/api/contractorsApi"
import { SelectStatic } from "../../../components/form/SelectStatic"
import { Tooltip } from "../../../components/common/Tooltip"
import { convertDataToOptions } from "../../../utils/convertData"
import styles from "./index.module.scss"

interface Props {
  inputValues: UITypes.Contractor
  error: {
    field: string
    message: string
  }
  tenantIDStatus: UITypes.TenantIDStautus
  memberships: UITypes.Option[]
  setError: (value: SetStateAction<{ field: string; message: string }>) => void
  setTenantIDStatus: (value: SetStateAction<UITypes.TenantIDStautus>) => void
  setMemberships: (value: SetStateAction<UITypes.Option[]>) => void
  onInputChange: (event: FormEvent<HTMLInputElement>) => void
}

const IntegrationSection = ({
  inputValues,
  error,
  setError,
  onInputChange,
  tenantIDStatus,
  memberships,
  setTenantIDStatus,
  setMemberships,
}: Props) => {
  const [triggerCheckServiceTitan, { isFetching: isSTFetching }] = useLazyCheckServiceTitanQuery()
  const [triggerGetSTMemberships, { isFetching: isSTMembershipsFetching, data: membershipsData }] =
    useLazyGetSTMembershipsQuery()

  useEffect(() => {
    inputValues.isSTChecked == `1` && setTenantIDStatus("pass")

    inputValues.memberships?.length &&
      setMemberships(
        inputValues.memberships?.map((item) => ({
          value: item?.id,
          label: item?.name,
        })),
      )
  }, [])

  useEffect(() => {
    if (tenantIDStatus === "pass") {
      const fetchData = async () => {
        try {
          await triggerGetSTMemberships({
            client_id: inputValues.stClientID,
            client_secret: inputValues.stClientSecret,
            app_key: inputValues.stAppKey,
            tenant_id: inputValues.stTenant,
          })
        } catch (error) {
          console.error(error)
        }
      }

      fetchData()
    }
  }, [tenantIDStatus])

  const tenantIDHandler = () => {
    if (tenantIDStatus === "error") {
      setTenantIDStatus("check")
    }
    if (tenantIDStatus === "check") {
      onTenantIDCheck()
    }
    if (tenantIDStatus === "pass") {
      setTenantIDStatus("check")
    }
  }

  const onTenantIDCheck = () => {
    if (onFormValidate(inputValues, contractorSTIntegrationSchema, setError, true)) {
      sendTenantIDRequest()
    }
  }

  const sendTenantIDRequest = async () => {
    try {
      const res: any = await triggerCheckServiceTitan({
        client_id: inputValues.stClientID,
        client_secret: inputValues.stClientSecret,
        app_key: inputValues.stAppKey,
        tenant_id: inputValues.stTenant,
      })

      if (res?.error) {
        toast.error(res?.error?.data?.message)
        setTenantIDStatus("error")
      } else {
        setTenantIDStatus("pass")
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <ModalSectionTitle title="Sevicetitan Integrations">
        {
          <Tooltip id={uuidv4()} orientation="top" className={styles.titleInformationIcon}>
            Enter the credentials (if available) to enable integration between the Nuve application and ServiceTitan.
          </Tooltip>
        }
      </ModalSectionTitle>
      <div className={styles.row}>
        <InputNew
          label="Client ID"
          placeholder="Enter client ID"
          name="stClientID"
          value={inputValues.stClientID || ""}
          className={styles.inputText}
          onChange={onInputChange}
          errorMessage={error.field === "stClientID" ? error.message : ""}
        />
        <InputNew
          label="Client Secret"
          placeholder="Enter client secret"
          name="stClientSecret"
          value={inputValues.stClientSecret || ""}
          className={styles.inputText}
          onChange={onInputChange}
          errorMessage={error.field === "stClientSecret" ? error.message : ""}
        />
      </div>
      <div className={styles.row}>
        <InputNew
          label="API Key"
          placeholder="Enter API Key"
          name="stAppKey"
          value={inputValues.stAppKey || ""}
          className={styles.inputText}
          onChange={onInputChange}
          errorMessage={error.field === "stAppKey" ? error.message : ""}
        />
        <InputTenantID
          status={isSTFetching ? "loading" : tenantIDStatus}
          label="Tenant ID"
          placeholder="Tenant ID"
          name="stTenant"
          value={inputValues.stTenant || ""}
          onChange={onInputChange}
          buttonHandler={tenantIDHandler}
          errorMessage={error.field === "stTenant" ? error.message : ""}
        />
      </div>
      {tenantIDStatus === "pass" && (
        <div className={styles.row}>
          <SelectStatic
            label="Memberships"
            placeholder="Select Membership"
            multi={true}
            values={memberships}
            isLoading={isSTMembershipsFetching}
            options={convertDataToOptions(membershipsData, "id", "name")}
            onChange={setMemberships}
          />
        </div>
      )}
    </div>
  )
}

export default IntegrationSection
