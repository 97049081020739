import styles from "../../../assets/scss/index.module.scss"
import { useEffect, useState } from "react"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Pagination } from "../../../components/common/Pagination"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { CheckboxNew } from "../../../components/form/CheckboxNew"
import { UITypes } from "../../../types"
import { toast } from "react-toastify"
import { convertOrdersToTableRows } from "../../../utils/convertData"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
  showDeleteOrderModal,
  showNewOrderModal,
  showOrderFulfillmentModal,
  showEditOrderModal,
} from "../../../store/modalSlise"
import { useLazyGetFulfilledOrdersQuery, useLazyGetOrdersQuery } from "../../../store/api/ordersApi"
import { setCurrentOrder } from "../../../store/tempDataSlise"
import TableWrapper from "../../../components/table/TableWrapper"
import { orderStatusOptions } from "../../../constants/SelectFieldOptions"
import useUrlQueryParams from "../../../hooks/useUrlQueryParams"
import useExportCSV from "../../../hooks/useExportCSV"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "",
  },
  {
    title: "Order number",
    type: "sort-search",
    name: "id",
    size: "middle",
  },
  {
    title: "Creation Date",
    type: "sort-date",
    name: "create_date",
    size: "middle",
  },
  {
    title: "Quantity",
    type: "sort-search",
    name: "quantity",
    size: "middle",
  },
  {
    title: "Status",
    type: "select",
    name: "status",
    options: orderStatusOptions,
  },
  {
    title: "Warehouse",
    type: "sort-search",
    name: "warehouse",
  },
]

export const Orders = () => {
  const dispatch = useAppDispatch()
  const [triggerGetOrders, { isFetching: isOrdersFetching, data: ordersData }] = useLazyGetOrdersQuery()
  const [triggerGetFulfilledOrders, { isFetching: isFulfilledFetching, data: fulfilledData }] =
    useLazyGetFulfilledOrdersQuery()
  const { urlQueryParams } = useUrlQueryParams()
  const currentOrder = useAppSelector((state) => state.tempData.currentOrder)
  const [isFulfillChecked, checkFulfill] = useState(false)
  const [isFirstRender, setFirstRender] = useState(true)

  const relevantData = isFulfillChecked ? fulfilledData : ordersData
  const relevantFetching = isFulfillChecked ? isFulfilledFetching : isOrdersFetching

  const { handleExport } = useExportCSV({
    params: urlQueryParams,
    headerCells: tableHeaderCells,
    meta: relevantData?.meta,
    fileName: "orders",
    convertData: convertOrdersToTableRows,
    useLazyQuery: isFulfillChecked ? useLazyGetFulfilledOrdersQuery : useLazyGetOrdersQuery,
  })

  const onSetCurrentOrder = (id?: number) => {
    const ordersList = relevantData?.data

    if (!id || !ordersList?.length) {
      dispatch(setCurrentOrder(undefined))
    } else {
      const currentOrder = ordersList.filter((item) => item.id === id)[0]
      dispatch(setCurrentOrder(currentOrder))
    }
  }

  const onEditClick = (id: number) => {
    onSetCurrentOrder(id)
    dispatch(showEditOrderModal(true))
  }

  const onDeleteClick = (id: number) => {
    onSetCurrentOrder(id)
    dispatch(showDeleteOrderModal(true))
  }

  const onNewClick = () => {
    dispatch(showNewOrderModal(true))
  }

  const onFulfillClick = () => {
    if (!checkFulfillAbility()) return false
    dispatch(showOrderFulfillmentModal(true))
  }

  const onCheckboxChange = (id: number) => {
    onSetCurrentOrder(id === currentOrder?.id ? undefined : id)
  }

  const checkFulfillAbility = () => {
    return currentOrder?.id && currentOrder?.status === "shipping" ? true : false
  }

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = isFulfillChecked ? await triggerGetFulfilledOrders(params) : await triggerGetOrders(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(urlQueryParams)
  }, [urlQueryParams, isFirstRender, isFulfillChecked])

  return (
    <>
      <ContentWrapper title="Orders history">
        <nav className={styles.tableNavWrapper}>
          <div>
            <ButtonNew onClick={onNewClick} color="blue">
              New order
            </ButtonNew>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <ButtonNew
              disabled={!checkFulfillAbility()}
              onClick={onFulfillClick}
              color={!checkFulfillAbility() ? "grey" : "blue"}
            >
              Fulfill
            </ButtonNew>
            <CheckboxNew
              className={styles.tableNavItem}
              isReverse={true}
              id="fulfilled"
              isChecked={isFulfillChecked}
              onChange={(event) => checkFulfill(event?.currentTarget?.checked)}
            >
              Show fulfilled
            </CheckboxNew>
            <ButtonNew
              onClick={handleExport}
              className={styles.tableNavItem}
              disabled={relevantData?.meta?.total === 0}
            >
              Export
            </ButtonNew>
          </div>
        </nav>
        <TableWrapper
          tableName="contractor-orders"
          headerCells={tableHeaderCells}
          bodyRows={convertOrdersToTableRows(relevantData?.data)}
          isLoading={relevantFetching}
          currentItemID={currentOrder?.id}
          onCheckboxChange={onCheckboxChange}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
          clearAdditionalTags={() => dispatch(setCurrentOrder(undefined))}
          additionalTags={
            currentOrder
              ? [
                  {
                    label: "Order number",
                    values: [currentOrder.id],
                  },
                ]
              : []
          }
        />
        <Pagination meta={relevantData?.meta} />
      </ContentWrapper>
    </>
  )
}
