import { ApiTypes, UITypes } from "../types"
import { TypedUseLazyQuery } from "@reduxjs/toolkit/dist/query/react"
import downloadFile from "../utils/downloadFile"

const PER_PAGE = 100

interface Props {
  params: UITypes.Params
  headerCells: UITypes.TableHeaderCell[]
  meta: ApiTypes.Model.Pagination | undefined
  fileName: string
  convertData: (data: any, headerCells: UITypes.TableHeaderCell[]) => UITypes.TableBodyRow[]
  useLazyQuery: TypedUseLazyQuery<any, any, any>
}

const useExportCSV = ({ params, headerCells, meta, fileName, convertData, useLazyQuery }: Props) => {
  const [triggerQuery] = useLazyQuery()

  const isShowCell = (cell: UITypes.TableBodyCell) => {
    const headerCell = headerCells.find((headerCell) => headerCell.name !== undefined && headerCell.name === cell.name)

    if (headerCell?.name !== "checkbox" && headerCell?.visibility !== false && cell.type !== "checkbox") {
      return true
    }

    return false
  }

  const getCellValue = (cell: UITypes.TableBodyCell) => {
    const headerCell = headerCells.find((headerCell) => headerCell.name === cell.name)

    if (headerCell?.type === "select") {
      return headerCell.options?.find((option) => option.value === cell.value)?.label || cell.value
    }

    return cell.value
  }

  const handleExport = async () => {
    const total = meta?.total || 0
    const pages = Math.ceil(total / PER_PAGE)

    const response = await Promise.all(
      Array.from({ length: pages }).map((_, i) => triggerQuery({ ...params, page: i + 1, per_page: PER_PAGE })),
    )

    const data = response.map((res) => res.data.data).flat()
    const parsedData = convertData(data, headerCells)
    const headerRow = headerCells
      .filter((cell) => cell.visibility !== false && cell.type !== "checkbox" && cell.type !== undefined)
      .map((cell) => cell.label || cell.title)
    const CSVdata = [
      headerRow,
      ...parsedData.map((row) => row.cells.filter((cell) => isShowCell(cell)).map((cell) => getCellValue(cell))),
    ]

    downloadFile(
      [
        CSVdata.map((row) =>
          row
            .map((item) => (typeof item === "string" ? item.replaceAll('"', '""') : item))
            .map((item) => (item?.toString().includes(",") ? `"${item}"` : item)),
        ).join("\n"),
      ],
      `${fileName}.csv`,
    )
  }

  return { handleExport }
}

export default useExportCSV
