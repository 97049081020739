import { FunctionComponent, SVGProps } from "react"
import styled from "styled-components/macro"

const Container = styled.div`
  display: flex;
  gap: 8px;
  background: #f8fafc;
  height: 32px;
  margin-bottom: 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;
`

const Title = styled.h4`
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #64748b;
`

interface ModalSectionTitleProps {
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  title: string
  children?: React.ReactNode
}

const ModalSectionTitle = ({ Icon, title, children }: ModalSectionTitleProps) => {
  return (
    <Container>
      {Icon && <Icon />}
      <Title>{title}</Title>
      {children}
    </Container>
  )
}

export default ModalSectionTitle
