import { useEffect } from "react"
import classNames from "classnames"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Pagination } from "../../../components/common/Pagination"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { ApiTypes, UITypes } from "../../../types"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { setCurrentAlert } from "../../../store/tempDataSlise"
import { convertAlertsToTableRows } from "../../../utils/convertData"
import { showAlertsFreezeModal, showAlertsAcknowledgeModal, showAlertsUnfreezeModal } from "../../../store/modalSlise"
import TableWrapper from "../../../components/table/TableWrapper"
import styles from "./index.module.scss"
import { alertStatusOptions, alertTypeOptions } from "../../../constants/SelectFieldOptions"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "",
  },
  {
    title: "Alerts",
    type: "select",
    name: "type",
    options: alertTypeOptions,
  },
  {
    title: "Date",
    type: "sort-date",
    name: "date",
  },
  {
    title: "Device S/N",
    type: "sort-search",
    name: "sn",
  },
  {
    title: "Status",
    type: "select",
    name: "status",
    options: alertStatusOptions,
  },
  {
    title: "Country",
    type: "sort-search",
    name: "country",
  },
  {
    title: "Address",
    type: "sort-search",
    name: "address1",
  },
  {
    title: "City",
    type: "sort-search",
    name: "city",
  },
  {
    title: "State",
    type: "sort-search",
    name: "state",
  },
]

interface AlertsPageContentProps {
  alertsList: ApiTypes.Model.Alert[]
  isFetching: boolean
  meta: ApiTypes.Model.Pagination | undefined
}

export const AlertsPageContent = ({ alertsList, isFetching, meta }: AlertsPageContentProps) => {
  const dispatch = useAppDispatch()
  const currentAlert = useAppSelector((state) => state.tempData.currentAlert)

  const onSetCurrentAlert = (id?: number) => {
    if (!id || !alertsList?.length) {
      dispatch(setCurrentAlert(undefined))
    } else {
      const currentAlert = alertsList.filter((item) => item.id === id)[0]
      dispatch(setCurrentAlert(currentAlert))
    }
  }

  const onCheckboxChange = (id: number) => {
    onSetCurrentAlert(id === currentAlert?.id ? undefined : id)
  }

  const onAcknowledgeClick = () => {
    dispatch(showAlertsAcknowledgeModal(true))
  }

  const onFreezeClick = () => {
    dispatch(showAlertsFreezeModal(true))
  }

  const onUnfreezeClick = () => {
    dispatch(showAlertsUnfreezeModal(true))
  }

  return (
    <ContentWrapper title="Alerts History">
      <nav className={styles.tableNavWrapper}>
        <div></div>
        <div className={styles.tableNavButtonsWrapper}>
          {currentAlert ? (
            <ButtonNew color="blue" onClick={onAcknowledgeClick} className={classNames(styles.tableNavItem)}>
              Acknowledge
            </ButtonNew>
          ) : (
            <ButtonNew color="grey" disabled className={classNames(styles.tableNavItem)}>
              Acknowledge
            </ButtonNew>
          )}
          {currentAlert ? (
            currentAlert?.status === "frozen" ? (
              <ButtonNew onClick={onUnfreezeClick} className={styles.tableNavItem}>
                Unfreeze
              </ButtonNew>
            ) : (
              <ButtonNew onClick={onFreezeClick} className={styles.tableNavItem}>
                Freeze
              </ButtonNew>
            )
          ) : (
            <ButtonNew color="grey" disabled className={styles.tableNavItem}>
              Freeze
            </ButtonNew>
          )}
        </div>
      </nav>
      <TableWrapper
        headerCells={tableHeaderCells}
        bodyRows={convertAlertsToTableRows(alertsList)}
        isLoading={isFetching}
        onCheckboxChange={onCheckboxChange}
        currentItemID={currentAlert?.id}
      />
      <Pagination meta={meta} />
    </ContentWrapper>
  )
}
