import { useState, useEffect } from "react"
import classNames from "classnames"
import ReactSelect, { components } from "react-select"
import { ReactComponent as IconSelect } from "../../../assets/svg/icon-select.svg"
import { ReactComponent as IconTableCross } from "../../../assets/svg/icon-table-cross.svg"
import { UITypes } from "../../../types"
import useUrlQueryParams from "../../../hooks/useUrlQueryParams"
import styles from "./index.module.scss"
import "./select.scss"

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span
        className={classNames(styles.iconArrow, {
          [styles.open]: props?.selectProps?.menuIsOpen,
        })}
      >
        <IconSelect />
      </span>
    </components.DropdownIndicator>
  )
}

const ClearIndicator = (props) => {
  const { innerProps } = props
  return (
    <div {...innerProps} className={styles.clearIcon}>
      <IconTableCross />
    </div>
  )
}

interface Props {
  headerCell: UITypes.TableHeaderCell
}

export const TableSelect = ({ headerCell: { title, name, options } }: Props) => {
  const [value, setValue] = useState<UITypes.Option | undefined>(undefined)
  const { urlQueryParams, setUrlQueryParams } = useUrlQueryParams()

  useEffect(() => {
    if (options) {
      const result = options.filter((item) => item.value === urlQueryParams[name || ""])
      setValue(result[0])
    }
  }, [urlQueryParams])

  const onChange = (data: UITypes.Option) => {
    if (!name) return

    setUrlQueryParams({
      [name]: data?.value || null,
    })
  }

  return (
    <div className={classNames(styles.field)}>
      <ReactSelect
        // menuIsOpen
        // isMulti
        options={options}
        classNamePrefix="filter"
        value={value || null}
        isClearable={true}
        hideSelectedOptions={true}
        className="custom"
        name={name}
        placeholder={title}
        onChange={onChange as any}
        components={{
          DropdownIndicator,
          ClearIndicator,
        }}
      />
    </div>
  )
}
