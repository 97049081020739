import classNames from "classnames"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Pagination } from "../../../components/common/Pagination"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { CheckboxNew } from "../../../components/form/CheckboxNew"
import { UITypes } from "../../../types"
import { WidgetDiagnosticsStatuses } from "../../../components/widgets/WidgetDiagnosticsStatuses"
import { WidgetConfirmationStatuses } from "../../../components/widgets/WidgetConfirmationStatuses"
import TableWrapper from "../../../components/table/TableWrapper"
import styles from "./index.module.scss"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "",
    type: "checkbox",
  },
  {
    title: "Customer",
    type: "sort-search",
    size: "middle",
  },
  {
    title: "Device S/N",
    type: "sort-search",
    size: "small",
  },
  {
    title: "Confirm Status",
    type: "select",
    size: "middle",
  },
  {
    title: "Status",
    type: "select",
    size: "middle",
  },
  {
    title: "City",
    type: "sort-search",
    size: "middle",
  },
  {
    title: "Zip code",
    type: "sort-search",
    size: "small",
  },
  {
    title: "State",
    type: "sort-search",
    size: "small",
  },
  {
    title: "Activation date",
    type: "sort-search",
    size: "middle",
  },
  {
    title: "Membership",
    type: "sort-search",
    size: "small",
  },
]

export const Diagnostics = () => {
  return (
    <>
      <div className={classNames(styles.widgetsWrapper)}>
        <WidgetConfirmationStatuses />
        <WidgetDiagnosticsStatuses />
      </div>
      <ContentWrapper title="Diagnostics">
        <nav className={styles.tableNavWrapper}>
          <div>
            <ButtonNew onClick={() => {}} color="grey">
              Start Diagnostic
            </ButtonNew>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <CheckboxNew className={styles.tableNavItem} isReverse={true} id="confirmed">
              Show Confirmed
            </CheckboxNew>
            <ButtonNew onClick={() => {}} color="blueBordered">
              Hisory
            </ButtonNew>
          </div>
        </nav>
        <TableWrapper
          tableName="diagnostics"
          headerCells={tableHeaderCells}
          bodyRows={[
            {
              cells: [
                { type: "checkbox" },
                {
                  value: "Bessie Cooper",
                  size: "middle",
                },
                {
                  value: "349574",
                  size: "small",
                },
                {
                  value: "",
                  size: "middle",
                },
                {
                  value: "",
                  size: "middle",
                },
                {
                  value: "775 Rolling Green R",
                  size: "middle",
                },
                {
                  value: "14355",
                  size: "small",
                },
                {
                  value: "MD",
                  size: "small",
                },
                {
                  value: "2020-08-08",
                  size: "middle",
                },
                {
                  value: "Membership",
                  size: "small",
                },
              ],
              id: 1,
            },
          ]}
          // isLoading={isFetching}
        />
        <Pagination />
      </ContentWrapper>
    </>
  )
}
