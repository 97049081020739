import "react-toastify/dist/ReactToastify.css"
import "@mdxeditor/editor/style.css"
import "./assets/scss/globals.scss"
import { useEffect } from "react"
import { useAppSelector, useAppDispatch } from "./store/hooks"
import { showTermsOfUseModal } from "./store/modalSlise"
import { Route, Routes, Navigate, Outlet, useSearchParams, useLocation, useNavigate } from "react-router-dom"
import { AuthLayout } from "./layouts/AuthLayout"
import MainLayout from "./layouts/MainLayout/MainLayout"
import { SignIn } from "./pages/common/Auth/SignIn"
import { ForgotPassword } from "./pages/common/Auth/ForgotPassword"
import { ChangePassword } from "./pages/common/Auth/ChangePassword"
import { CreatePassword } from "./pages/common/Auth/CreatePassword"
import { PrivateRoute } from "./components/common/PrivateRoute"
import { NotFound } from "./components/common/NotFound"
import { Orders } from "./pages/contractor/Orders"
import { Technicians } from "./pages/contractor/Technicians"
import ContractorDashboardPage from "./pages/contractor/ContractorDashboardPage/ContractorDashboardPage"
import { RedirectPage } from "./pages/common/RedirectPage"
import { AddCustomers } from "./pages/technician/AddCustomers"
import { AddDevice } from "./pages/technician/AddDevice"
import { Customers } from "./pages/contractor/Customers"
import { Contractors } from "./pages/vendor/Contractors"
import AlertsPage from "./pages/contractor/AlertsPage/AlertsPage"
import { MessageTemplateCreate } from "./pages/contractor/MessageTemplateCreate"
import { MessageTemplateEdit } from "./pages/contractor/MessageTemplateEdit"
import { MessageTemplateAdd } from "./pages/contractor/MessageTemplateAdd"
import { MessageCenter } from "./pages/contractor/MessageCenter"
import { MessageHistory } from "./pages/contractor/MessageHistory"
import { Orders as WHOrders } from "./pages/warehouse/Orders"
import { Devices as WHDevices } from "./pages/warehouse/Devices"
import { WarehouseList } from "./pages/vendor/WarehouseList"
import { MessageReport } from "./pages/contractor/MessageReport"
import { Users } from "./pages/vendor/Users"
import { Orders as VOrders } from "./pages/vendor/Orders"
import { Modals } from "./modals"
import { checkRoleType } from "./utils/checkRoleType"
import { Devices as VendorDevices } from "./pages/vendor/Devices"
import { Alerts as VendorAlerts } from "./pages/vendor/Alerts"
import { Dashboard as VendorDashboard } from "./pages/vendor/Dashboard"
import { Warranty } from "./pages/technician/Waranty"
import { storageKeys } from "./utils/storageKeys"
import { Diagnostics } from "./pages/contractor/Diagnostics"
import MonitoringPage from "./pages/contractor/MonitoringPage/MonitoringPage"
import PerformanceTestPage from "./pages/contractor/PerformanceTestPage/PerformanceTestPage"
import PerformanceTestHistoryPage from "./pages/contractor/PerformanceTestHistoryPage/PerformanceTestHistoryPage"

export const App = () => {
  const isUserAuthorized = useAppSelector((state) => state.user.isUserAuthorized)
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (isUserAuthorized && userProfile?.terms !== true) {
      dispatch(showTermsOfUseModal(true))
    }
  }, [isUserAuthorized])

  // FOR NOT AUTHORIZED TECHNICIAN ONLY
  useEffect(() => {
    if (checkRoleType(userProfile?.type) === "contractor-technician") return
    if (location?.pathname !== "/add-device/customer") return

    const deviceSN = searchParams.getAll("sn")

    if (deviceSN?.length) {
      sessionStorage.setItem(storageKeys.technicianSN, JSON.stringify(deviceSN.length === 1 ? deviceSN[0] : deviceSN))
    }

    navigate("auth/sign-in")
  }, [location])

  return (
    <MainLayout>
      <Routes>
        <Route
          element={
            <>
              <Outlet />
              <Modals />
            </>
          }
        >
          <Route path="auth" element={<AuthLayout />}>
            <Route path="sign-in" element={<SignIn />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="create-password" element={<CreatePassword />} />
          </Route>

          {/* CONTRACTOR */}
          {checkRoleType(userProfile?.type) === "contractor-manager" && (
            <Route element={<PrivateRoute />}>
              <Route path="dashboard" element={<ContractorDashboardPage />} />
              <Route path="orders" element={<Orders />}></Route>
              <Route path="technicians" element={<Technicians />} />
              <Route path="customers" element={<Customers />} />
              <Route path="alerts" element={<AlertsPage />} />
              <Route path="perf-test" element={<PerformanceTestPage />} />
              <Route path="perf-test-history" element={<PerformanceTestHistoryPage />} />
              <Route path="diagnostics" element={<Diagnostics />} />
              <Route path="monitoring" element={<MonitoringPage />} />
              <Route path="messages" element={<Navigate to="center" replace />} />
              <Route path="messages/*">
                <Route path="center" element={<MessageCenter />} />
                <Route path="history" element={<MessageHistory />} />
                <Route path="report" element={<MessageReport />} />
                <Route path="create-template" element={<MessageTemplateCreate />} />
                <Route path="edit-template" element={<MessageTemplateEdit />} />
                <Route path="add-template" element={<MessageTemplateAdd />} />
              </Route>
            </Route>
          )}

          {/* TECHNICIAN */}
          {checkRoleType(userProfile?.type) === "contractor-technician" && (
            <Route element={<PrivateRoute />}>
              <Route path="add-device" element={<Navigate to="customer" replace />} />
              <Route path="add-device/*">
                <Route path="customer" element={<AddCustomers />} />
                <Route path="details" element={<AddDevice />} />
              </Route>
              <Route path="warranty" element={<Warranty />} />
            </Route>
          )}

          {/* VENDOR ADMIN */}
          {checkRoleType(userProfile?.type) === "vendor-admin" && (
            <Route element={<PrivateRoute />}>
              <Route path="contractors" element={<Contractors />} />
              <Route path="warehouse-list" element={<WarehouseList />} />
              <Route path="orders" element={<VOrders />}></Route>
              <Route path="devices" element={<VendorDevices />} />
              <Route path="alerts" element={<VendorAlerts />} />
              <Route path="dashboard" element={<VendorDashboard />} />
              <Route path="admin/*">
                <Route path="users" element={<Users />} />
              </Route>
              <Route path="admin" element={<Navigate to="users" replace />} />
            </Route>
          )}

          {/* SALES MANAGER */}
          {checkRoleType(userProfile?.type) === "sales-manager" && (
            <Route element={<PrivateRoute />}>
              <Route path="contractors" element={<Contractors />} />
              <Route path="orders" element={<VOrders />}></Route>
              <Route path="admin" element={<Navigate to="users" replace />} />
              <Route path="orders" element={<VOrders />}></Route>
              <Route path="devices" element={<VendorDevices />} />
              <Route path="alerts" element={<VendorAlerts />} />
              <Route path="dashboard" element={<VendorDashboard />} />
            </Route>
          )}

          {/* WAREHOUSE MANAGER */}
          {checkRoleType(userProfile?.type) === "warehouse-manager" && (
            <Route element={<PrivateRoute />}>
              <Route path="wh-orders" element={<WHOrders />} />
              <Route path="wh-devices" element={<WHDevices />} />
            </Route>
          )}
          <Route element={<PrivateRoute />}>
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="/" element={<RedirectPage />} />
          <Route path="*" element={<RedirectPage />} />
        </Route>
      </Routes>
    </MainLayout>
  )
}
