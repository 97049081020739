import { FormEvent, SetStateAction, useState } from "react"
import ModalSectionTitle from "../../../components/common/ModalSectionTitle/ModalSectionTitle"
import { InputNew } from "../../../components/form/InputNew"
import { UITypes } from "../../../types"
import { urlPatternWithoutHttpPrefix } from "../../../utils/regularExpressions"
import { InputPhone } from "../../../components/form/InputPhone"
import { ContractorPhoneSupport } from "../../components/contractorPhoneSupport"
import { ContractorCustomApp } from "../../components/contractorCustomApp"
import { InputLogo } from "../../../components/form/InputLogo"
import styles from "./index.module.scss"

interface Props {
  inputValues: UITypes.Contractor
  error: {
    field: string
    message: string
  }
  setError: (value: SetStateAction<{ field: string; message: string }>) => void
  onInputChange: (event: FormEvent<HTMLInputElement>) => void
  setInputValues: (value: SetStateAction<UITypes.Contractor>) => void
}

const InfoSection = ({ inputValues, error, setError, onInputChange, setInputValues }: Props) => {
  const onInputBlur = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget

    if (urlPatternWithoutHttpPrefix.test(value)) {
      const urlPattern = /^(http:\/\/|https:\/\/)/

      setInputValues((prevState) => {
        return { ...prevState, [name]: `${urlPattern.test(value) ? "" : "https://"}${value}` }
      })
    }
  }

  return (
    <div>
      <ModalSectionTitle title="Info" />
      <div className={styles.row}>
        <InputNew
          label="Company web URL"
          placeholder="Enter URL"
          name="url"
          value={inputValues.url}
          className={styles.inputText}
          onChange={onInputChange}
          onBlur={onInputBlur}
          errorMessage={error.field === "url" ? error.message : ""}
        />
        <InputNew
          label="Schedule URL"
          placeholder="Enter schedule URL"
          name="schedule"
          value={inputValues.schedule}
          className={styles.inputText}
          onChange={onInputChange}
          onBlur={onInputBlur}
          errorMessage={error.field === "schedule" ? error.message : ""}
        />
      </div>
      <div className={styles.row}>
        <InputNew
          label="Full Name"
          placeholder="Enter Full Name"
          name="fullName"
          value={inputValues.fullName}
          className={styles.inputText}
          onChange={onInputChange}
          errorMessage={error.field === "fullName" ? error.message : ""}
        />
      </div>
      <div className={styles.row}>
        <InputPhone
          label="Phone number"
          placeholder="Enter phone"
          name="phone"
          value={inputValues.phone}
          className={styles.inputText}
          onChange={(value) => setInputValues({ ...inputValues, ...{ phone: value } })}
          errorMessage={error.field === "phone" ? error.message : ""}
        />
      </div>
      <ContractorPhoneSupport
        phoneForSupport={inputValues.phoneForSupport}
        phoneForSupportCall={inputValues.phoneForSupportCall}
        phoneForSupportText={inputValues.phoneForSupportText}
        inputValues={inputValues}
        error={error}
        setError={setError}
        setInputValues={setInputValues}
      />
      <div className={styles.row}>
        <InputNew
          label="Email"
          placeholder="Enter email address"
          name="email"
          value={inputValues.email}
          className={styles.inputText}
          onChange={onInputChange}
          errorMessage={error.field === "email" ? error.message : ""}
        />
      </div>
      <div className={styles.row}>
        <InputLogo
          name="logo"
          id="logo"
          value={inputValues.image && [inputValues.image]}
          imageUrl={inputValues.logo}
          errorMessage={error.field === "logo" ? error.message : ""}
          label="Company logo"
          formatNote="(Format: Png Ratio: 2:1 Max size: 750x375px)"
          onSetFile={(files) =>
            setInputValues((prevState) => {
              return { ...prevState, image: files[0] }
            })
          }
        />
      </div>
      <div className={styles.row}>
        <ContractorCustomApp
          customAppEnabled={inputValues.isCustom || false}
          appStoreLink={inputValues.appStoreLink}
          googlePlayLink={inputValues.googlePlayLink}
          error={error}
          setError={setError}
          setInputValues={setInputValues}
          onInputChange={onInputChange}
          onInputBlur={onInputBlur}
        />
      </div>
    </div>
  )
}

export default InfoSection
