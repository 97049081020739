import { useEffect } from "react"
import dayjs from "dayjs"
import { storageKeys } from "../../utils/storageKeys"
import { useLazyGetUserProfileQuery } from "../../store/api/profileApi"
import { useAppDispatch } from "../../store/hooks"
import { setUserProfile } from "../../store/userSlise"

const useUserProfileSync = () => {
  const dispatch = useAppDispatch()
  const [triggerGetProfile] = useLazyGetUserProfileQuery()

  const getProfile = async () => {
    try {
      const res: any = await triggerGetProfile()
      if (res && !res.error) {
        const lastSync = dayjs()
        localStorage.setItem(storageKeys.userProfile, JSON.stringify({ ...res?.data?.data, lastSync }))
        dispatch(setUserProfile({ ...res?.data?.data, lastSync }))
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const jsonUserProfile = localStorage.getItem(storageKeys.userProfile)
      if (!jsonUserProfile) return
      const parsedUserProfile = jsonUserProfile ? JSON.parse(jsonUserProfile) : null

      const userProfile = {
        ...parsedUserProfile,
        lastSync: parsedUserProfile.lastSync ? dayjs(parsedUserProfile.lastSync) : dayjs().year(2000),
      }

      if (dayjs().diff(userProfile.lastSync, "minute") > 30) {
        getProfile()
      }
    }, 15000)

    return () => {
      clearInterval(interval)
    }
  }, [])
}

export default useUserProfileSync
