import styles from "../../assets/scss/index.module.scss"
import { createPortal } from "react-dom"
import { ReactComponent as IconUnfreeze } from "../../assets/svg/modal-icon-unfreeze.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"
import { useLazyPostAlertUnfreezeQuery, useLazyGetAlertsQuery } from "../../store/api/alertsApi"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { toast } from "react-toastify"
import { setCurrentAlert } from "../../store/tempDataSlise"
import {
  useLazyGetAdminAlertsQuery,
  useLazyGetVendorAlertAndDeviceWidgetQuery,
  useLazyGetVendorStatusesWidgetQuery,
} from "../../store/api/adminApi"
import useUrlQueryParams from "../../hooks/useUrlQueryParams"

interface Props {
  onCancel?: () => void
}

export const AlertsUnfreezeModal = ({ onCancel }: Props) => {
  const [triggerAlertUnfreeze, { isFetching }] = useLazyPostAlertUnfreezeQuery()
  const [triggerGetAdminAlerts] = useLazyGetAdminAlertsQuery()
  const [triggerGetVendorStatusesWidget] = useLazyGetVendorStatusesWidgetQuery()
  const [triggerGetVendorAlertAndDeviceWidget] = useLazyGetVendorAlertAndDeviceWidgetQuery()
  const dispatch = useAppDispatch()
  const currentAlert = useAppSelector((state) => state.tempData.currentAlert)
  const { urlQueryParams } = useUrlQueryParams()

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      const res: any = await triggerAlertUnfreeze(currentAlert?.id as number)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Alert has been successfully unfrozen!")
        dispatch(setCurrentAlert(undefined))
        triggerGetAdminAlerts(urlQueryParams)
        triggerGetVendorStatusesWidget()
        triggerGetVendorAlertAndDeviceWidget()
        onCancel && onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconUnfreeze />
            <h3 className={styles.modalTitle}>Unfreeze</h3>
            <div className={styles.modalMessage}>
              By unfreezing you will start receiving alert(s) on the selected device(s). Are you sure you want to
              unfreeze the alert(s)?
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
