import { MutableRefObject, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import "../../../assets/libs/react-datepicker.css"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { useAppDispatch } from "../../../store/hooks"
import { convertDataToPerformanceTestHistoryTableRows } from "../../../utils/convertData"
import { useLazyGetPerformanceTestReportQuery } from "../../../store/api/performanceTestApi"
import { setPerformanceTestReport } from "../../../store/tempDataSlise"
import { ApiTypes } from "../../../types"
import { showPerformanceTestReportModal } from "../../../store/modalSlise"
import { Pagination } from "../../../components/common/Pagination"
import { perfTestHistoryFilters, getPerfTestDateRangeParams, setDateParam } from "./getPerfTestDateRangeParams"
import { useOutsideClick } from "../../../utils/useOutsideClick"
import styles from "./index.module.scss"
import TableWrapper from "../../../components/table/TableWrapper"
import useUrlQueryParams from "../../../hooks/useUrlQueryParams"
import performanceTestHistoryTableHeaderCells from "./performanceTestHistoryTableHeaderCells"

const testHistoryBreadcrumbs = [
  { label: "Performance Test", link: "/perf-test" },
  { label: "Performance Test History", link: "/perf-test-history" },
]

interface PerformanceTestHistoryPageContentProps {
  devices: ApiTypes.Model.PerformanceTestHistory[]
  meta: ApiTypes.Model.Pagination | undefined
  isFetching: boolean
  onExport: () => void
}

const PerformanceTestHistoryPageContent = ({
  devices,
  meta,
  isFetching,
  onExport,
}: PerformanceTestHistoryPageContentProps) => {
  const dispatch = useAppDispatch()
  const { setUrlQueryParams } = useUrlQueryParams()
  const [triggerGetPerformanceTestReport] = useLazyGetPerformanceTestReportQuery()
  const [activeDateFilter, setActiveDateFilter] = useState("all")
  const [isDateCustom, setIsDateCustom] = useState(false)
  const [customFilterDateRange, setCustomFilterDateRange] = useState({
    startDate: new Date(),
    endDate: null,
  })
  const datePickerRef: MutableRefObject<HTMLInputElement | null> = useRef(null)

  useOutsideClick(datePickerRef, () => setIsDateCustom(false))

  const handleTableRowClick = async (id: number) => {
    const currentDevice = devices.find((item) => item.perftest.perftest_result_id === id)

    const res = await triggerGetPerformanceTestReport(id)
    if (res.data) {
      dispatch(
        setPerformanceTestReport({
          ...res?.data,
          customer: currentDevice?.client?.homeowner_name,
          zip_code: currentDevice?.device?.zip?.code,
          where_installed_name: currentDevice?.device?.where_installed_name,
        }),
      )
      dispatch(showPerformanceTestReportModal(true))
    }
  }

  const handleChangeDateFilter = (date: string) => {
    setActiveDateFilter(date)

    if (date === "custom" && !isDateCustom) {
      setIsDateCustom(true)
      return
    }

    setUrlQueryParams(getPerfTestDateRangeParams(date))
  }

  const handleChangeCustomDate = (dates) => {
    const [start, end] = dates
    setCustomFilterDateRange({
      startDate: start,
      endDate: end,
    })
  }

  const handleCancelDateFilter = () => {
    setIsDateCustom(false)
    setCustomFilterDateRange({
      startDate: new Date(),
      endDate: null,
    })
  }

  const handleApplyDateFilter = () => {
    setUrlQueryParams(setDateParam(customFilterDateRange.startDate, customFilterDateRange.endDate))
    setIsDateCustom(false)
  }

  return (
    <>
      <ContentWrapper title="Performance Test History" breadcrumbs={testHistoryBreadcrumbs}>
        <nav className={styles.tableNavWrapper}>
          <div className={styles.tableFiltersWrapper}>
            {perfTestHistoryFilters.map((filter) => (
              <div
                key={filter.id}
                className={`${styles.tableFilter} ${activeDateFilter === filter.value ? styles.active : ""}`}
                onClick={() => handleChangeDateFilter(filter.value)}
              >
                <span>{filter.label}</span>
              </div>
            ))}
            {isDateCustom && (
              <div ref={datePickerRef} className={styles.datePickerWrapper}>
                <DatePicker
                  wrapperClassName={styles.innerWrapper}
                  selected={customFilterDateRange.startDate}
                  onChange={handleChangeCustomDate}
                  startDate={customFilterDateRange.startDate}
                  endDate={customFilterDateRange.endDate}
                  selectsRange
                  inline
                />
                <div className={styles.dateButtonsWrapper}>
                  <ButtonNew color="greyBordered" type="submit" size="halfWidth" onClick={handleCancelDateFilter}>
                    Cancel
                  </ButtonNew>
                  <ButtonNew color="blue" type="submit" size="halfWidth" onClick={handleApplyDateFilter}>
                    Apply
                  </ButtonNew>
                </div>
              </div>
            )}
          </div>
          <ButtonNew onClick={onExport} className={styles.tableNavItem} disabled={meta?.total === 0}>
            Export
          </ButtonNew>
        </nav>
        <TableWrapper
          headerCells={performanceTestHistoryTableHeaderCells}
          bodyRows={convertDataToPerformanceTestHistoryTableRows(devices)}
          isLoading={isFetching}
          onRowClick={(id) => handleTableRowClick(id)}
          skippedTags={["result_date_start", "result_date_end"]}
        />
        <Pagination meta={meta} />
      </ContentWrapper>
    </>
  )
}

export default PerformanceTestHistoryPageContent
