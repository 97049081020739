import { useEffect, useMemo } from "react"
import { toast } from "react-toastify"
import "../../../assets/libs/react-datepicker.css"
import { useAppSelector } from "../../../store/hooks"
import { useLazyGetPerformanceTestsHistoryQuery } from "../../../store/api/performanceTestApi"
import { UITypes } from "../../../types"
import { SubscriptionUpgradeModal } from "../../../modals/SubscriptionUpgradeModal/SubscriptionUpgradeModal"
import hasSubscriptionAccess from "../../../utils/hasSubscriptionAccess"
import SubscriptionPrivilege from "../../../types/enums/SubscriptionPrivilege"
import PerformanceTestHistoryPageContent from "./PerformanceTestHistoryPageContent"
import useUrlQueryParams from "../../../hooks/useUrlQueryParams"
import { dummyPerfTestDevices, dummyMeta } from "../PerformanceTestPage/dummyPerfTestData"
import useExportCSV from "../../../hooks/useExportCSV"
import performanceTestHistoryTableHeaderCells from "./performanceTestHistoryTableHeaderCells"
import { convertDataToPerformanceTestHistoryTableRows } from "../../../utils/convertData"

const PerformanceTestHistoryPage = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const [triggerGetPerformanceTestsHistory, { isFetching, data }] = useLazyGetPerformanceTestsHistoryQuery()
  const { urlQueryParams } = useUrlQueryParams()
  const requestParams = { orderColumn: "time", orderType: "DESC" as UITypes.Sort["orderType"], ...urlQueryParams }
  const hasPerformanceTestAccess = userProfile
    ? hasSubscriptionAccess(userProfile, SubscriptionPrivilege.PerformanceTest)
    : false
  const meta = hasPerformanceTestAccess ? data?.meta : dummyMeta
  const isFetchingData = isFetching && !data
  const devices = useMemo(() => {
    if (!hasPerformanceTestAccess) {
      return dummyPerfTestDevices
    }

    return data?.data || []
  }, [data, hasPerformanceTestAccess])

  const { handleExport } = useExportCSV({
    params: requestParams,
    headerCells: performanceTestHistoryTableHeaderCells,
    meta: meta,
    fileName: "performance_test_history",
    convertData: convertDataToPerformanceTestHistoryTableRows,
    useLazyQuery: useLazyGetPerformanceTestsHistoryQuery,
  })

  useEffect(() => {
    sendRequest(requestParams)
  }, [urlQueryParams])

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = await triggerGetPerformanceTestsHistory(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <PerformanceTestHistoryPageContent
        devices={devices}
        meta={meta}
        isFetching={isFetchingData}
        onExport={handleExport}
      />
      {!hasPerformanceTestAccess && <SubscriptionUpgradeModal page={SubscriptionPrivilege.PerformanceTest} />}
    </>
  )
}

export default PerformanceTestHistoryPage
