import { FormEvent, SetStateAction } from "react"
import { ToggleButton } from "../../../components/form/ToggleButton"
import { InputNew } from "../../../components/form/InputNew"
import { UITypes } from "../../../types"
import styles from "./index.module.scss"

interface ContractorCustomAppProps {
  customAppEnabled: boolean
  appStoreLink?: string | null
  googlePlayLink?: string | null
  error: { field: string; message: string }
  setError: (value: SetStateAction<{ field: string; message: string }>) => void
  setInputValues: (value: SetStateAction<UITypes.Contractor>) => void
  onInputChange: (event: FormEvent<HTMLInputElement>) => void
  onInputBlur: (event: FormEvent<HTMLInputElement>) => void
}

export const ContractorCustomApp: React.FC<ContractorCustomAppProps> = ({
  customAppEnabled,
  appStoreLink,
  googlePlayLink,
  error,
  setError,
  setInputValues,
  onInputChange,
  onInputBlur,
}) => {
  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues((prevState) => {
      return {
        ...prevState,
        [name]: checked,
        ...(!checked ? { appStoreLink: "", googlePlayLink: "" } : {}),
      }
    })

    if (!checked && ["appStoreLink", "googlePlayLink"].includes(error.field)) {
      setError({ field: "", message: "" })
    }
  }

  return (
    <div>
      <div className={styles.toggleFieldWrapper}>
        <ToggleButton id="isCustom" name="isCustom" isChecked={customAppEnabled} onChange={handleInputChange}>
          Custom App
        </ToggleButton>
      </div>
      <div className={styles.customAppLinkWrapper}>
        <InputNew
          label="App Store link"
          placeholder="Please paste the App Store link here"
          name="appStoreLink"
          value={appStoreLink || ""}
          disabled={!customAppEnabled}
          onChange={onInputChange}
          onBlur={onInputBlur}
          errorMessage={error.field === "appStoreLink" ? error.message : ""}
        />
        <InputNew
          label="Google Play link"
          placeholder="Please paste the Google Play link here"
          name="googlePlayLink"
          value={googlePlayLink || ""}
          disabled={!customAppEnabled}
          onChange={onInputChange}
          onBlur={onInputBlur}
          errorMessage={error.field === "googlePlayLink" ? error.message : ""}
        />
      </div>
    </div>
  )
}
