import { Chart } from "chart.js"

const getLegendMarginPlugin = (padding: number) => ({
  id: "legendMargin",
  beforeInit: (chart: Chart) => {
    const legend = chart.legend
    if (legend) {
      const fitValue = legend.fit

      legend.fit = function () {
        fitValue.bind(chart.legend)()
        this.height += padding
      }
    }
  },
})

export default getLegendMarginPlugin
