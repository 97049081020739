import classNames from "classnames"
import { Spinner } from "../../common/Spinner"
import { BarChart } from "../../common/BarChart"
import { ApiTypes } from "../../../types"
import styles from "./index.module.scss"

interface IPerformanceTestWidgetProps {
  widgetData: ApiTypes.Res.PerformanceTestWidgetData
  isFetching: boolean
}

export const WidgetPerformanceTest = ({ widgetData, isFetching }: IPerformanceTestWidgetProps) => (
  <div className={classNames(styles.perfTestWidgetWrapper)}>
    <header className={styles.widgetHeader}>
      <h3 className={styles.widgetTitle}>Performance Test Statuses</h3>
    </header>
    <div className={styles.chartWrapper}>
      <BarChart
        items={[
          {
            color: "#43E0F8",
            value: widgetData.scheduled,
          },
          {
            color: "#3b82f6",
            value: widgetData.rescheduled,
          },
          {
            color: "#f59e0b",
            value: widgetData.running,
          },
          {
            color: "#22C55E",
            value: widgetData.passed,
          },
          {
            color: "#EF4444",
            value: widgetData.issue,
          },
        ]}
      />
      <div className={styles.indicatorsWrapper}>
        <span className={styles.indicator}>
          <i
            className={styles.colorCircle}
            style={{
              backgroundColor: "#43E0F8",
            }}
          />
          Scheduled
          <i>{widgetData.scheduled}</i>
        </span>
        <span className={styles.indicator}>
          <i
            className={styles.colorCircle}
            style={{
              backgroundColor: "#3B82F6",
            }}
          />
          Rescheduled
          <i>{widgetData.rescheduled}</i>
        </span>
        <span className={styles.indicator}>
          <i
            className={styles.colorCircle}
            style={{
              backgroundColor: "#f59e0b",
            }}
          />
          Running
          <i>{widgetData.running}</i>
        </span>
        <span className={styles.indicator}>
          <i
            className={styles.colorCircle}
            style={{
              backgroundColor: "#22C55E",
            }}
          />
          Passed
          <i>{widgetData.passed}</i>
        </span>
        <span className={styles.indicator}>
          <i
            className={styles.colorCircle}
            style={{
              backgroundColor: "#EF4444",
            }}
          />
          Issue
          <i>{widgetData.issue}</i>
        </span>
      </div>
      {isFetching && (
        <div className={styles.widgetLoader}>
          <Spinner />
        </div>
      )}
    </div>
  </div>
)
