import { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import debounce from "lodash.debounce"
import isEqual from "lodash.isequal"
import DropdownSelect, { SelectRenderer } from "react-dropdown-select"
import styles from "./index.module.scss"
import { UITypes, ApiTypes } from "../../../types"
import { convertDataToOptions, convertStringArrayToOptions } from "../../../utils/convertData"
import { usePrevious } from "../../../utils/usePrevious"
import { setCurrentZip } from "../../../store/tempDataSlise"
import { useAppDispatch } from "../../../store/hooks"
import "../../../assets/libs/select.css"
import ItemRenderer from "./ItemRenderer"

export type SearchFnType = ({ props, state, methods }: SelectRenderer<any>) => any

interface Props {
  label?: string
  placeholder?: string
  name?: string
  values?: UITypes.Option[]
  errorMessage?: string
  valueName: string
  labelName: string
  searchFieldName?: string
  className?: string
  queryParams?: UITypes.Params
  searchFn?: SearchFnType
  onChange?: (value: UITypes.Option[]) => void
  addCustomOptionRenderer?: (searchValue: string) => JSX.Element
  useLazyQuery: any
  dropdownHeight?: string
  itemRenderer?: any
  responseType?: "stringArray" | "objectArray"
}

export const SelectDynamic = ({
  label,
  placeholder,
  name,
  values = [],
  errorMessage,
  valueName,
  labelName,
  className,
  searchFieldName = "name",
  dropdownHeight,
  queryParams = {},
  onChange,
  searchFn,
  useLazyQuery,
  addCustomOptionRenderer,
  responseType = "objectArray",
}: Props) => {
  if (!useLazyQuery || typeof useLazyQuery !== "function" || !useLazyQuery()?.length)
    return <div className={styles.errorText}>Error in "SelectDynamic" component</div> // Additional checking for useLazyQuery

  const [triggerGetData, { isFetching, data }] = useLazyQuery()
  const [searchValue, setSearchValue] = useState("")
  const [permanentSearchValue, setPermanentSearchValue] = useState("")
  const prevSearchValue = usePrevious(searchValue)
  const prevQueryParams = usePrevious(queryParams)
  const dispatch = useAppDispatch()

  const onSetSearchValue = (data) => {
    const search = data?.state?.search
    setSearchValue(search)
  }

  const setDebounceSearchValueRef = useRef(debounce(onSetSearchValue, 600))

  const onSearchChange = (props: SelectRenderer<any>): any => {
    setPermanentSearchValue(props.state.search)
    setDebounceSearchValueRef.current(props)

    return searchFn?.(props)
  }

  // FOR ZIP SELECT ONLY
  const filterCityStateBasedOnZip = (options: UITypes.Option[], resData: any) => {
    if (!resData?.data?.length) return
    const value = options[0]?.value
    const result = resData?.data?.filter((item: ApiTypes.Model.Zip) => Number(value) === item.id)
    result?.length && dispatch(setCurrentZip(result[0]))
  }

  const onSelectChange = (options) => {
    if (name === "zip") {
      filterCityStateBasedOnZip(options, data)
    }
    onChange?.(options)
  }

  useEffect(() => {
    if (searchValue !== prevSearchValue || !isEqual(prevQueryParams, queryParams)) {
      triggerGetData(searchValue === "" ? queryParams : { [searchFieldName]: searchValue, ...queryParams })
    }
  }, [searchValue, queryParams, prevSearchValue])

  const renderCustomOption = (data) => {
    if (
      addCustomOptionRenderer &&
      data.props.options.every(
        (option) =>
          option.value.toLowerCase().trim() !== permanentSearchValue.toLowerCase().trim() || option.type === "custom",
      ) &&
      permanentSearchValue.toLowerCase().trim()
    ) {
      return (
        <div className={styles.customOptionField} onClick={() => data.methods.addItem(data.item)}>
          {addCustomOptionRenderer(permanentSearchValue)}
        </div>
      )
    }

    return <></>
  }

  const itemRenderer = (data) => {
    if (data.item.type === "custom") {
      renderCustomOption(data)
    }

    return <ItemRenderer {...data} />
  }

  const getOptions = () => {
    if (responseType === "stringArray") {
      return [
        ...convertStringArrayToOptions((data as any)?.data || []),
        ...(addCustomOptionRenderer
          ? [{ value: permanentSearchValue, label: permanentSearchValue, type: "custom" }]
          : []),
      ]
    }

    return convertDataToOptions(data, valueName, labelName)
  }

  return (
    <div className={classNames(styles.fieldWrapper, className)}>
      {label && <div className={styles.fieldLabelNew}>{label}</div>}
      <div className={classNames(styles.field)}>
        <DropdownSelect
          options={getOptions()}
          loading={isFetching}
          placeholder={placeholder}
          name={name}
          className={classNames("custom", {
            [styles.error]: errorMessage,
          })}
          values={values?.length ? values : []}
          searchFn={onSearchChange}
          onChange={onSelectChange}
          dropdownHeight={dropdownHeight}
          itemRenderer={itemRenderer}
        />
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
