import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { UITypes } from "../../../types"
import { useAppDispatch } from "../../../store/hooks"
import { showEditColumnsModal } from "../../../store/modalSlise"
import { useLazyGetDevicesQuery } from "../../../store/api/devicesApi"
import { setSelectedDeviceIDs, setCurrentTableColumns } from "../../../store/tempDataSlise"
import { useLazyGetTableColumnsQuery } from "../../../store/api/dashboardApi"
import { reorderTableHeaderCells } from "../../../utils/reorderTabbleColumns"
import contractorDashboardDefaultTableHeaderCells from "./contractorDashboardDefaultTableHeaderCells"
import ContractorDashboardPageContent from "./ContractorDashboardPageContent"
import useUrlQueryParams from "../../../hooks/useUrlQueryParams"
import useExportCSV from "../../../hooks/useExportCSV"
import { convertDevicesToDashboardTableRows } from "../../../utils/convertData"

const Dashboard = () => {
  const [triggerGetDevices, { isFetching, data }] = useLazyGetDevicesQuery()
  const [triggerGetTableColumns, { data: columnsData }] = useLazyGetTableColumnsQuery()
  const { urlQueryParams } = useUrlQueryParams()
  const dispatch = useAppDispatch()
  const [isFirstRender, setFirstRender] = useState(true)
  const devices = data?.data || []

  const [tableHeaderCells, setTableHeaderCells] = useState<UITypes.TableHeaderCell[]>(
    contractorDashboardDefaultTableHeaderCells,
  )
  const { handleExport } = useExportCSV({
    params: urlQueryParams,
    headerCells: tableHeaderCells,
    meta: data?.meta,
    fileName: "devices",
    convertData: convertDevicesToDashboardTableRows,
    useLazyQuery: useLazyGetDevicesQuery,
  })

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = await triggerGetDevices(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onEditColumnsClick = () => {
    dispatch(showEditColumnsModal(true))
    dispatch(
      setCurrentTableColumns({
        table: "contractor_devices_list",
        columns: tableHeaderCells,
      }),
    )
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(urlQueryParams)
    triggerGetTableColumns({ list_name: "contractor_devices_list" })
  }, [urlQueryParams, isFirstRender])

  useEffect(() => {
    if (columnsData?.data?.config) {
      setTableHeaderCells(reorderTableHeaderCells(tableHeaderCells, columnsData?.data?.config))
    }
  }, [columnsData])

  // componentWillUnmount hook
  useEffect(
    () => () => {
      dispatch(setSelectedDeviceIDs([]))
    },
    [],
  )

  return (
    <ContractorDashboardPageContent
      devices={devices}
      tableHeaderCells={tableHeaderCells}
      isFetching={isFetching}
      meta={data?.meta}
      onEditColumnsClick={onEditColumnsClick}
      onExport={handleExport}
    />
  )
}

export default Dashboard
