import { useState, useEffect } from "react"
import { WidgetLayout } from "../../../layouts/WidgetLayout"
import styles from "./index.module.scss"
import classNames from "classnames"
import { Spinner } from "../../common/Spinner"
import { useLazyGetPhysicalStatusesWidgetQuery } from "../../../store/api/adminApi"
import { RainbowChart } from "../../common/RainbowChart"

const initialState = {
  installed: 0,
  inInventory: 0,
  inStock: 0,
  missing: 0,
  damaged: 0,
  transferring: 0,
  production: 0,
  warranty: 0,
  packed: 0,
  preinstalled: 0,
}

export const WidgetPhysicalStatus = () => {
  const [triggerGetPhysicalStatusesWidget, { isFetching, data }] = useLazyGetPhysicalStatusesWidgetQuery()
  const [widgetData, setWidgetData] = useState(initialState)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetPhysicalStatusesWidget()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!data?.data) return

    setWidgetData({
      installed: data?.data?.installed,
      inInventory: data?.data?.inventory,
      inStock: data?.data?.stocked,
      missing: data?.data?.missing,
      damaged: data?.data?.damaged,
      transferring: data?.data?.transferring,
      production: data?.data?.production,
      warranty: data?.data?.warranty,
      packed: data?.data?.packed,
      preinstalled: data?.data?.preinstalled,
    })
  }, [data])

  return (
    <WidgetLayout title="Physical status" className={styles.widgetWrapper}>
      <div
        className={classNames(styles.widgetContent, {
          [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartWrapper}>
          <div className={styles.chart}>
            <RainbowChart
              values={Object.values(widgetData)}
              colors={[
                "#14B8A6",
                "#C11574",
                "#22C55E",
                "#FCD34D",
                "#EF4444",
                "#3B82F6",
                "#CBD5E1",
                "#43E0F8",
                "#A855F7",
                "#115E59",
              ]}
            />
            <div className={styles.widgetMainValue}>
              <span className={styles.widgetNote}>DEVICES</span>
              <div className={styles.widgetValueWrapper}>
                {Object.values(widgetData).reduce((acc, current) => acc + current, 0)}
              </div>
            </div>
          </div>
          <div className={styles.chartNotesWrapper}>
            <div className={classNames(styles.chartNote, styles.hiddenChartNote)}></div>
            <div className={classNames(styles.chartNote, styles.hiddenChartNote)}></div>
            <div className={classNames(styles.chartNote, styles.hiddenChartNote)}></div>
            <div className={classNames(styles.chartNote, styles.installed, styles.rowPreLastNote)}>
              Installed
              <span className={styles.chartNoteValue}>{widgetData.installed}</span>
            </div>
            <div className={classNames(styles.chartNote, styles.packed, styles.rowLastNote)}>
              Packed
              <span className={styles.chartNoteValue}>{widgetData.packed}</span>
            </div>
            <div className={classNames(styles.chartNote, styles.hiddenChartNote)}></div>
            <div className={classNames(styles.chartNote, styles.hiddenChartNote)}></div>
            <div className={classNames(styles.chartNote, styles.preinstalled)}>
              Pre-Installed
              <span className={styles.chartNoteValue}>{widgetData.preinstalled}</span>
            </div>
            <div className={classNames(styles.chartNote, styles.damaged, styles.rowPreLastNote)}>
              Damaged
              <span className={styles.chartNoteValue}>{widgetData.damaged}</span>
            </div>
            <div className={classNames(styles.chartNote, styles.inStock, styles.rowLastNote)}>
              In Stock
              <span className={styles.chartNoteValue}>{widgetData.inStock}</span>
            </div>
            <div className={classNames(styles.chartNote, styles.inInventory)}>
              In Inventory
              <span className={styles.chartNoteValue}>{widgetData.inInventory}</span>
            </div>
            <div className={classNames(styles.chartNote, styles.production)}>
              Production
              <span className={styles.chartNoteValue}>{widgetData.production}</span>
            </div>
            <div className={classNames(styles.chartNote, styles.missing)}>
              Missing
              <span className={styles.chartNoteValue}>{widgetData.missing}</span>
            </div>
            <div className={classNames(styles.chartNote, styles.transfering, styles.rowPreLastNote)}>
              Transferring
              <span className={styles.chartNoteValue}>{widgetData.transferring}</span>
            </div>
            <div className={classNames(styles.chartNote, styles.warranty, styles.rowLastNote)}>
              Warranty
              <span className={styles.chartNoteValue}>{widgetData.warranty}</span>
            </div>
          </div>
        </div>
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </WidgetLayout>
  )
}
