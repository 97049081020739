import { toast } from "react-toastify"
import { ReactComponent as IconTransfer } from "../../assets/svg/modal-icon-trasfer.svg"
import { useLazyGetDevicesQuery } from "../../store/api/devicesApi"
import { useLazyPutContractorsDevicesAsTrasferedQuery } from "../../store/api/contractorsApi"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { setSelectedDeviceIDs } from "../../store/tempDataSlise"
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal"
import useUrlQueryParams from "../../hooks/useUrlQueryParams"

interface Props {
  onCancel: () => void
}

export const TransferModal = ({ onCancel }: Props) => {
  const [triggerPutContractorsDevicesAsTransfered, { isFetching }] = useLazyPutContractorsDevicesAsTrasferedQuery()
  const [triggerGetDevices] = useLazyGetDevicesQuery()
  const dispatch = useAppDispatch()
  const selectedDeviceIDs = useAppSelector((state) => state.tempData.selectedDeviceIDs)
  const { urlQueryParams } = useUrlQueryParams()

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      const res: any = await triggerPutContractorsDevicesAsTransfered({ device_ids: selectedDeviceIDs })
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("The status of the selected device(s) was changed successfully!")
        dispatch(setSelectedDeviceIDs([]))
        triggerGetDevices(urlQueryParams)
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ConfirmationModal
      title="Transfer"
      message="By transferring the selected device(s) to the Vendor, you give away the responsibility for their future support. The transferred device(s) will be deducted from your Inventory and Total Devices. Are you sure you want to transfer the selected device(s)?"
      isFetching={isFetching}
      icon={<IconTransfer />}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}
