import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import classNames from "classnames"
import { ApiTypes, UITypes } from "../../../types"
import { WidgetAlerts } from "../../../components/widgets/WidgetAlerts"
import { WidgetAlertStatuses } from "../../../components/widgets/WidgetAlertStatuses"
import {
  useLazyGetAlertAndDeviceWidgetQuery,
  useLazyGetAlertsQuery,
  useLazyGetAlertStatusesWidgetQuery,
  useLazyGetAlertTypesWidgetQuery,
} from "../../../store/api/alertsApi"
import { useAppSelector } from "../../../store/hooks"
import { AlertsPageContent } from "./AlertsPageContent"
import SubscriptionPrivilege from "../../../types/enums/SubscriptionPrivilege"
import hasSubscriptionAccess from "../../../utils/hasSubscriptionAccess"
import { SubscriptionUpgradeModal } from "../../../modals/SubscriptionUpgradeModal/SubscriptionUpgradeModal"
import { dummyAlerts, dummyAlertsStatusesWidgetData, dummyAlertsWidgetData, dummyMeta } from "./dummyAlertsData"
import styles from "./index.module.scss"
import useUrlQueryParams from "../../../hooks/useUrlQueryParams"

const initialWidgetStatusesData: ApiTypes.Res.AlertStatusesWidget = {
  active: 0,
  frozen: 0,
  acknowledged: 0,
}

const initialWidgetAlertsData: ApiTypes.Model.AlertsWidgetData = {
  airQuality: 0,
  ambientSensorMalfunction: 0,
  fanMalfunction: 0,
  humiditySensorMalfunction: 0,
  offline: 0,
  temperature: 0,
  temperatureSensorMalfunction: 0,
  enrolledDevicesCount: 0,
  devicesWithActiveAlerts: 0,
  alertsCount: 0,
}

const AlertsPage = () => {
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const [triggerGetAlerts, { isFetching, data }] = useLazyGetAlertsQuery()
  const { urlQueryParams } = useUrlQueryParams()
  const [widgetStatusesData, setWidgetStatusesData] = useState(initialWidgetStatusesData)
  const [widgetAlertsData, setWidgetAlertsData] = useState(initialWidgetAlertsData)
  const [isFirstRender, setFirstRender] = useState(true)
  const [triggerGetAlertStatusesWidget, { isFetching: isFetchingAlertsStatusesWidgetData }] =
    useLazyGetAlertStatusesWidgetQuery()
  const [triggerGetAlertTypesWidget, { isFetching: isFetchingWidgetAlertsData }] = useLazyGetAlertTypesWidgetQuery()
  const [triggerGetAlertsAndDeviceWidget] = useLazyGetAlertAndDeviceWidgetQuery()

  const hasAlertsAccess = userProfile ? hasSubscriptionAccess(userProfile, SubscriptionPrivilege.Alerts) : false

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = await triggerGetAlerts(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(urlQueryParams)
  }, [urlQueryParams, isFirstRender])

  useEffect(() => {
    if (!hasAlertsAccess) {
      setWidgetStatusesData(dummyAlertsStatusesWidgetData)
      setWidgetAlertsData(dummyAlertsWidgetData)
      return
    }

    const fetchData = async () => {
      try {
        const res = await triggerGetAlertStatusesWidget()
        if (res?.data) {
          setWidgetStatusesData({
            active: res?.data?.data?.active,
            frozen: res?.data?.data?.frozen,
            acknowledged: res?.data?.data?.acknowledged,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    const fetchWidgetData = async () => {
      try {
        const [res1, res2] = await Promise.all([triggerGetAlertTypesWidget(), triggerGetAlertsAndDeviceWidget()])

        let updatedData = { ...widgetAlertsData }

        if (res1?.data?.data) {
          updatedData = {
            ...updatedData,
            airQuality: res1.data.data?.air_quality,
            ambientSensorMalfunction: res1.data.data?.ambient_sensor_malfunction,
            fanMalfunction: res1.data.data?.fan_malfunction,
            humiditySensorMalfunction: res1.data.data?.humidity_sensor_malfunction,
            offline: res1.data.data?.offline,
            temperature: res1.data.data?.temperature,
            temperatureSensorMalfunction: res1.data.data?.temperature_sensor_malfunction,
          }
        }

        if (res2?.data?.data) {
          updatedData = {
            ...updatedData,
            enrolledDevicesCount: res2.data.data?.enrolled_devices_count,
            devicesWithActiveAlerts: res2.data.data?.devices_with_active_alerts,
            alertsCount: res2.data.data?.alerts_count,
          }
        }

        setWidgetAlertsData(updatedData)
      } catch (error) {
        console.error(error)
      }
    }

    fetchWidgetData()
    fetchData()
  }, [])

  const getAlerts = () => {
    if (!hasAlertsAccess) {
      return dummyAlerts
    }

    return data?.data || []
  }

  const alerts = getAlerts()
  const meta = hasAlertsAccess ? data?.meta : dummyMeta
  const isFetchingData = isFetching && !data

  return (
    <>
      <div className={classNames(styles.widgetsWrapper)}>
        <WidgetAlerts widgetData={widgetAlertsData} isFetching={isFetchingWidgetAlertsData} />
        <WidgetAlertStatuses widgetData={widgetStatusesData} isFetching={isFetchingAlertsStatusesWidgetData} />
      </div>
      <AlertsPageContent alertsList={alerts} isFetching={isFetchingData} meta={meta} />
      {!hasAlertsAccess && <SubscriptionUpgradeModal page={SubscriptionPrivilege.Alerts} />}
    </>
  )
}

export default AlertsPage
