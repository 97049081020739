import { useState } from "react"
import classNames from "classnames"
import { createPortal } from "react-dom"
import { ReactComponent as IconAccountSettings } from "../../assets/svg/modal-icon-account-settings.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import { ModalHeader } from "../../components/common/ModalHeader"
import { AccountSettingsProfile } from "../AccountSettingsProfile"
import { AccountSettingsAdditionalInfo } from "../AccountSettingsAdditionalInfo"
import { AccountSettingsPassword } from "../AccountSettingsPassword"
import { AccountSettingsIntegration } from "../AccountSettingsIntegration"
import AccountSettingsBilling from "./AccountSettingsBilling"
import styles from "./index.module.scss"

interface Props {
  onCancel: () => void
}

export const AccountSettingsModal = ({ onCancel }: Props) => {
  const [currentTab, setCurrentTab] = useState("profile")

  const renderCurrentView = () => {
    switch (currentTab) {
      case "profile":
        return <AccountSettingsProfile onCancel={onCancel} />
      case "additional-info":
        return <AccountSettingsAdditionalInfo onCancel={onCancel} />
      case "password":
        return <AccountSettingsPassword onCancel={onCancel} />
      case "integration":
        return <AccountSettingsIntegration />
      case "billing":
        return <AccountSettingsBilling />
      default:
        return <AccountSettingsProfile onCancel={onCancel} />
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="680px" isWithoutFooter={true} onCancel={onCancel}>
          <ModalHeader title="Account Settings">
            <IconAccountSettings />
          </ModalHeader>
          <div>
            <div className={styles.modalTabsWrapper}>
              <nav className={styles.modalTabs}>
                <span
                  onClick={() => setCurrentTab("profile")}
                  className={classNames(styles.modalTab, { [styles.active]: currentTab === "profile" })}
                >
                  Profile
                </span>
                <span
                  onClick={() => setCurrentTab("additional-info")}
                  className={classNames(styles.modalTab, { [styles.active]: currentTab === "additional-info" })}
                >
                  Additional information
                </span>
                <span
                  onClick={() => setCurrentTab("billing")}
                  className={classNames(styles.modalTab, { [styles.active]: currentTab === "billing" })}
                >
                  Billing
                </span>
                <span
                  onClick={() => setCurrentTab("password")}
                  className={classNames(styles.modalTab, { [styles.active]: currentTab === "password" })}
                >
                  Password
                </span>
                <span
                  onClick={() => setCurrentTab("integration")}
                  className={classNames(styles.modalTab, { [styles.active]: currentTab === "integration" })}
                >
                  Integration
                </span>
              </nav>
            </div>
            {renderCurrentView()}
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
