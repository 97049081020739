import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import styles from "../../../assets/scss/index.module.scss"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Pagination } from "../../../components/common/Pagination"
import { UITypes } from "../../../types"
import { useLazyGetClientsQuery, useLazyGetClientQuery } from "../../../store/api/clientApi"
import { convertClientsToTableRows } from "../../../utils/convertData"
import { useAppDispatch } from "../../../store/hooks"
import { setEditableClient } from "../../../store/tempDataSlise"
import { showEditCustomerModal } from "../../../store/modalSlise"
import TableWrapper from "../../../components/table/TableWrapper"
import useUrlQueryParams from "../../../hooks/useUrlQueryParams"
import { ButtonNew } from "../../../components/form/ButtonNew"
import styled from "styled-components"
import useExportCSV from "../../../hooks/useExportCSV"

const ExportButton = styled(ButtonNew)`
  position: absolute;
  top: 20px;
  right: 20px;
`

const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Email",
    type: "sort-search",
    name: "email",
  },
  {
    title: "Devices",
    type: "sort-search",
    name: "devices_count",
    size: "small",
  },
  {
    title: "Phone",
    type: "sort-search",
    name: "phone",
  },
  {
    title: "Full Name",
    type: "sort-search",
    name: "full_name",
  },
  {
    title: "Customer ID",
    type: "sort-search",
    name: "id",
    size: "middle",
  },
  {
    title: "Membership",
    type: "sort-search",
    name: "membership",
    size: "small",
  } as UITypes.TableHeaderCell,
]

export const Customers = () => {
  const [triggerGetClients, { isFetching, data }] = useLazyGetClientsQuery()
  const [triggerGetClient] = useLazyGetClientQuery()
  const { urlQueryParams } = useUrlQueryParams()
  const dispatch = useAppDispatch()
  const [isFirstRender, setFirstRender] = useState(true)

  const { handleExport } = useExportCSV({
    params: urlQueryParams,
    headerCells: tableHeaderCells,
    meta: data?.meta,
    fileName: "customers",
    convertData: convertClientsToTableRows,
    useLazyQuery: useLazyGetClientsQuery,
  })

  const sendRequest = (params: UITypes.Params) => {
    try {
      triggerGetClients(params).then((res: any) => {
        if (res?.error) {
          toast.error(res?.error?.data?.message)
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  // const onShowToggleDisabled = () => {
  //   setUrlQueryParams({
  //     isEnabled: urlQueryParams?.isEnabled === 0 ? null : 0,
  //   })
  // }

  const onEditClick = async (id: number) => {
    try {
      const res = await triggerGetClient(id)
      if (res.data?.data) {
        dispatch(setEditableClient(res.data?.data))
        dispatch(showEditCustomerModal(true))
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(urlQueryParams)
  }, [urlQueryParams, isFirstRender])

  return (
    <>
      <ContentWrapper title="Customers">
        <nav className={styles.tableNavWrapper}>
          <div></div>
          <div className={styles.tableNavButtonsWrapper}>
            {/* <CheckboxNew
              className={styles.tableNavItem}
              isReverse={true}
              id="fulfilled"
              onChange={onShowToggleDisabled}
            >
              Show Disabled
            </CheckboxNew> */}
            <ExportButton onClick={handleExport} className={styles.tableNavItem} disabled={data?.meta?.total === 0}>
              Export
            </ExportButton>
          </div>
        </nav>
        <TableWrapper
          tableName="customers"
          onEditClick={onEditClick}
          headerCells={tableHeaderCells}
          bodyRows={convertClientsToTableRows(data?.data)}
          isLoading={isFetching}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
