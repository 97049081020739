import { useEffect } from "react"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { showPerformanceTestCancelModal, showPerformanceTestActionsModal } from "../../../store/modalSlise"
import { convertDataToPerformanceTestTableRows } from "../../../utils/convertData"
import { WidgetPerformanceTest } from "../../../components/widgets/WidgetPerformanceTest"
import { setSelectedDevicesForPerformanceTest } from "../../../store/tempDataSlise"
import { ApiTypes } from "../../../types"
import { Pagination } from "../../../components/common/Pagination"
import TableWrapper from "../../../components/table/TableWrapper"
import styles from "./index.module.scss"
import performanceTestTableHeaderCells from "./performanceTestTableHeaderCells"

interface PerformanceTestContentProps {
  devices: ApiTypes.Model.Device[]
  meta: ApiTypes.Model.Pagination | undefined
  isFetching: boolean
  widgetData: ApiTypes.Res.PerformanceTestWidgetData
  isFetchingWidgetData: boolean
  onExport: () => void
}

const PerformanceTestContent = ({
  devices,
  meta,
  isFetching,
  widgetData,
  isFetchingWidgetData,
  onExport,
}: PerformanceTestContentProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { devices: selectedDevices } = useAppSelector((state) => state.tempData.selectedDevicesForPerformanceTest)

  useEffect(() => {
    dispatch(setSelectedDevicesForPerformanceTest({ devices: [] }))
  }, [])

  const handlePerformanceTestActions = () => {
    dispatch(showPerformanceTestActionsModal(true))
  }

  const handleCancelPerformanceTest = () => {
    dispatch(showPerformanceTestCancelModal(true))
  }

  const onSelectDeviceForPerformanceTest = (id?: number) => {
    if (selectedDevices?.some((item) => item.id === id)) {
      dispatch(setSelectedDevicesForPerformanceTest({ devices: selectedDevices?.filter((item) => item.id !== id) }))
    } else {
      const selectedItem = devices.find((item) => item.id === id)
      dispatch(
        setSelectedDevicesForPerformanceTest({
          isImmediate: false,
          devices: [
            ...selectedDevices,
            ...[{ id, email: selectedItem?.email, sn: selectedItem?.sn, system_type: selectedItem?.system_type }],
          ] as ApiTypes.Model.PerformanceTestDevice[],
        }),
      )
    }
  }

  return (
    <>
      <div className={styles.widgetsWrapper}>
        <WidgetPerformanceTest widgetData={widgetData} isFetching={isFetchingWidgetData} />
      </div>
      <ContentWrapper title="Performance Test">
        <nav className={styles.tableNavWrapper}>
          <div className={styles.tableNavButtonsWrapper}>
            <ButtonNew
              className={classNames(styles.tableNavItem, styles.startPerformanceButton)}
              type="submit"
              disabled={selectedDevices.length === 0}
              onClick={handlePerformanceTestActions}
            >
              Start Performance Test
            </ButtonNew>
            <ButtonNew
              className={styles.cancelButton}
              type="submit"
              disabled={selectedDevices.length === 0}
              onClick={handleCancelPerformanceTest}
            >
              CANCEL
            </ButtonNew>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <ButtonNew className={styles.tableNavItem} onClick={() => navigate("/perf-test-history")}>
              History
            </ButtonNew>
            <ButtonNew className={styles.tableNavItem} onClick={onExport} disabled={meta?.total === 0}>
              Export
            </ButtonNew>
          </div>
        </nav>
        <TableWrapper
          headerCells={performanceTestTableHeaderCells}
          bodyRows={convertDataToPerformanceTestTableRows(devices)}
          isLoading={isFetching}
          onCheckboxChange={onSelectDeviceForPerformanceTest}
          currentItemIDs={selectedDevices.map((item) => item.id)}
        />
        <Pagination meta={meta} />
      </ContentWrapper>
    </>
  )
}

export default PerformanceTestContent
