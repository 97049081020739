import styles from "./index.module.scss"
import classNames from "classnames"
import { UITypes } from "../../../types"

interface Props {
  type: UITypes.DevicePhysicalStatus | string
}

export const StatusOfPhysical = ({ type }: Props) => {
  let label = ""

  switch (type) {
    case "stocked":
      label = "In Stock"
      break

    case "inventory":
      label = "In inventory"
      break

    case "installed":
      label = "Installed"
      break

    case "missing":
      label = "Missing"
      break

    case "production":
      label = "Production"
      break

    case "packed":
      label = "Packed"
      break

    case "warranty":
      label = "Warranty"
      break

    case "damaged":
      label = "Damaged"
      break

    case "preinstalled":
      label = "Pre-installed"
      break

    case "transferring":
      label = "Transferring"
      break

    default:
      label = type
      break
  }

  return (
    <span
      className={classNames(styles.physicalStatus, {
        [styles[type]]: type ? true : false,
      })}
    >
      {label}
    </span>
  )
}
