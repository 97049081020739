import styles from "../../assets/scss/index.module.scss"
import { createPortal } from "react-dom"
import { ReactComponent as IconWarranty } from "../../assets/svg/modal-icon-warranty.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import {
  useLazyGetAdminDevicesQuery,
  useLazyPutDevicesReturnFromWarrantyQuery,
  useLazyGetPhysicalStatusesWidgetQuery,
  useLazyGetInstalledDevicesWidgetQuery,
} from "../../store/api/adminApi"
import { setSelectedDeviceIDs } from "../../store/tempDataSlise"
import { toast } from "react-toastify"
import useUrlQueryParams from "../../hooks/useUrlQueryParams"

interface Props {
  onCancel: () => void
}

export const WarrantyModal = ({ onCancel }: Props) => {
  const [triggerReturnFromWarranty, { isFetching }] = useLazyPutDevicesReturnFromWarrantyQuery()
  const [triggerGetPhysicalStatusesWidget] = useLazyGetPhysicalStatusesWidgetQuery()
  const [triggerGetInstalledDEvicesWidget] = useLazyGetInstalledDevicesWidgetQuery()
  const [triggerGetAdminDevices] = useLazyGetAdminDevicesQuery()
  const dispatch = useAppDispatch()
  const selectedDeviceIDs = useAppSelector((state) => state.tempData.selectedDeviceIDs)
  const { urlQueryParams } = useUrlQueryParams()

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      const res: any = await triggerReturnFromWarranty({ device_ids: selectedDeviceIDs })
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("The status of the selected device(s) was changed successfully!")
        dispatch(setSelectedDeviceIDs([]))
        triggerGetAdminDevices(urlQueryParams)
        triggerGetPhysicalStatusesWidget()
        triggerGetInstalledDEvicesWidget()
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconWarranty />
            <h3 className={styles.modalTitle}>Return from warranty</h3>
            <div className={styles.modalMessage}>
              Are you sure you want to return the selected device(s) from warranty?{" "}
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
