import { PropsWithChildren } from "react"
import { Outlet } from "react-router-dom"
import { ContractorTopbar } from "../../components/topbars/ContractorTopbar"
import { TechnicianTopbar } from "../../components/topbars/TechnicianTopbar"
import { VendorTopbar } from "../../components/topbars/VendorTopbar"
import { WerehouseTopbar } from "../../components/topbars/WerehouseTopbar"
import { SalesManagerTopbar } from "../../components/topbars/SalesManagerTopbar"
import { useAppSelector } from "../../store/hooks"
import { checkRoleType } from "../../utils/checkRoleType"
import useUserProfileSync from "./useUserProfileSync"
import styles from "./index.module.scss"

const MainLayout = ({ children }: PropsWithChildren) => {
  useUserProfileSync()
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const userRole = checkRoleType(userProfile?.type)

  const setNecessaryTopbar = () => {
    if (userRole === "contractor-manager") {
      return <ContractorTopbar />
    }
    if (userRole === "contractor-technician") {
      return <TechnicianTopbar />
    }
    if (userRole === "vendor-admin") {
      return <VendorTopbar />
    }
    if (userRole === "warehouse-manager") {
      return <WerehouseTopbar />
    }
    if (userRole === "sales-manager") {
      return <SalesManagerTopbar />
    }
    return null
  }

  return (
    <section className={styles.pageWrapper}>
      {setNecessaryTopbar()}
      {children}
      <Outlet />
    </section>
  )
}

export default MainLayout
