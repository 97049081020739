import styles from "./index.module.scss"
import { ReactComponent as UserAvatar } from "../../../assets/svg/user-avatar.svg"
import { ReactComponent as IconLogout } from "../../../assets/svg/icon-logout.svg"
import classNames from "classnames"
import { useState } from "react"
import { useAppSelector, useAppDispatch } from "../../../store/hooks"
import { useNavigate } from "react-router-dom"
import { showAccountSettingsModal } from "../../../store/modalSlise"
import { setUserProfile } from "../../../store/userSlise"

export const UserMenu = () => {
  const dispatch = useAppDispatch()
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const navigate = useNavigate()
  let timeoutId

  const onLogOut = () => {
    dispatch(setUserProfile(null))
    sessionStorage.clear()
    const userTemperatureScale = localStorage.getItem("userTemperatureScale")
    localStorage.clear()
    localStorage.setItem("userTemperatureScale", userTemperatureScale!)
    window.location.reload()
    navigate("/")
  }

  const openDropdown = () => {
    clearTimeout(timeoutId)
    setDropdownVisible(true)
  }

  const closeDropdown = () => {
    timeoutId = setTimeout(() => {
      setDropdownVisible(false)
    }, 300)
  }

  const openAccountSettings = () => {
    // check is it contractor type
    if (userProfile?.type === 4) {
      dispatch(showAccountSettingsModal(true))
    }
  }

  return (
    <div className={styles.userMenuWrapper} onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
      <figure className={classNames(styles.userMenuButton, { [styles.active]: isDropdownVisible })}>
        <UserAvatar className={styles.userAvatarWhite} />
      </figure>
      {isDropdownVisible && (
        <div className={styles.userDropdown} onMouseEnter={openDropdown}>
          <div className={styles.userDropdownItem} onClick={openAccountSettings}>
            <UserAvatar className={classNames(styles.itemIcon, styles.userAvatarBlack)} />
            <div>
              <div>{userProfile?.username}</div>
              <div className={styles.userEmail}>{userProfile?.email}</div>
            </div>
          </div>
          <div className={styles.userDropdownItem} onClick={onLogOut}>
            <IconLogout className={styles.itemIcon} />
            Log out
          </div>
        </div>
      )}
    </div>
  )
}
