import dayjs from "dayjs"
import { Line } from "react-chartjs-2"
import { Chart, registerables } from "chart.js"
import classNames from "classnames"
import { borderColorWithSkipped, borderDashWithSkipped, defaultChartTooltipOptions } from "../../utils/chartUtils"
import { NoDataLayer } from "../../components/chart/NoDataLayer"
import { ApiTypes } from "../../types"
import getLegendMarginPlugin from "../../utils/chartjs/getLegendMarginPlugin"
import styles from "./index.module.scss"

Chart.register(...registerables)

interface Props {
  settings: {
    ticksCount: number
    dataCountsDivider: number
  }
  historicalData: ApiTypes.Res.MonitoringHistoricalDataBucket[]
  isSingleDataPoint: boolean
  showSetHumidity: boolean
}

const HumidityChart = ({ settings, historicalData, isSingleDataPoint, showSetHumidity }: Props) => {
  const parsedData =
    historicalData.map((item) => ({
      humidity: item["avg#average_current_humidity_embedded"].value,
      setHumidity: item["avg#average_set_humidity"].value,
      timestamp: item.key_as_string,
    })) || []
  const noData = parsedData.length === 0

  const commonDatasetValues = {
    fill: "start",
    backgroundColor: "transparent",
    pointStyle: isSingleDataPoint as unknown as "circle",
    spanGaps: true,
    segment: {
      borderDash: borderDashWithSkipped,
      borderColor: borderColorWithSkipped,
    },
  }

  const data = {
    labels: [...parsedData.map((item) => item.timestamp)],
    datasets: [
      {
        label: "Current humidity",
        ...commonDatasetValues,
        data: parsedData.map((item) => item.humidity),
        borderColor: "#6266f1",
        pointBackgroundColor: "#6266f1",
      },
      ...(showSetHumidity
        ? [
            {
              label: "Set humidity",
              ...commonDatasetValues,
              data: parsedData.map((item) => item.setHumidity),
              borderColor: "#f6ad55",
              pointBackgroundColor: "#f6ad55",
            },
          ]
        : []),
      ...(parsedData.length === 0
        ? [
            {
              label: "",
              data: [50, 51, 52, 53, 54],
            },
          ]
        : []),
    ],
  }

  const options = {
    maintainAspectRatio: false,
    interaction: {
      mode: "index" as const,
    },
    plugins: {
      legend: {
        display: !noData,
        align: "end" as const,
        labels: {
          color: "#94A3B8",
          usePointStyle: true,
          pointStyle: "rect",
        },
      },
      tooltip: {
        ...defaultChartTooltipOptions,
        displayColors: true,
        callbacks: {
          title: function (this: any, tooltipItem) {
            const date = dayjs(tooltipItem[0].label)
            return date.format("YYYY-MM-DD HH:mm")
          },
          label: function (this: any, tooltipItem) {
            return `${tooltipItem.formattedValue}%`
          },
        },
      },
    },
    scales: {
      x: {
        border: {
          color: "#e2e8f0",
        },
        grid: {
          color: "#e2e8f0",
        },
        ticks: {
          maxRotation: 0,
          maxTicksLimit: settings.ticksCount,
          color: "#94A3B8",
          callback: (value) => value / settings.dataCountsDivider,
        },
      } as any,
      y: {
        position: "left",
        border: {
          display: false,
          color: "rgba(0,0,0,0)",
        },
        ticks: {
          maxTicksLimit: 6,
          stepSize: parsedData.length === 0 ? 1 : 0.1,
          color: "#94A3B8",
          callback: function (value) {
            return `${value.toFixed(1)}%`
          },
        },
      } as any,
    },
    onHover: (ctx) => {
      ctx.chart.data.datasets[0].pointStyle = true
      ctx.chart.data.datasets[1].pointStyle = true
    },
  }

  return (
    <div
      className={classNames(styles.chartContainer, {
        [styles.chartContainerWithLabel]: !noData,
        [styles.emptyReportDataWrapper]: noData,
      })}
    >
      {noData && <NoDataLayer />}
      <Line data={data} options={options} plugins={[getLegendMarginPlugin(15)]} />
    </div>
  )
}

export default HumidityChart
