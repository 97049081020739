import { ApiTypes } from "../../../types"

export const dummyMeta: ApiTypes.Model.Pagination = {
  total: 2,
  per_page: 10,
  current_page: 1,
  last_page: 1,
  from: 1,
  to: 2,
  links: [],
}

export const dummyAlerts = [
  {
    id: 1,
    sn: "01-000-000001",
    address1: "",
    country: "Canada",
    state: "Alberta",
    city: "Calgary",
    zip: "T0L",
    date: "2024-11-26",
    status: "active",
    type: "offline",
  },
  {
    id: 2,
    sn: "01-000-000002",
    address1: "",
    country: "Canada",
    state: "Alberta",
    city: "Calgary",
    zip: "T0L",
    date: "2024-11-26",
    status: "active",
    type: "online",
  },
  {
    id: 3,
    sn: "01-000-000003",
    address1: "",
    country: "Canada",
    state: "Alberta",
    city: "Calgary",
    zip: "T0L",
    date: "2024-11-26",
    status: "active",
    type: "online",
  },
]

export const dummyAlertsStatusesWidgetData: ApiTypes.Res.AlertStatusesWidget = {
  active: 1,
  frozen: 1,
  acknowledged: 1,
}

export const dummyAlertsWidgetData: ApiTypes.Model.AlertsWidgetData = {
  airQuality: 1,
  ambientSensorMalfunction: 1,
  fanMalfunction: 1,
  humiditySensorMalfunction: 1,
  offline: 1,
  temperature: 1,
  temperatureSensorMalfunction: 1,
  enrolledDevicesCount: 1,
  devicesWithActiveAlerts: 1,
  alertsCount: 1,
}
