import { ApiTypes } from "../types"
import { storageKeys } from "./storageKeys"

const updateStorageUser = (userProps: Partial<ApiTypes.Model.UserProfile>) => {
  const userProfile = localStorage.getItem(storageKeys.userProfile)
  if (userProfile) {
    const parsedUserProfile: ApiTypes.Model.UserProfile = JSON.parse(userProfile)
    localStorage.setItem(storageKeys.userProfile, JSON.stringify({ ...parsedUserProfile, ...userProps }))
  }
}

export default updateStorageUser
