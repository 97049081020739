import { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
  useLazyGetPerformanceTestsQuery,
  useLazyGetPerformanceTestWidgetDataQuery,
} from "../../../store/api/performanceTestApi"
import { setSelectedDevicesForPerformanceTest } from "../../../store/tempDataSlise"
import { UITypes } from "../../../types"
import PerformanceTestContent from "./PerformanceTestContent"
import hasSubscriptionAccess from "../../../utils/hasSubscriptionAccess"
import SubscriptionPrivilege from "../../../types/enums/SubscriptionPrivilege"
import { SubscriptionUpgradeModal } from "../../../modals/SubscriptionUpgradeModal/SubscriptionUpgradeModal"
import { dummyDevices, dummyMeta } from "../MonitoringPage/dummyMonitoringData"
import { dummyPerfTestWidgetData } from "./dummyPerfTestData"
import useUrlQueryParams from "../../../hooks/useUrlQueryParams"
import useExportCSV from "../../../hooks/useExportCSV"
import performanceTestTableHeaderCells from "./performanceTestTableHeaderCells"
import { convertDataToPerformanceTestTableRows } from "../../../utils/convertData"

const initialState = {
  scheduled: 0,
  rescheduled: 0,
  passed: 0,
  issue: 0,
  running: 0,
}

const PerformanceTestPage = () => {
  const dispatch = useAppDispatch()
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const [triggerGetPerformanceTest, { isFetching, data }] = useLazyGetPerformanceTestsQuery()
  const { urlQueryParams } = useUrlQueryParams()
  const requestParams = { phisical_status: "installed", ...urlQueryParams }
  const [triggerGetPerformanceTestWidgetData, { isFetching: isFetchingWidgetData }] =
    useLazyGetPerformanceTestWidgetDataQuery()
  const [widgetData, setWidgetData] = useState(initialState)
  const hasPerformanceTestAccess = userProfile
    ? hasSubscriptionAccess(userProfile, SubscriptionPrivilege.PerformanceTest)
    : false
  const widgetInfo = hasPerformanceTestAccess ? widgetData : dummyPerfTestWidgetData
  const isFetchingData = isFetching && !data
  const meta = hasPerformanceTestAccess ? data?.meta : dummyMeta
  const devices = useMemo(() => {
    if (!hasPerformanceTestAccess) {
      return dummyDevices
    }

    return data?.data || []
  }, [data, hasPerformanceTestAccess])

  const { handleExport } = useExportCSV({
    params: requestParams,
    headerCells: performanceTestTableHeaderCells,
    meta: meta,
    fileName: "performance_tests",
    convertData: convertDataToPerformanceTestTableRows,
    useLazyQuery: useLazyGetPerformanceTestsQuery,
  })

  const sendRequest = async (params: UITypes.Params) => {
    if (!hasPerformanceTestAccess) {
      return
    }

    try {
      const res: any = await triggerGetPerformanceTest(requestParams)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    dispatch(setSelectedDevicesForPerformanceTest({ devices: [] }))

    const fetchPerformanceTestWidgetData = async () => {
      try {
        const res = await triggerGetPerformanceTestWidgetData()
        if (res?.data) {
          setWidgetData({
            scheduled: res?.data?.scheduled,
            rescheduled: res?.data?.rescheduled,
            passed: res?.data?.passed,
            issue: res?.data?.issue,
            running: res?.data?.running,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (hasPerformanceTestAccess) {
      fetchPerformanceTestWidgetData()
    }
  }, [])

  useEffect(() => {
    sendRequest(requestParams)
  }, [urlQueryParams])

  return (
    <>
      <PerformanceTestContent
        devices={devices}
        meta={meta}
        isFetching={isFetchingData}
        widgetData={widgetInfo}
        isFetchingWidgetData={isFetchingWidgetData}
        onExport={handleExport}
      />
      {!hasPerformanceTestAccess && <SubscriptionUpgradeModal page={SubscriptionPrivilege.PerformanceTest} />}
    </>
  )
}

export default PerformanceTestPage
