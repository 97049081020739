import styles from "./index.module.scss"
import classNames from "classnames"
import { Tooltip } from "react-tooltip"
import { StatusOfOrder } from "../StatusOfOrder"
import { StatusOfAlert } from "../StatusOfAlert"
import { StatusOfWHDevice } from "../StatusOfWHDevice"
import { StatusOfWHOrder } from "../StatusOfWHOrder"
import { StatusOfMessageReport } from "../StatusOfMessageReport"
import { StatusOfDeviceStorage } from "../StatusOfDeviceStorage"
import { StatusOfPhysical } from "../StatusOfPhysical"
import { StatusOfWork } from "../StatusOfWork"
import { TableSort } from "../TableSort"
import { TableRowNavigation } from "../TableRowNavigation"
import { TableSearch } from "../TableSearch"
import { CheckboxNew } from "../../form/CheckboxNew"
import { UITypes } from "../../../types"
import { v4 as uuidv4 } from "uuid"
import { Spinner } from "../../common/Spinner"
import { ReactComponent as IconEye } from "../../../assets/svg/icon-eye.svg"
import { ReactComponent as IconAdd } from "../../../assets/svg/add-icon.svg"
import { ReactComponent as PerformanceTestIcon } from "../../../assets/svg/menu-performance.svg"
import { ReactComponent as StatsReportIcon } from "../../../assets/svg/icon-stats-report.svg"
import { ReactComponent as IconPower } from "../../../assets/svg/icon-power.svg"
import { ReactComponent as IconLog } from "../../../assets/svg/icon-log.svg"
import { TableSelect } from "../TableSelect"
import { TableDatePicker } from "../TableDatePicker"
import {
  headingsPlugin,
  listsPlugin,
  tablePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  MDXEditor,
} from "@mdxeditor/editor"
import { StatusOfPerformanceTest } from "../StatusOfPerformanceTest"
import { PerformanceTestLabel } from "../PerformanceTestLabel"
import { OptedInOut } from "../OptedInOut"
import ContractorCompanyName from "../components/ContractorCompanyName"

export interface TableProps {
  tableName?: string
  headerCells: UITypes.TableHeaderCell[]
  bodyRows?: UITypes.TableBodyRow[]
  isLoading?: boolean
  currentItemID?: number | string | null
  currentItemIDs?: number[] | string[] | undefined
  statusOptions?: UITypes.Option[]
  className?: string
  isHeaderChecked?: boolean
  editable?: boolean
  areHeaderColumnsDynamic?: boolean
  onHeaderCheckboxChange?: () => void
  onCheckboxChange?: (id: number) => void
  onDeleteClick?: (id: number) => void
  onEditClick?: (id: number) => void
  onViewClick?: (id: number) => void
  dynamicNavHandler1?: (id: number) => void
  dynamicNavHandler2?: (id: number) => void
  dynamicNavHandler3?: (id: number) => void
  onSort?: (data: UITypes.Sort) => void
  onRowClick?: (id: number) => void
}

export const Table = ({
  tableName,
  headerCells,
  bodyRows,
  isLoading,
  currentItemID,
  currentItemIDs,
  className,
  isHeaderChecked,
  areHeaderColumnsDynamic,
  onDeleteClick,
  onEditClick,
  onViewClick,
  dynamicNavHandler1,
  dynamicNavHandler2,
  dynamicNavHandler3,
  onCheckboxChange,
  onHeaderCheckboxChange,
  onRowClick,
}: TableProps) => {
  const renderCurrentHeaderCell = (cell: UITypes.TableHeaderCell) => {
    const size = cell.size ? cell.size : ""

    if (areHeaderColumnsDynamic) {
      if (!cell?.visibility) return null
    }

    switch (cell.type) {
      case "sort-search":
        return (
          <div key={`${cell.name}_search`} className={classNames(styles.tableHeaderCell, styles[size])}>
            <TableSort label={cell.title} name={cell.name} />
            <TableSearch label={cell.title} name={cell.name} />
          </div>
        )

      case "sort-date":
        return (
          <div key={`${cell.name}_date`} className={classNames(styles.tableHeaderCell, styles[size])}>
            <TableSort label={cell.title} name={cell.name} />
            <TableDatePicker label={cell.title} name={cell.name} />
          </div>
        )

      case "sort":
        return (
          <div key={`${cell.name}_sort`} className={classNames(styles.tableHeaderCell, styles[size])}>
            <TableSort label={cell.title} name={cell.name} />
          </div>
        )

      case "select":
        return (
          <div
            key={`${cell.name}_select`}
            className={classNames(styles.tableHeaderCell, styles.withoutGaps, styles[size])}
          >
            <TableSelect headerCell={cell} />
          </div>
        )

      case "checkbox":
        return (
          <div key={`${cell.name}_checkbox`} className={classNames(styles.tableHeaderCell, styles.tableCellCheckbox)}>
            <CheckboxNew isChecked={isHeaderChecked} onChange={onHeaderCheckboxChange} id={uuidv4()} />
          </div>
        )

      default:
        return (
          <div
            key={uuidv4()}
            className={classNames(styles.tableHeaderCell, styles[size], { [styles.tableCellCheckbox]: !cell.title })}
          >
            {cell.title}
          </div>
        )
    }
  }

  // TODO: change item to generic
  const renderCurrentBodyCell = (cell: UITypes.TableBodyCell, id: number, item: any) => {
    const size = cell.size ? cell.size : ""
    const color = cell.color ? cell.color : ""
    const classNamesList = classNames(styles.tableCell, styles[size], styles[color], {
      [styles.action]: cell.callback ? true : false,
    })

    switch (cell.type) {
      case "status":
        return (
          <div key={uuidv4()} className={classNames(classNamesList)}>
            <StatusOfOrder type={cell.value as UITypes.OrderStatus} />
          </div>
        )

      case "alert-status":
        return (
          <div key={uuidv4()} className={classNamesList}>
            <StatusOfAlert type={cell.value as UITypes.AlertStatus} />
          </div>
        )

      case "wh-device-status":
        return (
          <div key={uuidv4()} className={classNamesList}>
            <StatusOfWHDevice type={cell.value as string} />
          </div>
        )

      case "wh-order-status":
        return (
          <div key={uuidv4()} className={classNamesList}>
            <StatusOfWHOrder type={cell.value as string} />
          </div>
        )

      case "message-report-status":
        return (
          <div key={uuidv4()} className={classNamesList}>
            <StatusOfMessageReport type={cell.value as string} />
          </div>
        )

      case "devices-storage-status":
        return (
          <div key={uuidv4()} className={classNamesList}>
            <StatusOfDeviceStorage type={cell.value as UITypes.DeviceStorageStatus} />
          </div>
        )

      case "devices-physical-status":
        return (
          <div key={uuidv4()} className={classNamesList}>
            <StatusOfPhysical type={cell.value as UITypes.DevicePhysicalStatus} />
          </div>
        )

      case "performance-test-status":
        return (
          <div key={uuidv4()} className={classNamesList}>
            <StatusOfPerformanceTest type={cell.value as UITypes.PerformanceTestStatus} currentItem={item} />
          </div>
        )

      case "performance-test-label":
        return (
          <div key={uuidv4()} className={classNamesList}>
            <PerformanceTestLabel label={cell.value as string} />
          </div>
        )

      case "devices-work-status":
        return (
          <div key={uuidv4()} className={classNamesList}>
            <StatusOfWork type={cell.value as UITypes.DeviceWorkStatus} />
          </div>
        )

      case "group-separator":
        return (
          <div key={uuidv4()} className={classNames(classNamesList, styles.groupTitle)}>
            {cell.value}
          </div>
        )

      case "markdown":
        return (
          <div key={uuidv4()} className={classNamesList}>
            <MDXEditor
              className={styles.markdownWrapper}
              markdown={cell.value as string}
              readOnly={true}
              plugins={[
                headingsPlugin(),
                listsPlugin(),
                tablePlugin(),
                thematicBreakPlugin(),
                markdownShortcutPlugin(),
              ]}
            />
          </div>
        )

      case "boolean":
        return (
          <div key={uuidv4()} className={classNamesList}>
            {cell.value === "1" ? (
              <span className={classNames(styles.booleanValue, styles.enable, styles[size])}>Enabled</span>
            ) : (
              <span className={classNames(styles.booleanValue, styles.disable, styles[size])}>Disabled</span>
            )}
          </div>
        )

      case "checkbox": {
        let isChecked

        if (currentItemIDs) {
          isChecked = currentItemIDs?.some((item) => item === id)
        } else {
          isChecked = currentItemID === id ? true : false
        }

        return (
          <div key={uuidv4()} className={classNames(classNamesList, styles.tableCellCheckbox)}>
            <CheckboxNew
              id={uuidv4()}
              onChange={() => onCheckboxChange && onCheckboxChange(id)}
              isChecked={isChecked}
              disabled={cell.disabled}
            />
          </div>
        )
      }

      case "opt-in-out":
        return (
          <div key={uuidv4()} className={classNamesList}>
            <OptedInOut option={cell.value as string} />
          </div>
        )

      case "company-name":
        return (
          <div key={uuidv4()} className={classNames(classNamesList, styles.companyName)}>
            <ContractorCompanyName contractor={item} />
          </div>
        )

      default:
        return (
          <div key={uuidv4()} className={classNamesList} onClick={() => cell?.callback && cell.callback(id)}>
            {cell.value}
          </div>
        )
    }
  }

  const checkDynamicNavHandler = (row) => {
    if (tableName === "message-history" && dynamicNavHandler1) {
      return dynamicNavHandler1(row.id)
    }

    if (tableName === "wh-orders") {
      const whOrderStatus = row?.cells?.find((item) => item?.type === "wh-order-status")

      if (whOrderStatus?.value === "confirmed") {
        dynamicNavHandler1 && dynamicNavHandler1(row.id)
      }

      if (whOrderStatus?.value === "packed") {
        dynamicNavHandler2 && dynamicNavHandler2(row.id)
      }
    }

    if (!dynamicNavHandler1 || !dynamicNavHandler2 || row?.isEnable === undefined) return false
  }

  const checkDynamicNavIcon = (row) => {
    if (tableName === "message-history") {
      return IconEye
    }

    if (
      tableName === "message-library-templates" &&
      dynamicNavHandler1 &&
      dynamicNavHandler2 &&
      row?.isEnable !== false
    ) {
      return () => (
        <div className={styles.messageTemplateButtonContainer}>
          <span onClick={() => dynamicNavHandler2(row.id)} data-tooltip-id="reportBtn">
            <IconEye />
          </span>
          <Tooltip
            id="reportBtn"
            place="top-start"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={styles.tooltip}
          >
            View template
          </Tooltip>
          <span onClick={() => dynamicNavHandler1(row.id)} data-tooltip-id="perfTestBtn">
            <IconAdd />
          </span>
          <Tooltip
            id="perfTestBtn"
            place="top-start"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={styles.tooltip}
          >
            Add to my templates
          </Tooltip>
        </div>
      )
    }

    if (tableName === "wh-orders") {
      if (row?.status === "confirmed") {
        return () => <span className={styles.dynamicNavButton}>Packing</span>
      }

      if (row?.status === "packed") {
        return () => <span className={styles.dynamicNavButton}>Shipping</span>
      }
    }

    if (tableName === "monitoring" && dynamicNavHandler1 && dynamicNavHandler2 && dynamicNavHandler3) {
      const isOffline = row.item.work_status === "offline"

      return () => (
        <div className={styles.monitoringIconsWrapper}>
          <span
            onClick={() => isOffline || dynamicNavHandler3(row.id)}
            className={classNames({ [styles.disabledNavButton]: isOffline })}
            data-tooltip-id="rebootBtn"
          >
            <IconPower />
          </span>
          <Tooltip
            id="rebootBtn"
            place="top"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={classNames(styles.tooltip)}
          >
            Reboot device
            {isOffline && (
              <>
                <br />
                (device is offline)
              </>
            )}
          </Tooltip>
          <span
            onClick={() => isOffline || dynamicNavHandler1(row.id)}
            className={classNames({ [styles.disabledNavButton]: isOffline })}
            data-tooltip-id="perfTestBtn"
          >
            <PerformanceTestIcon />
          </span>
          <Tooltip
            id="perfTestBtn"
            place="top-start"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={styles.tooltip}
          >
            Start performance test
            {isOffline && (
              <>
                <br />
                (device is offline)
              </>
            )}
          </Tooltip>
          <span onClick={() => dynamicNavHandler2(row.id)} data-tooltip-id="reportBtn">
            <StatsReportIcon />
          </span>
          <Tooltip
            id="reportBtn"
            place="top-start"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={styles.tooltip}
          >
            Monitoring report
          </Tooltip>
        </div>
      )
    }

    if (tableName === "adminDevices" && dynamicNavHandler1 && dynamicNavHandler2 && dynamicNavHandler3) {
      const isOffline = row.item.work_status === "offline"

      return () => (
        <div
          className={classNames(styles.monitoringIconsWrapper, {
            [styles.redFlag]: !!row?.isRedFlag,
          })}
        >
          <span
            onClick={() => isOffline || dynamicNavHandler3(row.id)}
            className={classNames({ [styles.disabledNavButton]: isOffline })}
            data-tooltip-id="rebootBtn"
          >
            <IconPower />
          </span>
          <Tooltip
            id="rebootBtn"
            place="top"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={classNames(styles.tooltip)}
          >
            Reboot device
            {isOffline && (
              <>
                <br />
                (device is offline)
              </>
            )}
          </Tooltip>
          <span
            onClick={() => isOffline || dynamicNavHandler1(row.id)}
            className={classNames({ [styles.disabledNavButton]: isOffline })}
            data-tooltip-id="perfTestBtn"
          >
            <IconLog />
          </span>
          <Tooltip
            id="perfTestBtn"
            place="top-start"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={styles.tooltip}
          >
            Send Logs to DB
            {isOffline && (
              <>
                <br />
                (device is offline)
              </>
            )}
          </Tooltip>
          <span onClick={() => dynamicNavHandler2(row.id)} data-tooltip-id="reportBtn">
            <StatsReportIcon />
          </span>
          <Tooltip
            id="reportBtn"
            place="top-start"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={styles.tooltip}
          >
            Monitoring report
          </Tooltip>
        </div>
      )
    }
  }

  const checkIsDeleteAvailable = (row: UITypes.TableBodyRow) => {
    if (tableName === "vendor-orders" && row.status !== "new") {
      return undefined
    }
    if (tableName === "contractor-orders" && row.status !== "new") {
      return undefined
    } else if (onDeleteClick) {
      return () => onDeleteClick(row.id)
    }
  }

  const checkIsEditAvailable = (row: UITypes.TableBodyRow) => {
    if (
      tableName === "vendor-orders" &&
      row.status !== "new" &&
      row.status !== "denied" &&
      row.status !== "confirmed" &&
      row.status !== "pending"
    ) {
      return undefined
    }
    if (tableName === "contractor-orders" && row.status !== "new") {
      return undefined
    }
    if (tableName === "customers" && row?.isEnable !== true) {
      return undefined
    } else if (onEditClick) {
      return () => onEditClick(row.id)
    }
  }

  return (
    <div className={classNames(styles.tableWrapper, className)}>
      <div className={styles.table}>
        <header className={styles.tableHeader}>{headerCells.map((item) => renderCurrentHeaderCell(item))}</header>
        <div className={styles.tableBody}>
          {bodyRows?.map((row: UITypes.TableBodyRow) => (
            <div
              key={uuidv4()}
              className={classNames(styles.tableRow, {
                [styles.active]: currentItemIDs?.includes(row.id as never) || currentItemID === row.id,
                [styles.redFlag]: !!row?.isRedFlag,
                [styles.clickableRow]: row?.isRowClickable,
              })}
              onClick={onRowClick ? () => onRowClick(row.id) : undefined}
            >
              {row.cells.map((item) => renderCurrentBodyCell(item, row.id, row.item))}
              <TableRowNavigation
                onDelete={checkIsDeleteAvailable(row)}
                onEdit={checkIsEditAvailable(row)}
                dynamicNavHandler={() => checkDynamicNavHandler(row)}
                DynamicNavIcon={checkDynamicNavIcon(row)}
                customClassName={styles.rowNavVisibility}
                redFlag={!!row?.isRedFlag}
              />
            </div>
          ))}
        </div>
      </div>
      {isLoading && (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      )}
    </div>
  )
}
