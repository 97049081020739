import "../../../assets/libs/react-datepicker.css"
import styles from "./index.module.scss"
import { useState, useEffect, useRef } from "react"
import { ReactComponent as IconTableCross } from "../../../assets/svg/icon-table-cross.svg"
import { ReactComponent as IconCalendar } from "../../../assets/svg/icon-calendar.svg"
import DatePicker from "react-datepicker"
import { useOutsideClick } from "../../../utils/useOutsideClick"
import dayjs from "dayjs"
import useUrlQueryParams from "../../../hooks/useUrlQueryParams"

interface Props {
  name?: string
  label?: string
}

export const TableDatePicker = ({ name, label }: Props) => {
  const [isActive, setActive] = useState(false)
  const [value, setValue] = useState("")
  const { urlQueryParams, setUrlQueryParams } = useUrlQueryParams()
  const datePickerRef = useRef(null)

  useOutsideClick(datePickerRef, () => setActive(false))

  const onClear = () => {
    setValue("")
    name && setUrlQueryParams({ [name]: null })
    setActive(false)
  }

  const onDateSelect = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD")

    name &&
      setUrlQueryParams({
        [name]: formattedDate,
      })

    setValue(formattedDate)
    setActive(false)
  }

  useEffect(() => {
    if (name && urlQueryParams[name] && !value) {
      setValue(urlQueryParams[name])
      setActive(true)
    }
  }, [urlQueryParams])

  return (
    <div className={styles.tableDateWrapper} onClick={() => {}}>
      {isActive ? (
        <div className={styles.inputWrapper} ref={datePickerRef}>
          <DatePicker value={value} dateFormat="y-m-d" open={true} name={name} inline onSelect={onDateSelect} />
        </div>
      ) : value ? (
        <div className={styles.valueWrapper}>
          <span className={styles.value}>{value}</span>
          <span className={styles.icon} onClick={onClear}>
            <IconTableCross />
          </span>
        </div>
      ) : (
        <span className={styles.icon} onClick={() => setActive(true)}>
          <IconCalendar />
        </span>
      )}
    </div>
  )
}
