import { Tooltip as ReactTooltip } from "react-tooltip"
import { PropsWithChildren } from "react"
import styles from "./index.module.scss"
import "./tooltip.css"

interface Props extends PropsWithChildren {
  id: string
  text?: string
  className?: string
  orientation?: "top" | "bottom" | "left" | "right" | "top-start"
}
export const Tooltip = ({ children, id, text = "", orientation = "top-start", className }: Props) => {
  return (
    <div className={className}>
      <span data-tooltip-id={id} className={styles.tooltipText}>
        {text}
      </span>
      <ReactTooltip classNameArrow={styles.tooltipArrow} className={styles.tooltip} place={orientation} id={id}>
        {children}
      </ReactTooltip>
    </div>
  )
}
