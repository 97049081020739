import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Pagination } from "../../../components/common/Pagination"
import { UITypes } from "../../../types"
import styles from "./index.module.scss"
import { ButtonNew } from "../../../components/form/ButtonNew"
import classNames from "classnames"
import { WidgetDevicesStatus } from "../../../components/widgets/WidgetDevicesStatus"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
  showDamageDeviceModalForWarehouse,
  showMissingModalForWarehouse,
  showAddDeviceModal,
} from "../../../store/modalSlise"
import { useLazyGetWHDevicesQuery } from "../../../store/api/warehouseManagerApi"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import { convertWHManagerDevicessToTableRows } from "../../../utils/convertData"
import { setSelectedDeviceIDs } from "../../../store/tempDataSlise"
import TableWrapper from "../../../components/table/TableWrapper"
import { warehouseManagerDeviceStatusOptions } from "../../../constants/SelectFieldOptions"
import useUrlQueryParams from "../../../hooks/useUrlQueryParams"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "",
    type: "checkbox",
  },
  {
    title: "Device S/N",
    type: "sort-search",
    size: "large",
    name: "sn",
  },
  {
    title: "Date of Input",
    type: "sort-date",
    size: "large",
    name: "input_date",
  },
  {
    title: "Status",
    type: "select",
    size: "large",
    name: "phisical_status",
    options: warehouseManagerDeviceStatusOptions,
  },
]

export const Devices = () => {
  const [triggerGetWHDevices, { data, isFetching }] = useLazyGetWHDevicesQuery()
  const dispatch = useAppDispatch()
  const { urlQueryParams } = useUrlQueryParams()
  const selectedDeviceIDs = useAppSelector((state) => state.tempData.selectedDeviceIDs)
  const [isHeaderChecked, setHeaderChecked] = useState(false)
  const [isFirstRender, setFirstRender] = useState(true)

  const toggleAllItems = () => {
    if (isHeaderChecked) {
      dispatch(setSelectedDeviceIDs([]))
      setHeaderChecked(false)
    } else {
      const result = data?.data?.map((item) => item?.id)
      result && dispatch(setSelectedDeviceIDs(result))
      setHeaderChecked(true)
    }
  }

  const onSelectItem = (id?: number) => {
    if (selectedDeviceIDs?.some((item) => item === id)) {
      dispatch(setSelectedDeviceIDs(selectedDeviceIDs?.filter((item) => item !== id)))
    } else {
      dispatch(setSelectedDeviceIDs([...selectedDeviceIDs, ...[id]] as number[]))
    }
  }

  const onDamagedClick = () => {
    dispatch(showDamageDeviceModalForWarehouse(true))
  }

  const onMissingClick = () => {
    dispatch(showMissingModalForWarehouse(true))
  }

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = await triggerGetWHDevices(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(urlQueryParams)
  }, [urlQueryParams, isFirstRender])

  return (
    <>
      <div className={styles.widgetsWrapper}>
        <WidgetDevicesStatus />
      </div>
      <ContentWrapper title="Devices">
        <nav className={styles.tableNavWrapper}>
          <div>
            <ButtonNew onClick={() => dispatch(showAddDeviceModal(true))} color="blue">
              Add New Device
            </ButtonNew>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            {selectedDeviceIDs?.length ? (
              <>
                <span onClick={onMissingClick} className={classNames(styles.tagButton, styles.yellow)}>
                  MARK AS MISSING
                </span>
                <span onClick={onDamagedClick} className={classNames(styles.tagButton, styles.red)}>
                  MARK AS DAMAGED
                </span>
              </>
            ) : (
              <>
                <span className={classNames(styles.tagButton)}>MARK AS MISSING</span>
                <span className={classNames(styles.tagButton)}>MARK AS DAMAGED</span>
              </>
            )}
          </div>
        </nav>
        <TableWrapper
          headerCells={tableHeaderCells}
          bodyRows={convertWHManagerDevicessToTableRows(data?.data)}
          isLoading={isFetching}
          onCheckboxChange={onSelectItem}
          onHeaderCheckboxChange={toggleAllItems}
          currentItemIDs={selectedDeviceIDs}
          isHeaderChecked={isHeaderChecked}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
