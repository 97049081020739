import merge from "lodash.merge"
import values from "lodash.values"
import keyby from "lodash.keyby"

export const reorderTableHeaderCells = (columnsFE, columnsBE) => {
  const keyedArray1 = keyby(columnsFE, "name")
  const keyedArray2 = keyby(columnsBE, "name")

  const merged = merge({}, keyedArray1, keyedArray2)
  const result = values(merged)
    .filter((item) => keyedArray1[item.name])
    .map((item) => ({ ...item, visibility: item.visibility ?? true }))

  result.sort((a, b) => {
    const indexA = columnsBE.findIndex((item) => item.name === a.name)
    const indexB = columnsBE.findIndex((item) => item.name === b.name)

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB
    }

    const FEindexA = columnsFE.findIndex((item) => item.name === a.name)
    const FEindexB = columnsFE.findIndex((item) => item.name === b.name)

    return FEindexA - FEindexB
  })

  return result
}

export const reorderTabbleBodyCells = (sourceArray, referenceArray) => {
  const visibilityMap = referenceArray.reduce((acc, item) => {
    acc[item.name] = item.visibility
    return acc
  }, {})

  const filteredSource = sourceArray.filter((item) => visibilityMap[item.name] !== undefined)

  const orderedArray = referenceArray
    .filter((refItem) => refItem.visibility)
    .map((refItem) => filteredSource.find((srcItem) => srcItem.name === refItem.name))
    .filter((item) => item !== undefined)

  return orderedArray
}
