import { ScriptableContext } from "chart.js"
import { ApiTypes } from "../types"
import dayjs, { Dayjs } from "dayjs"

export const getChartGradient = (context: ScriptableContext<"line">, startColor: string, endColor: string) => {
  const ctx = context.chart.ctx
  const gradient = ctx.createLinearGradient(0, 0, 0, 200)

  gradient.addColorStop(0, startColor)
  gradient.addColorStop(1, endColor)
  return gradient
}

export const defaultChartTooltipOptions = {
  caretSize: 0,
  caretPadding: 6,
  xAlign: "center" as const,
  yAlign: "bottom" as const,
  displayColors: false,
}

export const borderDashWithSkipped = (ctx) => (ctx.p0.skip || ctx.p1.skip ? [4, 2] : [])

export const borderColorWithSkipped = (ctx) => (ctx.p0.skip || ctx.p1.skip ? "#cccccc" : undefined)

const createEmptyHistoricalData = (itemsCount: number) =>
  [...Array(itemsCount)].map(() => ({
    key_as_string: "",
    "avg#average_current_temperature_embedded": { value: null },
    "avg#average_current_humidity_embedded": { value: null },
    "avg#average_current_air_quality": { value: null },
    "avg#average_set_temperature": { value: null },
    "avg#average_set_humidity": { value: null },
    "filters#current_cooling_stage_count": { buckets: { count1: { doc_count: 0 }, count2: { doc_count: 0 } } },
    "filters#current_heating_stage_count": {
      buckets: { count1: { doc_count: 0 }, count2: { doc_count: 0 }, count3: { doc_count: 0 } },
    },
  }))

export const fillMissingHistoricalData = (
  allHistoricalData: ApiTypes.Res.MonitoringHistoricalDataBucket[],
  startDateTimestamp: Dayjs,
  intervalInMinutes: number,
  itemsCount: number,
  isCustom: boolean,
) => {
  const historicalData = allHistoricalData.slice(0, itemsCount)
  if (historicalData.length === 0) {
    return [] as ApiTypes.Res.MonitoringHistoricalDataBucket[]
  }

  const firstItemDateTime = dayjs(historicalData[0].key_as_string)
  const diff = firstItemDateTime.diff(startDateTimestamp, "minutes")
  const missingItemsFromStart = Math.max(Math.floor(diff / intervalInMinutes) - (isCustom ? 1 : 0), 0)
  const missingItemsFromEnd = Math.max(itemsCount - historicalData.length - missingItemsFromStart, 0)

  return [
    ...createEmptyHistoricalData(missingItemsFromStart),
    ...historicalData,
    ...createEmptyHistoricalData(missingItemsFromEnd),
  ] as ApiTypes.Res.MonitoringHistoricalDataBucket[]
}
