import styled from "styled-components"
import { ApiTypes } from "../../../types"
import { parentGroupOptions } from "../../../constants/SelectFieldOptions"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  span:nth-child(2) {
    font-size: 12px;
    color: #64748b;
  }
`

interface ContractorCompanyNameProps {
  contractor: ApiTypes.Model.Contractor
}
const ContractorCompanyName = ({ contractor }: ContractorCompanyNameProps) => {
  const parentGroup = contractor.parent_group || "none"

  return (
    <Container>
      <span>{contractor.name}</span>
      {parentGroup !== "none" && (
        <span>{parentGroupOptions.find((option) => option.value === parentGroup)?.label || ""}</span>
      )}
    </Container>
  )
}

export default ContractorCompanyName
