import { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { Dayjs } from "dayjs"
import { SelectStatic } from "../../components/form/SelectStatic"
import { ApiTypes, UITypes } from "../../types"
import { dropDownOptions, getMonitoringDateRangeParams } from "./getMonitoringDateRangeParams"
import TemperatureLineChart from "./TemperatureLineChart"
import TemperatureBarChart from "./TemperatureBarChart"
import AirQualityChart from "./AirQualityChart"
import HumidityChart from "./HumidityChart"
import { useLazyGetMonitoringHistoricalDataQuery } from "../../store/api/monitoringApi"
import { Spinner } from "../../components/common/Spinner"
import { fillMissingHistoricalData } from "../../utils/chartUtils"
import DateRangePicker from "./DateRangePicker"
import styles from "./index.module.scss"

interface Props {
  device: ApiTypes.Model.Device
  isCelsius: boolean
}

const TabSettings = [
  { name: "Temperature", value: "temperature" },
  { name: "Air Quality", value: "airQuality" },
  { name: "Humidity", value: "humidity" },
]

export const ChartSettings = {
  lastHour: { ticksCount: 15, dataCountsDivider: 1 },
  last6Hours: { ticksCount: 12, dataCountsDivider: 20 },
  last24Hours: { ticksCount: 24, dataCountsDivider: 6 },
  yesterday: { ticksCount: 24, dataCountsDivider: 6 },
  last3Days: { ticksCount: 3, dataCountsDivider: 48 },
  last7Days: { ticksCount: 7, dataCountsDivider: 24 },
  last30Days: { ticksCount: 30, dataCountsDivider: 6 },
  custom: { ticksCount: 0, dataCountsDivider: 6 },
}

export const StatsReportChartsWrapper = ({ device, isCelsius }: Props) => {
  const [activeTab, setActiveTab] = useState(TabSettings[0].value)
  const [selectedTime, setSelectedTime] = useState<UITypes.Option[]>([dropDownOptions[0]])
  const [selectedDateRange, setSelectedDateRange] = useState<[Dayjs, Dayjs] | null>(null)
  const [isSingleDataPoint, setIsSingleDataPoint] = useState(false)
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false)
  const settings = ChartSettings[selectedTime[0].value as keyof typeof ChartSettings]
  const selectKey = useRef(1)
  const {
    startDateString,
    endDateString,
    startDateTime,
    intervalInMinutes,
    itemsCount,
    daysCount = 0,
  } = getMonitoringDateRangeParams(selectedTime[0].value, selectedDateRange)
  const [triggerGetMonitoringHistoricalData, { isFetching }] = useLazyGetMonitoringHistoricalDataQuery()
  const [data, setData] = useState<ApiTypes.Res.MonitoringHistoricalDataBucket[] | null>(null)
  const isCustom = selectedTime[0].value === "custom"

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await triggerGetMonitoringHistoricalData(
          {
            sn: device.sn,
            interval_in_minutes: intervalInMinutes,
            start_date_time: startDateString,
            end_date_time: endDateString,
          },
          true,
        )
        if (res) {
          const data = fillMissingHistoricalData(
            res?.data?.aggregations?.["date_histogram#aggregation_over_time"]?.buckets || [],
            startDateTime,
            intervalInMinutes,
            itemsCount,
            isCustom,
          )
          setData(data)
          setIsSingleDataPoint(res?.data?.aggregations?.["date_histogram#aggregation_over_time"]?.buckets?.length === 1)
        }
      } catch (error) {
        console.error(error)
        setData([])
        setIsSingleDataPoint(false)
      }
    }

    fetchData()
  }, [device.sn, selectedTime[0].value, selectedDateRange])

  const handleSetSelectedTime = (option: UITypes.Option[]) => {
    if (option[0].value === "custom") {
      setShowCustomDatePicker(true)
      return
    }

    setShowCustomDatePicker(false)
    setSelectedTime(option)
  }

  const renderCharts = () => {
    const parsedSettings = isCustom ? { ...settings, ticksCount: daysCount } : settings
    if (isFetching || data == null) return <Spinner />

    if (activeTab === "temperature") {
      return (
        <div>
          <TemperatureLineChart
            isCelsius={isCelsius}
            settings={parsedSettings}
            historicalData={data}
            isSingleDataPoint={isSingleDataPoint}
          />
          {data.length > 0 && (
            <TemperatureBarChart selectedTime={selectedTime[0].value} historicalData={data} ticksCount={daysCount} />
          )}
        </div>
      )
    }

    if (activeTab === "airQuality") {
      return <AirQualityChart settings={parsedSettings} historicalData={data} isSingleDataPoint={isSingleDataPoint} />
    }
    isSingleDataPoint

    return (
      <HumidityChart
        settings={parsedSettings}
        historicalData={data}
        isSingleDataPoint={isSingleDataPoint}
        showSetHumidity={device.accessories !== "none"}
      />
    )
  }

  const handleDateCancel = () => {
    selectKey.current += 1
    setShowCustomDatePicker(false)
  }

  const handleDateRangeSubmit = (dateRange: [Dayjs, Dayjs]) => {
    setSelectedDateRange(dateRange)
    setShowCustomDatePicker(false)
    setSelectedTime([dropDownOptions[dropDownOptions.length - 1]])
  }

  const contentRenderer = () => {
    if (isCustom && selectedDateRange) {
      return () => (
        <>
          {selectedDateRange[0].format("MMM D, YYYY")}-{selectedDateRange[1].format("MMM D, YYYY")}
        </>
      )
    }

    return undefined
  }

  return (
    <div className={classNames(styles.chartsWrapper)}>
      <div className={styles.chartOptionsContainer}>
        <div className={styles.tabButtonsWrapper}>
          {TabSettings.map((tab) => (
            <span
              key={tab.value}
              className={classNames(styles.tabButton, { [styles.active]: activeTab === tab.value })}
              onClick={() => setActiveTab(tab.value)}
            >
              {tab.name}
            </span>
          ))}
        </div>
      </div>
      <div className={styles.dropdownWrapper}>
        <SelectStatic
          key={selectKey.current}
          values={selectedTime}
          onChange={handleSetSelectedTime}
          options={dropDownOptions}
          contentRenderer={contentRenderer()}
        />
        {showCustomDatePicker && (
          <DateRangePicker
            maxRange={30}
            onCancel={handleDateCancel}
            onSubmit={handleDateRangeSubmit}
            selectedRange={selectedDateRange || undefined}
          />
        )}
      </div>
      {renderCharts()}
    </div>
  )
}
