import styles from "../../assets/scss/index.module.scss"
import { createPortal } from "react-dom"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"

interface ConfirmationModalProps {
  title: string
  message: string
  isFetching?: boolean
  icon: React.ReactNode
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  onCancel: () => void
}

const ConfirmationModal = ({ title, message, isFetching, icon, onSubmit, onCancel }: ConfirmationModalProps) => (
  <>
    {createPortal(
      <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
        <div className={classNames(styles.modalContent, styles.center)}>
          {icon}
          <h3 className={styles.modalTitle}>{title}</h3>
          <div className={styles.modalMessage}>{message}</div>
        </div>
      </ModalLayout>,
      document.body,
    )}
  </>
)

export default ConfirmationModal
