import styles from "./index.module.scss"
import classNames from "classnames"
import { BarChart } from "../../common/BarChart"
import { Spinner } from "../../common/Spinner"
import { ApiTypes } from "../../../types"

interface AlertStatusesWidgetProps {
  widgetData: ApiTypes.Res.AlertStatusesWidget
  isFetching: boolean
}

export const WidgetAlertStatuses = ({ widgetData, isFetching }: AlertStatusesWidgetProps) => (
  <div className={classNames(styles.alertsWidgetWrapper)}>
    <header className={styles.widgetHeader}>
      <h3 className={styles.widgetTitle}>Alerts Statuses</h3>
    </header>
    <div className={styles.chartWrapper}>
      <BarChart
        items={[
          {
            color: "#22C55E",
            value: widgetData.active,
          },
          {
            color: "#475569",
            value: widgetData.frozen,
          },
          {
            color: "#2DB2C8",
            value: widgetData.acknowledged,
          },
        ]}
      />
      <div className={styles.indicatorsWrapper}>
        <span className={styles.indicator}>
          <i
            className={styles.colorCircle}
            style={{
              backgroundColor: "#22C55E",
            }}
          />
          Active
          <i>{widgetData.active}</i>
        </span>
        <span className={styles.indicator}>
          <i
            className={styles.colorCircle}
            style={{
              backgroundColor: "#475569",
            }}
          />
          Frozen
          <i>{widgetData.frozen}</i>
        </span>
        <span className={styles.indicator}>
          <i
            className={styles.colorCircle}
            style={{
              backgroundColor: "#2DB2C8",
            }}
          />
          Acknowledged
          <i>{widgetData.acknowledged}</i>
        </span>
      </div>
      {isFetching && (
        <div className={styles.widgetLoader}>
          <Spinner />
        </div>
      )}
    </div>
  </div>
)
