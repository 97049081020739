import { DeleteModal } from "../DeleteModal"
import { toast } from "react-toastify"
import { useAppSelector } from "../../store/hooks"
import { useLazyGetVendorOrdersQuery } from "../../store/api/vendorApi"
import { useLazyDeleteVendorOrderQuery } from "../../store/api/adminApi"
import useUrlQueryParams from "../../hooks/useUrlQueryParams"

interface Props {
  onCancel: () => void
}

export const DeleteOrderModalForVendor = ({ onCancel }: Props) => {
  const [triggerDeleteVendorOrder, { isFetching }] = useLazyDeleteVendorOrderQuery()
  const [triggerGetVendorOrders] = useLazyGetVendorOrdersQuery()
  const currentOrder = useAppSelector((state) => state.tempData.currentOrder)
  const { urlQueryParams } = useUrlQueryParams()

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      const res: any = await triggerDeleteVendorOrder(currentOrder?.id!)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Order has been successfully deleted!")
        triggerGetVendorOrders(urlQueryParams)
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DeleteModal
      title="Delete order"
      message="Are you sure you want to delete the selected order(s)?"
      isFetching={isFetching}
      onCancel={onCancel}
      onConfirm={onSubmit}
    />
  )
}
