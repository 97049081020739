import styles from "../../../assets/scss/index.module.scss"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Pagination } from "../../../components/common/Pagination"
import { UITypes } from "../../../types"
import { useLazyGetMessageHistoryQuery } from "../../../store/api/contractorsApi"
import { useAppSelector, useAppDispatch } from "../../../store/hooks"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { convertMessagesHistoryToTableRows } from "../../../utils/convertData"
import { useNavigate } from "react-router-dom"
import TableWrapper from "../../../components/table/TableWrapper"
import useUrlQueryParams from "../../../hooks/useUrlQueryParams"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Title",
    type: "sort-search",
    name: "title",
  },
  {
    title: "Message",
    type: "sort-search",
    name: "body",
  },
  {
    title: "Date",
    type: "sort-date",
    name: "date",
  },
  {
    title: "Delivered",
    type: "sort",
    size: "small",
    name: "delivered",
  },
  {
    title: "Pending",
    type: "sort",
    size: "small",
    name: "pending",
  },
  {
    title: "Failed",
    type: "sort",
    size: "small",
    name: "failed",
  },
]

export const MessageHistory = () => {
  const [triggerGetMessageHistory, { isFetching, data }] = useLazyGetMessageHistoryQuery()
  const { urlQueryParams } = useUrlQueryParams()
  const navigate = useNavigate()
  const [isFirstRender, setFirstRender] = useState(true)

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = await triggerGetMessageHistory(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(urlQueryParams)
  }, [urlQueryParams, isFirstRender])

  const goToMessageIDDetails = (id) => {
    navigate(`/messages/report?id=${id}`)
  }

  return (
    <>
      <ContentWrapper
        title="Message history"
        breadcrumbs={[
          {
            label: "Message center",
            link: "/messages/center",
          },
          {
            label: "Message history",
            link: "/messages/history",
          },
        ]}
      >
        <nav className={styles.tableNavWrapper}>
          <div></div>
          <div className={styles.tableNavButtonsWrapper}>
            {/* <ButtonNew className={styles.tableNavItem} disabled>Export</ButtonNew> */}
          </div>
        </nav>
        <TableWrapper
          tableName="message-history"
          dynamicNavHandler1={goToMessageIDDetails}
          isLoading={isFetching}
          headerCells={tableHeaderCells}
          bodyRows={convertMessagesHistoryToTableRows(data?.data)}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
