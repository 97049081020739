import { useState } from "react"
import { useNavigate } from "react-router-dom"
import classNames from "classnames"
import { ModalLayout } from "../../layouts/ModalLayout"
import { ReactComponent as IconCheck } from "../../assets/svg/icon-check-subscription.svg"
import { ReactComponent as IconPhone } from "../../assets/svg/icon-phone.svg"
import { ReactComponent as IconEmail } from "../../assets/svg/icon-email.svg"
import { ReactComponent as IconUpgradePlan } from "../../assets/svg/icon-upgrade-plan.svg"
import SubscriptionPlan from "../../types/enums/SubscriptionPlan"
import { useAppSelector } from "../../store/hooks"
import SubscriptionPlanTitles from "../../constants/SubscriptionPlanTitles"
import SubscriptionPrivilege from "../../types/enums/SubscriptionPrivilege"

import styles from "./index.module.scss"

const upgradeMessages = {
  [SubscriptionPrivilege.PerformanceTest]: "Performance Test is unavailable",
  [SubscriptionPrivilege.Alerts]: "Alerts is unavailable",
  [SubscriptionPrivilege.Monitoring]: "Monitoring is unavailable",
}

const subscriptionPlanFeatures: {
  name: string
  [SubscriptionPlan.NETWORK]: string
  [SubscriptionPlan.PLUS]: string
  [SubscriptionPlan.BASIC]: string
}[] = [
  {
    name: "Logo",
    [SubscriptionPlan.NETWORK]: "check",
    [SubscriptionPlan.PLUS]: "check",
    [SubscriptionPlan.BASIC]: "check",
  },
  {
    name: "Messaging center",
    [SubscriptionPlan.NETWORK]: "check",
    [SubscriptionPlan.PLUS]: "check",
    [SubscriptionPlan.BASIC]: "check",
  },
  {
    name: "Dashboard center",
    [SubscriptionPlan.NETWORK]: "check",
    [SubscriptionPlan.PLUS]: "check",
    [SubscriptionPlan.BASIC]: "check",
  },
  {
    name: "24/7 Monitoring",
    [SubscriptionPlan.NETWORK]: "check",
    [SubscriptionPlan.PLUS]: "check",
    [SubscriptionPlan.BASIC]: "",
  },
  {
    name: "Remote Performance Test",
    [SubscriptionPlan.NETWORK]: "check",
    [SubscriptionPlan.PLUS]: "check",
    [SubscriptionPlan.BASIC]: "",
  },
  {
    name: "Alerts",
    [SubscriptionPlan.NETWORK]: "check",
    [SubscriptionPlan.PLUS]: "check",
    [SubscriptionPlan.BASIC]: "",
  },
  {
    name: "Marketing Center",
    [SubscriptionPlan.NETWORK]: "check",
    [SubscriptionPlan.PLUS]: "check",
    [SubscriptionPlan.BASIC]: "",
  },
  {
    name: "Monthly Monitoring Call",
    [SubscriptionPlan.NETWORK]: "check",
    [SubscriptionPlan.PLUS]: "",
    [SubscriptionPlan.BASIC]: "",
  },
  {
    name: "Custom App",
    [SubscriptionPlan.NETWORK]: "check",
    [SubscriptionPlan.PLUS]: "",
    [SubscriptionPlan.BASIC]: "",
  },
  {
    name: "Custom Icon",
    [SubscriptionPlan.NETWORK]: "check",
    [SubscriptionPlan.PLUS]: "",
    [SubscriptionPlan.BASIC]: "",
  },
  {
    name: "Monitoring fee",
    [SubscriptionPlan.NETWORK]: "price",
    [SubscriptionPlan.PLUS]: "price",
    [SubscriptionPlan.BASIC]: "price",
  },
]

const planPrices = {
  [SubscriptionPlan.NETWORK]: ["1,498"],
  [SubscriptionPlan.PLUS]: ["1,198"],
  [SubscriptionPlan.BASIC]: [698],
}

interface SubscriptionUpgradeModalProps {
  onCancel?: () => void
  page?: SubscriptionPrivilege
  hideUnavailableModal?: boolean
}

export const SubscriptionUpgradeModal = ({ onCancel, hideUnavailableModal, page }: SubscriptionUpgradeModalProps) => {
  const navigate = useNavigate()
  const [showUnavailableModal, setShowUnavailableModal] = useState(!hideUnavailableModal)
  const userProfile = useAppSelector((state) => state.user.userProfile)
  const current_subscription = userProfile?.contractor?.subscription_plan || SubscriptionPlan.BASIC

  const handleCancel = () => {
    if (onCancel) {
      return onCancel()
    }

    navigate("/dashboard")
  }

  const handleHideUnavailableModal = () => {
    setShowUnavailableModal(false)
  }

  if (showUnavailableModal && page) {
    return (
      <div className={styles.subscriptionSubModal}>
        <ModalLayout
          width="454px"
          buttonSize="fullWidth"
          confirmButtonText="Upgrade Plan"
          cancelButtonText="See all plans"
          footerClassName={styles.subscriptionSubModalFooter}
          onClose={handleCancel}
          onCancel={handleHideUnavailableModal}
          onConfirm={handleHideUnavailableModal}
        >
          <div className={styles.modalBody}>
            <div className={styles.iconWrapper}>
              <IconUpgradePlan />
            </div>
            <h3>{upgradeMessages[page]}</h3>
            <p>
              {upgradeMessages[page]} in <span>“Nuve {SubscriptionPlanTitles[current_subscription]}”</span> plan.
              Please, upgrade your plan to be able use this feature.
            </p>
          </div>
        </ModalLayout>
      </div>
    )
  }

  const renderPlanFeature = (plan: SubscriptionPlan, feature: { name: string; [key: string]: string }) => {
    if (feature[plan] === "check") {
      return <IconCheck />
    }

    if (feature[plan] === "price") {
      const [oldPrice] = planPrices[plan]
      return (
        <div className={styles.subscriptionModalPrice}>
          <div className={styles.subscriptionModalPriceOld}>
            <span>${oldPrice}</span>
          </div>
        </div>
      )
    }

    return ""
  }

  return (
    <div className={styles.subscriptionModal}>
      <ModalLayout width="1000px" onClose={handleCancel}>
        <div className={styles.subscriptionModalContainer}>
          <div className={styles.subscriptionModalWrapper}>
            {[SubscriptionPlan.NETWORK, SubscriptionPlan.PLUS, SubscriptionPlan.BASIC].map((plan) => (
              <div key={plan} className={styles.subscriptionModalSection}>
                <div className={classNames(styles.subscriptionModalContent, styles.subscriptionModalFeature)}>
                  <div className={styles.subscriptionModalRowTitle}>
                    <p className={styles.featuresTitle}>Pricing and Features</p>
                  </div>
                  {subscriptionPlanFeatures.map((feature) => (
                    <div className={styles.subscriptionModalRow} key={feature.name}>
                      <p>{feature.name}</p>
                    </div>
                  ))}
                </div>
                <div className={styles.subscriptionModalContent}>
                  {plan === SubscriptionPlan.NETWORK && (
                    <div className={styles.popularPlan}>
                      <div className={styles.mostPopularText}>
                        <p>most Popular</p>
                      </div>
                    </div>
                  )}
                  <div className={classNames(styles.subscriptionModalRowTitle, styles.popularPlanTitle)}>
                    <span>Nuve</span>
                    <p className={styles.boldTitle}>{SubscriptionPlanTitles[plan]}</p>
                    <div>{current_subscription === plan ? "Current Plan" : ""}</div>
                  </div>
                  {subscriptionPlanFeatures.map((feature) => (
                    <div className={styles.subscriptionModalRow} key={feature.name}>
                      {renderPlanFeature(plan, feature)}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.subscriptionModalFooter}>
            <div className={styles.subscriptionModalFooterText}>
              <span>Contact Sales to upgrade your Plan</span>
            </div>
            <div className={styles.subscriptionModalFooterContacts}>
              <span>
                <IconPhone /> 1-657-626-1592
              </span>
              <span>
                <IconEmail /> support@nuvehome.com
              </span>
            </div>
          </div>
        </div>
      </ModalLayout>
    </div>
  )
}
