import { createPortal } from "react-dom"
import classNames from "classnames"
import styled from "styled-components/macro"
import styles from "../../assets/scss/index.module.scss"
import { ModalLayout } from "../../layouts/ModalLayout"
import { ApiTypes } from "../../types"
import { ReactComponent as IconPower } from "../../assets/svg/icon-power.svg"
import { useRunDeviceCommandMutation } from "../../store/api/performanceTestApi"

const PowerIconContainer = styled.div`
  display: flex;
  justify-content: center;

  div {
    width: 44px;
    height: 44px;
    padding-top: 12px;
    border-radius: 10px;
    background-color: #f1f5f9;
  }
`
interface Props {
  device: ApiTypes.Model.Device
  onClose: () => void
}

const DeviceRebootModal = ({ onClose, device }: Props) => {
  const [triggerRunReboot] = useRunDeviceCommandMutation()

  const handleRebootClick = async (event) => {
    event && event.preventDefault()
    await triggerRunReboot({ sn: device.sn || "", command: "reboot" })
    onClose()
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="490px"
          cancelButtonText="Cancel"
          confirmButtonText="Reboot"
          onConfirm={handleRebootClick}
          onCancel={onClose}
        >
          <div className={classNames(styles.modalContent, styles.center)}>
            <PowerIconContainer>
              <div>
                <IconPower />
              </div>
            </PowerIconContainer>
            <h3 className={styles.modalTitle}>Reboot the device</h3>
            <div className={styles.modalMessage}>Are you sure you want to reboot the device?</div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}

export default DeviceRebootModal
