import styles from "../../../../assets/scss/index.module.scss"
import { useState, FormEvent } from "react"
import { Helmet } from "react-helmet"
import { Input } from "../../../../components/form/Input"
import { AuthForm } from "../../../../components/form/AuthForm"
import { Button } from "../../../../components/form/Button"
import { useNavigate } from "react-router-dom"
import { onFormValidate, validationInitialState, forgotPassword } from "../../../../utils/onValidate"
import { useLazyForgotPasswordQuery } from "../../../../store/api/authApi"
import { toast } from "react-toastify"
import { Spinner } from "../../../../components/common/Spinner"

const inputValuesInitialState = {
  email: "",
}

export const ForgotPassword = () => {
  const [triggerForgotPassword, { isFetching }] = useLazyForgotPasswordQuery()
  const [inputValues, setInputValues] = useState(inputValuesInitialState)

  const [error, setError] = useState(validationInitialState)

  const navigate = useNavigate()

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget

    setInputValues((prevState) => {
      return { ...prevState, [name]: value }
    })
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    if (onFormValidate(inputValues, forgotPassword, setError)) {
      try {
        const res: any = await triggerForgotPassword({
          email: inputValues.email,
        })

        if (res?.error) {
          if (res.error.originalStatus === 401) {
            toast.error("The email provided is invalid. Please check your email address and try again.")
          } else {
            toast.error(res?.error?.data?.message ? res?.error?.data?.message : "Server error, try again later.")
          }
        } else {
          setInputValues(inputValuesInitialState)
          toast.success("We have emailed your password reset link.")
          navigate("/")
        }
      } catch (error: any) {
        toast.error(error?.message)
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Nuve - Forgot Password</title>
      </Helmet>
      <div>
        <AuthForm title="Forgot Password?" subtitle="Enter your email to reset your password." onSubmit={onSubmit}>
          <Input
            label="Email"
            placeholder="Enter email"
            name="email"
            value={inputValues.email}
            onChange={onInputChange}
            errorMessage={error.field === "email" ? error.message : ""}
          />
          <div className={styles.authButtonsWrapper}>
            {/* <Button
              color="white"
              size="halfWidth"
              onClick={onBack}>
              Back
            </Button> */}
            <Button onClick={onSubmit} type="submit" disabled={isFetching} color="colored" size="fullWidth">
              {isFetching ? <Spinner width={38} height={38} /> : "Submit"}
            </Button>
          </div>
        </AuthForm>
      </div>
    </>
  )
}
