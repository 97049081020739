import styles from "./index.module.scss"
import { useState, useEffect } from "react"
import { InputNew } from "../../../components/form/InputNew"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { RadioButton } from "../../../components/form/RadioButton"
import { SelectDynamic } from "../../../components/form/SelectDynamic"
import { toast } from "react-toastify"
import {
  useLazyGetCitiesQuery,
  useLazyGetZipCodesQuery,
  useLazyGetStatesQuery,
  useLazyGetResidentTypesQuery,
  useLazyGetDeviceLocationsQuery,
} from "../../../store/api/dictionaryApi"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { UITypes } from "../../../types"
import { inputHandler } from "../../../utils/onInputChange"
import { useAppSelector, useAppDispatch } from "../../../store/hooks"
import { useLazyInstallDeviceQuery } from "../../../store/api/techniciansApi"
import { Spinner } from "../../../components/common/Spinner"
import { v4 as uuidv4 } from "uuid"
import { storageKeys } from "../../../utils/storageKeys"
import { validationInitialState, onFormValidate, addDeviceSchema } from "../../../utils/onValidate"
import { setHomeowner, setCurrentZip } from "../../../store/tempDataSlise"
import { convertDataToOptions } from "../../../utils/convertData"

const inputValuesInitialState: UITypes.Device = {
  _id: uuidv4(),
  serialNumber: "",
  address1: "",
  address2: " ",
  country: [],
  zip: [],
  deviceName: "",
  installationType: "",
}

export const AddDevice = () => {
  const [triggerInstallDevice, { isFetching }] = useLazyInstallDeviceQuery()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [devices, setDevices] = useState<UITypes.Device[]>([])
  const [inputValues, setInputValues] = useState<UITypes.Device>(inputValuesInitialState)
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [errorDeviceID, setErrorDeviceID] = useState("")
  const [error, setError] = useState(validationInitialState)
  const currentZip = useAppSelector((state) => state.tempData?.currentZip)
  const homeowner = useAppSelector((state) => state.tempData?.homeowner)

  const addDeviceToTheList = (_id: string, serialNumber?: string) => {
    return {
      _id,
      serialNumber: serialNumber ? serialNumber : "",
      address1: "",
      address2: "",
      deviceName: "",
      country: [],
      zip: [],
      state: [],
      city: [],
      installationType: "",
      residentialType: [],
      deviceLocation: [],
    }
  }

  // UPDATE DEVICES STATE
  const saveChanges = () => {
    const _devices = devices.map((obj) => {
      if (obj?._id === inputValues?._id) {
        return inputValues
      }
      return obj
    })

    setDevices(_devices)
    return _devices
  }

  const prepareData = (devicesData) => {
    const data = {
      client: {
        full_name: homeowner?.fullName,
        phone: homeowner?.phone,
        email: homeowner?.email,
      },
      devices: devicesData?.map((item) => ({
        sn: item?.serialNumber,
        address1: item?.address1,
        address2: item?.address2,
        name: item?.deviceName,
        installation_type: item?.installationType,
        state: item?.state?.[0] && Number(item?.state?.[0]?.value),
        city: item?.city?.[0] && Number(item?.city?.[0]?.value),
        zip_code: item?.zip?.[0] && Number(item?.zip?.[0]?.label),
        where_installed_id: item?.deviceLocation?.[0] && Number(item?.deviceLocation?.[0]?.value),
        resident_type_id: item?.residentialType?.[0] && Number(item?.residentialType?.[0]?.value),
      })),
    }

    return data as any
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    const _devices = saveChanges()

    // VALIDATION
    const isAllow = _devices.every((item) => {
      const result = onFormValidate(item, addDeviceSchema, setError)

      if (!result) {
        setErrorDeviceID(item?._id!)
      } else {
        setErrorDeviceID("")
      }

      return result
    })

    if (!isAllow) return

    try {
      const res: any = await triggerInstallDevice(prepareData(_devices))
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Device has been successfully installed!")
        sessionStorage.removeItem(storageKeys.technicianHomeowner)
        sessionStorage.removeItem(storageKeys.technicianSN)
        dispatch(setHomeowner(null))
        dispatch(setCurrentZip(undefined))
        onBackClick()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onBackClick = () => {
    navigate("/add-device/customer")
  }

  const onTabChange = (tab: number) => {
    if (tab === currentTab) return
    saveChanges()
    setCurrentTab(tab)
  }

  // SET DEVICES BASED ON SERIAL NUMBERS
  useEffect(() => {
    const deviceSN = sessionStorage.getItem(storageKeys.technicianSN)

    if (!deviceSN) return

    if (typeof JSON.parse(deviceSN) === "string") {
      const deviceItem = JSON.parse(deviceSN)
      const id = uuidv4()
      setDevices([addDeviceToTheList(id, deviceItem)])
      setInputValues({
        _id: id,
        serialNumber: deviceItem ? deviceItem : "",
        address1: homeowner?.address1!,
        address2: homeowner?.address2,
        deviceName: "",
        country: (homeowner?.country && convertDataToOptions({ data: [homeowner?.country] }, "id", "name")) || [],
        zip: homeowner?.zip && convertDataToOptions({ data: [homeowner?.zip] }, "id", "code"),
        state: homeowner?.state && convertDataToOptions({ data: [homeowner?.state] }, "id", "name"),
        city: homeowner?.city && convertDataToOptions({ data: [homeowner?.city] }, "id", "name"),
        installationType: "",
        residentialType: [],
        deviceLocation: [],
      })
    }

    if (typeof JSON.parse(deviceSN) === "object") {
      const devicesList = JSON.parse(deviceSN)?.map((item) => addDeviceToTheList(uuidv4(), item))

      setDevices(devicesList)
      setInputValues(devicesList[currentTab])
    }
  }, [])

  // SET CURRENT TAB
  useEffect(() => {
    if (!devices?.length) return
    setInputValues(devices[currentTab])
  }, [currentTab, devices])

  // SWITCH TAB WHEN VALIDATION ERROR IS HAPPEN
  useEffect(() => {
    if (errorDeviceID !== inputValues?._id && error?.field) {
      const index = devices.findIndex((item) => item?._id === errorDeviceID)
      setCurrentTab(index)
    }
  }, [error, errorDeviceID])

  // FILL IN CITY AND STATE SELECT BASED ON ZIP
  useEffect(() => {
    if (currentZip?.city) {
      const city = convertDataToOptions({ data: [currentZip.city] }, "id", "name")
      const state = convertDataToOptions({ data: [currentZip.state] }, "id", "name")

      if (city && state) {
        setInputValues({
          ...inputValues,
          ...{
            city,
            state,
          },
        })
      }
    }
  }, [currentZip])

  return (
    <div className={styles.screenWrapper}>
      <form className={styles.formWrapper} onSubmit={onSubmit}>
        <div className={styles.tabsWrapper}>
          {devices?.map((item, index) => (
            <span
              key={uuidv4()}
              onClick={() => onTabChange(index)}
              className={classNames(styles.tab, { [styles.active]: devices?.length > 1 && currentTab === index })}
            >
              Device {index + 1}
            </span>
          ))}
          {/* <span className={styles.addTab} onClick={onAddNew}></span> */}
        </div>
        <div className={styles.formColumnsWrapper}>
          <div className={styles.formColumn}>
            <InputNew
              // disabled={deviceSNList?.some(item => data?.serialNumber === item)}
              disabled
              name="serialNumber"
              label="S/N"
              value={inputValues?.serialNumber}
              // onChange={(event) => inputHandler(event, setInputValues)}
              errorMessage={error.field === "serialNumber" && errorDeviceID === inputValues._id ? error.message : ""}
            />
            <InputNew
              label="Device Name"
              placeholder="Enter Device Name"
              name="deviceName"
              value={inputValues?.deviceName}
              onChange={(event) => inputHandler(event, setInputValues)}
              errorMessage={error.field === "deviceName" && errorDeviceID === inputValues._id ? error.message : ""}
            />
            <InputNew
              label="Address 1"
              placeholder="Enter address"
              name="address1"
              value={inputValues?.address1}
              onChange={(event) => inputHandler(event, setInputValues)}
              errorMessage={error.field === "address1" && errorDeviceID === inputValues._id ? error.message : ""}
            />
            <InputNew
              label="Address 2 (Optional)"
              placeholder="Enter address"
              name="address2"
              value={inputValues?.address2}
              onChange={(event) => inputHandler(event, setInputValues)}
              errorMessage={error.field === "address2" && errorDeviceID === inputValues._id ? error.message : ""}
            />
            <div className={styles.formCellsWrapper}>
              <div className={styles.formCell}>
                <SelectDynamic
                  label="Zip Code"
                  valueName="id"
                  labelName="code"
                  searchFieldName="code"
                  name="zip"
                  values={inputValues?.zip}
                  useLazyQuery={useLazyGetZipCodesQuery}
                  placeholder="Enter Zip Code"
                  onChange={(value) => setInputValues({ ...inputValues, ...{ zip: value } })}
                  errorMessage={error.field === "zip" && errorDeviceID === inputValues._id ? error.message : ""}
                />
              </div>
              <div className={styles.formCell}>
                <SelectDynamic
                  label="State"
                  placeholder="Enter state"
                  name="state"
                  valueName="id"
                  labelName="name"
                  values={inputValues?.state}
                  useLazyQuery={useLazyGetStatesQuery}
                  onChange={(value) => setInputValues({ ...inputValues, ...{ state: value } })}
                  errorMessage={error.field === "state" && errorDeviceID === inputValues._id ? error.message : ""}
                />
              </div>
            </div>
            <SelectDynamic
              label="City"
              valueName="id"
              labelName="name"
              name="city"
              useLazyQuery={useLazyGetCitiesQuery}
              placeholder="Enter city"
              values={inputValues?.city}
              onChange={(value) => setInputValues({ ...inputValues, ...{ city: value } })}
              errorMessage={error.field === "city" && errorDeviceID === inputValues._id ? error.message : ""}
            />
          </div>
          <div className={styles.formColumn}>
            <SelectDynamic
              label="Residence type"
              placeholder="Select residence type"
              useLazyQuery={useLazyGetResidentTypesQuery}
              valueName="id"
              labelName="name"
              values={inputValues?.residentialType}
              onChange={(value) => setInputValues({ ...inputValues, ...{ residentialType: value } })}
              errorMessage={error.field === "residentialType" && errorDeviceID === inputValues._id ? error.message : ""}
            />
            <SelectDynamic
              label="Device location"
              placeholder="Select device location"
              useLazyQuery={useLazyGetDeviceLocationsQuery}
              valueName="id"
              labelName="name"
              values={inputValues?.deviceLocation}
              onChange={(value) => setInputValues({ ...inputValues, ...{ deviceLocation: value } })}
              errorMessage={error.field === "deviceLocation" && errorDeviceID === inputValues._id ? error.message : ""}
            />
            <RadioButton
              id="new"
              isChecked={inputValues.installationType === "new"}
              name="installationType"
              label="Installation type"
              onChange={() => setInputValues({ ...inputValues, ...{ installationType: "new" } })}
              errorMessage={
                error.field === "installationType" && errorDeviceID === inputValues._id ? error.message : ""
              }
            >
              New/Replacement HVAC System
            </RadioButton>
            <RadioButton
              id="existing"
              isChecked={inputValues.installationType === "existing"}
              name="installationType"
              onChange={() => setInputValues({ ...inputValues, ...{ installationType: "existing" } })}
              errorMessage={
                error.field === "installationType" && errorDeviceID === inputValues._id ? error.message : ""
              }
            >
              Existing HVAC system
            </RadioButton>
            <div className={styles.buttonsWrapper}>
              <ButtonNew onClick={onBackClick} className={styles.mobileButton} color="greyBordered">
                Back
              </ButtonNew>
              <ButtonNew
                onClick={onSubmit}
                color="blue"
                disabled={isFetching}
                className={classNames(styles.buttonRight, styles.mobileButton)}
              >
                {isFetching ? <Spinner /> : "Save"}
              </ButtonNew>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
