import { FormEvent, SetStateAction } from "react"
import ModalSectionTitle from "../../../components/common/ModalSectionTitle/ModalSectionTitle"
import { InputNew } from "../../../components/form/InputNew"
import { UITypes } from "../../../types"
import { SelectDynamic } from "../../../components/form/SelectDynamic"
import {
  useLazyGetCountriesQuery,
  useLazyGetCitiesQuery,
  useLazyGetStatesQuery,
  useLazyGetZipCodesQuery,
} from "../../../store/api/dictionaryApi"
import { SelectStatic } from "../../../components/form/SelectStatic"
import { parentGroupOptions } from "../../../constants/SelectFieldOptions"
import styles from "./index.module.scss"

interface Props {
  inputValues: UITypes.Contractor
  error: {
    field: string
    message: string
  }
  onInputChange: (event: FormEvent<HTMLInputElement>) => void
  setInputValues: (value: SetStateAction<UITypes.Contractor>) => void
}

const DetailsSection = ({ inputValues, error, onInputChange, setInputValues }: Props) => {
  return (
    <div>
      <ModalSectionTitle title="Details" />
      <div className={styles.row}>
        <InputNew
          label="Company name"
          placeholder="Enter company name"
          name="name"
          value={inputValues.name}
          className={styles.inputText}
          onChange={onInputChange}
          errorMessage={error.field === "name" ? error.message : ""}
        />
      </div>
      <div className={styles.row}>
        <InputNew
          label="Brand name"
          placeholder="Enter brand name"
          name="brand"
          value={inputValues.brand}
          className={styles.inputText}
          onChange={onInputChange}
          errorMessage={error.field === "brand" ? error.message : ""}
        />
        <SelectStatic
          label="Parent company"
          placeholder="Select the parent company"
          values={inputValues.parentGroup}
          className={styles.inputText}
          options={parentGroupOptions}
          onChange={(data) => setInputValues({ ...inputValues, parentGroup: data })}
          errorMessage={error.field === "parentGroup" ? error.message : ""}
        />
      </div>
      <div className={styles.row}>
        <InputNew
          label="Address 1"
          placeholder="Enter address "
          name="address1"
          value={inputValues.address1}
          className={styles.inputText}
          onChange={onInputChange}
          errorMessage={error.field === "address1" ? error.message : ""}
        />
        <InputNew
          label="Address 2"
          placeholder="Enter address "
          name="address2"
          value={inputValues.address2}
          className={styles.inputText}
          onChange={onInputChange}
          errorMessage={error.field === "address2" ? error.message : ""}
        />
      </div>
      <div className={styles.row}>
        <SelectDynamic
          label="Country"
          searchFieldName="country"
          valueName="id"
          labelName="name"
          name="country"
          values={inputValues.country}
          className={styles.inputText}
          useLazyQuery={useLazyGetCountriesQuery}
          placeholder="Enter Country"
          onChange={(value) => setInputValues({ ...inputValues, ...{ country: value } })}
          errorMessage={error.field === "country" ? error.message : ""}
        />
        <SelectDynamic
          label="Zip Code"
          searchFieldName="code"
          valueName="id"
          labelName="code"
          name="zip"
          values={inputValues.zip}
          className={styles.inputText}
          useLazyQuery={useLazyGetZipCodesQuery}
          placeholder="Enter Zip Code"
          onChange={(value) => setInputValues({ ...inputValues, ...{ zip: value } })}
          errorMessage={error.field === "zip" ? error.message : ""}
          queryParams={{ country_id: (inputValues.country?.[0]?.value || 1) as unknown as number }}
        />
      </div>
      <div className={styles.row}>
        <SelectDynamic
          label="State"
          placeholder="Enter state"
          name="state"
          valueName="id"
          labelName="name"
          values={inputValues.state}
          className={styles.inputText}
          useLazyQuery={useLazyGetStatesQuery}
          onChange={(value) => setInputValues({ ...inputValues, ...{ state: value } })}
          errorMessage={error.field === "state" ? error.message : ""}
        />
        <SelectDynamic
          label="City"
          valueName="id"
          labelName="name"
          name="city"
          useLazyQuery={useLazyGetCitiesQuery}
          placeholder="Enter city"
          values={inputValues.city}
          className={styles.inputText}
          onChange={(value) => setInputValues({ ...inputValues, ...{ city: value } })}
          errorMessage={error.field === "city" ? error.message : ""}
        />
      </div>
    </div>
  )
}

export default DetailsSection
