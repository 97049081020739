import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ApiTypes, UITypes } from "../types"
import { storageKeys } from "../utils/storageKeys"

const technicianHomeowner = sessionStorage.getItem(storageKeys.technicianHomeowner)

export interface TempDataState {
  editableTechnician: ApiTypes.Model.Technician | undefined
  currentOrder: ApiTypes.Model.Order | undefined
  currentAlert: ApiTypes.Model.Alert | undefined
  contractor: UITypes.Contractor | null
  editableWarehouse: ApiTypes.Model.Warehouse | undefined
  editableClient: ApiTypes.Model.ClientDevices | undefined
  editableUser: ApiTypes.Model.User | undefined
  additionalRequestParams: UITypes.RequestParams | null
  homeowner: UITypes.Homeowner | null
  currentTemplateID: string | undefined
  currentZip: ApiTypes.Model.Zip | undefined
  selectedDeviceIDs: number[]
  selectedDevicesForPerformanceTest: {
    isImmediate?: boolean
    devices: ApiTypes.Model.PerformanceTestDevice[]
  }
  selectedDeviceForMonitoringReport: ApiTypes.Model.Device | undefined
  performanceTestReport: UITypes.PerformanceTestReport | undefined
  selectedDevices: ApiTypes.Model.VendorDevice[]
  selectedWHOrder: ApiTypes.Model.WHManagerOrder | undefined
  selectedVSMOrder: ApiTypes.Model.VSManagerOrder | undefined
  deviceHistoryLogID: number | undefined
  warrantySNs: UITypes.WarrantySNs | undefined
  modalContent: UITypes.ModalContent | undefined
  currentTableColumns:
    | {
        table: string
        columns: UITypes.TableHeaderCell[]
      }
    | undefined
}

const initialState: TempDataState = {
  editableTechnician: undefined,
  currentOrder: undefined,
  currentAlert: undefined,
  contractor: null,
  editableWarehouse: undefined,
  editableClient: undefined,
  editableUser: undefined,
  additionalRequestParams: null,
  homeowner: technicianHomeowner ? JSON.parse(technicianHomeowner) : null,
  currentTemplateID: undefined,
  currentZip: undefined,
  selectedDeviceIDs: [],
  selectedDevicesForPerformanceTest: {
    isImmediate: false,
    devices: [],
  },
  selectedDeviceForMonitoringReport: undefined,
  performanceTestReport: undefined,
  selectedDevices: [],
  selectedWHOrder: undefined,
  selectedVSMOrder: undefined,
  deviceHistoryLogID: undefined,
  warrantySNs: undefined,
  modalContent: undefined,
  currentTableColumns: undefined,
}

export const tempDataSlise = createSlice({
  name: "tempData",
  initialState,
  reducers: {
    setEditableTechnician: (state, action: PayloadAction<ApiTypes.Model.Technician | undefined>) => {
      state.editableTechnician = action.payload
    },
    setCurrentOrder: (state, action: PayloadAction<ApiTypes.Model.Order | undefined>) => {
      state.currentOrder = action.payload
    },
    setCurrentAlert: (state, action: PayloadAction<ApiTypes.Model.Alert | undefined>) => {
      state.currentAlert = action.payload
    },
    setContractor: (state, action: PayloadAction<UITypes.Contractor | null>) => {
      state.contractor = action.payload
    },
    setEditableWarehouse: (state, action: PayloadAction<ApiTypes.Model.Warehouse | undefined>) => {
      state.editableWarehouse = action.payload
    },
    setEditableClient: (state, action: PayloadAction<ApiTypes.Model.ClientDevices | undefined>) => {
      state.editableClient = action.payload
    },
    setEditableUser: (state, action: PayloadAction<ApiTypes.Model.User | undefined>) => {
      state.editableUser = action.payload
    },
    setAdditionalRequestParams: (state, action: PayloadAction<UITypes.RequestParams | null>) => {
      if (action.payload === null) {
        state.additionalRequestParams = {}
      } else {
        state.additionalRequestParams = { ...state.additionalRequestParams, ...action.payload }
      }
    },
    setHomeowner: (state, action: PayloadAction<UITypes.Homeowner | null>) => {
      state.homeowner = action.payload
    },
    setCurrentTemplateID: (state, action: PayloadAction<string | undefined>) => {
      state.currentTemplateID = action.payload
    },
    setCurrentZip: (state, action: PayloadAction<ApiTypes.Model.Zip | undefined>) => {
      state.currentZip = action.payload
    },
    setSelectedDeviceIDs: (state, action: PayloadAction<number[]>) => {
      state.selectedDeviceIDs = action.payload
    },
    setSelectedDevicesForPerformanceTest: (
      state,
      action: PayloadAction<{ isImmediate?: boolean; devices: ApiTypes.Model.PerformanceTestDevice[] }>,
    ) => {
      state.selectedDevicesForPerformanceTest = action.payload
    },
    setSelectedDeviceForMonitoringReport: (state, action: PayloadAction<ApiTypes.Model.Device | undefined>) => {
      state.selectedDeviceForMonitoringReport = action.payload
    },
    setPerformanceTestReport: (state, action: PayloadAction<UITypes.PerformanceTestReport | undefined>) => {
      state.performanceTestReport = action.payload
    },
    setSelectedDevices: (state, action: PayloadAction<ApiTypes.Model.VendorDevice[]>) => {
      state.selectedDevices = action.payload
    },
    setSelectedWHOrder: (state, action: PayloadAction<ApiTypes.Model.WHManagerOrder | undefined>) => {
      state.selectedWHOrder = action.payload
    },
    setSelectedVSMOrder: (state, action: PayloadAction<ApiTypes.Model.VSManagerOrder | undefined>) => {
      state.selectedVSMOrder = action.payload
    },
    setDeviceHistoryLogID: (state, action: PayloadAction<number | undefined>) => {
      state.deviceHistoryLogID = action.payload
    },
    setWarrantySNs: (state, action: PayloadAction<UITypes.WarrantySNs | undefined>) => {
      state.warrantySNs = action.payload
    },
    setModalContent: (state, action: PayloadAction<UITypes.ModalContent | undefined>) => {
      state.modalContent = { title: action.payload?.title || "", content: action.payload?.content || "" }
    },
    setCurrentTableColumns: (
      state,
      action: PayloadAction<
        | {
            table: string
            columns: UITypes.TableHeaderCell[]
          }
        | undefined
      >,
    ) => {
      state.currentTableColumns = action.payload
    },
  },
})

export const {
  setEditableTechnician,
  setCurrentOrder,
  setContractor,
  setAdditionalRequestParams,
  setCurrentAlert,
  setHomeowner,
  setCurrentTemplateID,
  setCurrentZip,
  setEditableWarehouse,
  setEditableUser,
  setSelectedDeviceIDs,
  setSelectedDevicesForPerformanceTest,
  setSelectedDeviceForMonitoringReport,
  setPerformanceTestReport,
  setSelectedDevices,
  setSelectedWHOrder,
  setSelectedVSMOrder,
  setDeviceHistoryLogID,
  setWarrantySNs,
  setEditableClient,
  setModalContent,
  setCurrentTableColumns,
} = tempDataSlise.actions

export default tempDataSlise.reducer
