import {
  contractorPerformanceTestStatusOptions,
  deviceAccessoriesOptions,
  devicesAlertsOptions,
  devicesMembershipOptions,
  deviceSystemTypeOptions,
  deviceWorkStatusOptions,
  hvacOptions,
} from "../../../constants/SelectFieldOptions"
import { UITypes } from "../../../types"

const performanceTestTableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "",
  },
  {
    title: "Email",
    type: "sort-search",
    name: "email",
    size: "middle",
  },
  {
    title: "Status",
    type: "select",
    name: "perftest_status",
    size: "small",
    options: contractorPerformanceTestStatusOptions,
  },
  {
    title: "Work status",
    type: "select",
    name: "work_status",
    size: "small",
    options: deviceWorkStatusOptions,
  },
  {
    title: "Label",
    type: "sort-search",
    name: "perftest_label",
    size: "small",
  },
  {
    title: "Country",
    type: "sort-search",
    name: "country",
    size: "small",
  },
  {
    title: "City",
    type: "sort-search",
    name: "city",
    size: "small",
  },
  {
    title: "ZIP code",
    type: "sort-search",
    name: "zip_code",
    size: "small",
  },
  {
    title: "State",
    type: "sort-search",
    name: "state",
    size: "small",
  },
  {
    title: "Customer",
    type: "sort-search",
    name: "homeowner_name",
    size: "middle",
  },
  {
    title: "Device S/N",
    type: "sort-search",
    name: "sn",
    size: "small",
  },
  {
    title: "Address",
    type: "sort-search",
    name: "address",
  },
  {
    title: "HVAC",
    type: "select",
    size: "small",
    name: "installation_type",
    options: hvacOptions,
  },
  {
    title: "Alerts",
    type: "select",
    size: "small",
    name: "alerts",
    options: devicesAlertsOptions,
  },
  {
    title: "Membership",
    type: "select",
    name: "membership",
    options: devicesMembershipOptions,
    size: "small",
  },
  {
    title: "System type",
    type: "select",
    size: "small",
    name: "system_type",
    options: deviceSystemTypeOptions,
  },
  {
    title: "Accessories",
    type: "select",
    size: "small",
    name: "accessories",
    options: deviceAccessoriesOptions,
  },
]

export default performanceTestTableHeaderCells
