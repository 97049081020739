import styles from "./index.module.scss"
import { useState, useEffect } from "react"
import { ReactComponent as IconTableSearch } from "../../../assets/svg/icon-table-search.svg"
import { ReactComponent as IconTableCross } from "../../../assets/svg/icon-table-cross.svg"
import debounce from "lodash.debounce"
import useUrlQueryParams from "../../../hooks/useUrlQueryParams"

interface Props {
  name?: string
  label?: string
}

export const TableSearch = ({ name, label }: Props) => {
  const [isActive, setActive] = useState(false)
  const [value, setValue] = useState("")
  const { urlQueryParams, setUrlQueryParams } = useUrlQueryParams()

  const onClear = () => {
    setValue("")
    name && setUrlQueryParams({ [name]: null })
    setActive(false)
  }

  const onInputChange = (value) => {
    name &&
      setUrlQueryParams({
        [name]: value,
      })
  }

  useEffect(() => {
    if (name && urlQueryParams[name] && !value) {
      setValue(urlQueryParams[name])
      setActive(true)
    } else if (urlQueryParams && name && Object.hasOwn(urlQueryParams, name) && urlQueryParams[name] === null) {
      setValue("")
      setActive(false)
    }
  }, [urlQueryParams])

  const setDebaunceSearchValue = debounce(onInputChange, 500)

  return (
    <div className={styles.tableSearchWrapper} onClick={() => {}}>
      {isActive ? (
        <div className={styles.inputWrapper}>
          <input
            className={styles.inputSearch}
            placeholder="Search.."
            autoFocus
            defaultValue={value}
            onChange={(event) => setDebaunceSearchValue(event.currentTarget?.value)}
            name={name === "email" ? "emailField" : name}
          />
          <span className={styles.iconClear} onClick={onClear}>
            <IconTableCross />
          </span>
        </div>
      ) : (
        <span className={styles.iconSearch} onClick={() => setActive(true)}>
          <IconTableSearch />
        </span>
      )}
    </div>
  )
}
