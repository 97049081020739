import dayjs from "dayjs"
import { Line } from "react-chartjs-2"
import classNames from "classnames"
import { Chart, ScriptableContext, registerables } from "chart.js"
import {
  borderColorWithSkipped,
  borderDashWithSkipped,
  defaultChartTooltipOptions,
  getChartGradient,
} from "../../utils/chartUtils"
import { NoDataLayer } from "../../components/chart/NoDataLayer"
import { ApiTypes } from "../../types"
import styles from "./index.module.scss"

Chart.register(...registerables)

interface Props {
  settings: {
    ticksCount: number
    dataCountsDivider: number
  }
  historicalData: ApiTypes.Res.MonitoringHistoricalDataBucket[]
  isSingleDataPoint: boolean
}

const AirQualityChart = ({ settings, historicalData, isSingleDataPoint }: Props) => {
  const parsedData =
    historicalData.map((item) => ({
      airQuality: item["avg#average_current_air_quality"].value,
      timestamp: item.key_as_string,
    })) || []

  const data = {
    labels: [...parsedData.map((item) => item.timestamp)],
    datasets: [
      {
        label: "",
        data: parsedData.map((item) => item.airQuality),
        fill: "start",
        backgroundColor: (context: ScriptableContext<"line">) =>
          getChartGradient(context, "rgba(126,34,226,0.5)", "rgba(255,255,255,0)"),
        borderColor: "#a654f1",
        pointStyle: isSingleDataPoint as unknown as "circle",
        pointBackgroundColor: "#fff",
        spanGaps: true,
        segment: {
          borderDash: borderDashWithSkipped,
          borderColor: borderColorWithSkipped,
        },
      },
      ...(parsedData.length === 0
        ? [
            {
              label: "",
              data: [1, 2, 3],
            },
          ]
        : []),
    ],
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        ...defaultChartTooltipOptions,
        callbacks: {
          title: () => {
            return ""
          },
          label: function (this: any, tooltipItem) {
            const date = dayjs(tooltipItem.label)
            const label = `${tooltipItem.formattedValue} ${date.format("YYYY-MM-DD HH:mm")}`

            return label
          },
        },
      },
    },
    scales: {
      x: {
        border: {
          color: "#e2e8f0",
        },
        grid: {
          color: "#e2e8f0",
        },
        ticks: {
          maxRotation: 0,
          maxTicksLimit: settings.ticksCount,
          color: "#94A3B8",
          callback: (value) => value / settings.dataCountsDivider,
        },
      } as any,
      y: {
        position: "left",
        border: {
          display: false,
          color: "rgba(0,0,0,0)",
        },
        ticks: {
          color: "#94A3B8",
        },
        beginAtZero: true,
        suggestedMax: 5,
        afterTickToLabelConversion: (ctx) => {
          ctx.ticks = [
            { value: 1, label: "Good" },
            { value: 2, label: "Fair" },
            { value: 3, label: "Bad" },
          ]
        },
      } as any,
    },
    onHover: (ctx) => {
      ctx.chart.data.datasets[0].pointStyle = true
    },
  }

  return (
    <div className={classNames(styles.chartContainer, styles.airQualityChart)}>
      {parsedData.length === 0 && <NoDataLayer />}
      <Line data={data} options={options} />
    </div>
  )
}

export default AirQualityChart
