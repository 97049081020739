import { v4 as uuidv4 } from "uuid"
import { transformText } from "../../../utils/transformText"
import { UITypes } from "../../../types"
import useUrlQueryParams from "../../../hooks/useUrlQueryParams"
import styles from "./index.module.scss"
import classNames from "classnames"

interface FilterTagProps {
  additionalTags?: UITypes.AdditionalTag[]
  clearAdditionalTags?: () => void
  skippedTags?: string[]
  headerCells: UITypes.TableHeaderCell[]
}

export const FilterTags = ({ additionalTags, clearAdditionalTags, skippedTags = [], headerCells }: FilterTagProps) => {
  const { urlQueryParams, setUrlQueryParams } = useUrlQueryParams()
  const orderHeaderCell = headerCells.find((item) => item.name === urlQueryParams["orderColumn"])
  const hasSortTag = orderHeaderCell && urlQueryParams["orderType"]
  const tags = headerCells
    .filter((item) => !["checkbox"].includes(item.type || "") && !skippedTags.includes(item.name || ""))
    .filter((item) => Object.hasOwn(urlQueryParams, item.name || "") && urlQueryParams[item.name || ""] !== "")

  const getFilterValue = (item: UITypes.TableHeaderCell) => {
    const value = item.name || ""

    if (item.type === "select") {
      return item.options?.find((option) => option.value === urlQueryParams[value])?.label
    }

    return transformText(urlQueryParams[value] || "")
  }

  const handleFilterItemClear = (key: string) => {
    if (key === "") {
      clearAdditionalTags?.()
      return
    }
    setUrlQueryParams({ [key]: null, ...(key === "orderColumn" ? { orderType: null } : {}) })
  }

  const renderFilterTags = (
    key: string | undefined,
    label: string,
    itemClearFunction?: (key: string) => void,
    value?: string,
  ) => {
    return (
      <li className={styles.filterTag} key={key}>
        <span className={styles.filterTagName}>{label}</span>
        <span className={styles.filterTagValues}>{value}</span>
        {itemClearFunction && (
          <span onClick={() => itemClearFunction(key || "")} className={styles.filterTagClose}></span>
        )}
      </li>
    )
  }

  const renderAdditionalTags = () =>
    additionalTags?.map((item) => {
      const values = item.values
      const shownValues = [...values.slice(0, 3), ...(values.length - 3 > 0 ? [`+${values.length - 3}`] : [])]

      return renderFilterTags(uuidv4(), item.label, clearAdditionalTags, shownValues.join(", "))
    })

  const renderSortTag = () => {
    if (!urlQueryParams["orderType"] || !urlQueryParams["orderColumn"] || !orderHeaderCell) {
      return null
    }

    return renderFilterTags(
      "orderColumn",
      orderHeaderCell.label || orderHeaderCell.title,
      handleFilterItemClear,
      urlQueryParams["orderType"] || "",
    )
  }

  if (tags.length || additionalTags?.length || hasSortTag) {
    return (
      <div className={styles.filterTagsWrapper}>
        <h3 className={styles.filterTitle}>Selected filter(s)</h3>
        <ul className={styles.filterTagsList}>
          {renderAdditionalTags()}
          {renderSortTag()}
          {tags.map((item) =>
            renderFilterTags(item.name, item.label || item.title, handleFilterItemClear, getFilterValue(item)),
          )}
        </ul>
      </div>
    )
  }

  return null
}
