const downloadFile = (data: string[], filename: string, type = "text/csv") => {
  const blob = new Blob(data, { type: type })

  const url = URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.setAttribute("href", url)
  a.setAttribute("download", filename)

  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export default downloadFile
