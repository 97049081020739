import { FormEvent, SetStateAction, useState } from "react"
import classNames from "classnames"
import ModalSectionTitle from "../../../components/common/ModalSectionTitle/ModalSectionTitle"
import { UITypes } from "../../../types"
import { ToggleButton } from "../../../components/form/ToggleButton"
import SubscriptionPlan from "../../../types/enums/SubscriptionPlan"
import { SelectStatic } from "../../../components/form/SelectStatic"
import styles from "./index.module.scss"
import { useAppSelector } from "../../../store/hooks"

const subscriptionPlanOptions = [
  { value: SubscriptionPlan.NETWORK, label: "Nuve Network" },
  { value: SubscriptionPlan.PLUS, label: "Nuve Plus" },
  { value: SubscriptionPlan.BASIC, label: "Nuve Basic" },
] as UITypes.Option[]

interface Props {
  inputValues: UITypes.Contractor
  isCreate?: boolean
  onInputChange: (event: FormEvent<HTMLInputElement>) => void
  setInputValues: (value: SetStateAction<UITypes.Contractor>) => void
}

const SubscriptionSection = ({ inputValues, isCreate, onInputChange, setInputValues }: Props) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const currentPlan =
    subscriptionPlanOptions.find((option) => option.value === inputValues.subscriptionPlan) ||
    subscriptionPlanOptions[2]

  const handleChange = (values: UITypes.Option[]) => {
    if (!values.length) return

    setInputValues((prevState) => {
      return { ...prevState, subscriptionPlan: values[0]?.value as SubscriptionPlan }
    })
  }

  return (
    <div>
      <ModalSectionTitle title="Subscription plan" />
      <div className={classNames(styles.row, styles.subscriptionRow)}>
        <div className={styles.subscriptionPlanName}>
          <div>{currentPlan?.label}</div>
          <div onClick={() => setMenuIsOpen(true)}>Monthly</div>
        </div>
        <div className={styles.subscriptionDropdownContainer}>
          <SelectStatic
            placeholder="Select subscription plan"
            values={[currentPlan]}
            options={subscriptionPlanOptions}
            onChange={handleChange}
            contentRenderer={() => <>Change plan</>}
            backspaceDelete={false}
          />
        </div>
      </div>
      {!isCreate && (
        <>
          <div className={classNames(styles.row, styles.subscriptionRow)}>
            <div className={classNames(styles.modalLine, styles.subscriptionSectionModalLine)}></div>
          </div>
          <div className={styles.row}>
            <div className={styles.toggleFieldWrapper}>
              <ToggleButton id="isDisable" name="isDisable" isChecked={inputValues.isDisable} onChange={onInputChange}>
                Disable contractor
              </ToggleButton>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default SubscriptionSection
