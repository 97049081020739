import styles from "./index.module.scss"
import { createPortal } from "react-dom"
import { ReactComponent as IconAcknowledge } from "../../assets/svg/icon-modal-acknowledge.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import classNames from "classnames"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { useLazyPostAlertAcknowledgeQuery } from "../../store/api/alertsApi"
import { setCurrentAlert } from "../../store/tempDataSlise"
import { toast } from "react-toastify"
import { useState } from "react"
import {
  useLazyGetAdminAlertsQuery,
  useLazyGetVendorAlertAndDeviceWidgetQuery,
  useLazyGetVendorStatusesWidgetQuery,
} from "../../store/api/adminApi"
import useUrlQueryParams from "../../hooks/useUrlQueryParams"

interface Props {
  isFetching?: boolean
  onCancel?: () => void
  onConfirm?: (event) => void
}

export const AlertsAcknowledgeModal = ({ onCancel }: Props) => {
  const [triggerAlertAcknowledge, { isFetching }] = useLazyPostAlertAcknowledgeQuery()
  const [triggerGetVendorStatusesWidget] = useLazyGetVendorStatusesWidgetQuery()
  const [triggerGetVendorAlertAndDeviceWidget] = useLazyGetVendorAlertAndDeviceWidgetQuery()
  const [triggerGetAdminAlerts] = useLazyGetAdminAlertsQuery()
  const dispatch = useAppDispatch()
  const currentAlert = useAppSelector((state) => state.tempData.currentAlert)
  const { urlQueryParams } = useUrlQueryParams()
  const [period, setPeriod] = useState("6h")

  const onSubmit = async (event) => {
    event.preventDefault()

    try {
      await triggerAlertAcknowledge({
        id: currentAlert?.id!,
        until: period,
      })

      toast.success("Alert has been successfully acknowledged!")
      dispatch(setCurrentAlert(undefined))
      triggerGetAdminAlerts(urlQueryParams)
      triggerGetVendorStatusesWidget()
      triggerGetVendorAlertAndDeviceWidget()
      onCancel && onCancel()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconAcknowledge />
            <h3 className={styles.modalTitle}>Acknowledge</h3>
            <div className={styles.modalMessage}>
              Please choose the duration for which the alert will stay in the acknowledged status. During this time,
              alerts on the device will be temporarily paused.
            </div>
            <div className={styles.separator} />
            <div className={styles.periodButtonsWrapper}>
              <span
                className={classNames(styles.periodButton, { [styles.active]: period === "6h" })}
                onClick={() => setPeriod("6h")}
              >
                6 hr
              </span>
              <span
                className={classNames(styles.periodButton, { [styles.active]: period === "12h" })}
                onClick={() => setPeriod("12h")}
              >
                12 hr
              </span>
              <span
                className={classNames(styles.periodButton, { [styles.active]: period === "1d" })}
                onClick={() => setPeriod("1d")}
              >
                1 day
              </span>
              <span
                className={classNames(styles.periodButton, { [styles.active]: period === "3d" })}
                onClick={() => setPeriod("3d")}
              >
                3 days
              </span>
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
