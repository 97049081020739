import { useState } from "react"
import classNames from "classnames"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { showTransferModal, showMissingModalForContractor } from "../../../store/modalSlise"
import { Pagination } from "../../../components/common/Pagination"
import { WidgetInventory } from "../../../components/widgets/WidgetInventory"
import { WidgetMonitoring } from "../../../components/widgets/WidgetMonitoring"
import { WidgetStatus } from "../../../components/widgets/WidgetStatus"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { convertDevicesToDashboardTableRows } from "../../../utils/convertData"
import { setSelectedDeviceIDs } from "../../../store/tempDataSlise"
import InventoryConfirmationModal from "./InventoryConfirmationModal"
import PreinstalledConfirmationModal from "./PreInstalledConfirmationModal"
import { ApiTypes, UITypes } from "../../../types"
import TableWrapper from "../../../components/table/TableWrapper"
import styles from "./index.module.scss"
import { ButtonNew } from "../../../components/form/ButtonNew"
import styled from "styled-components"

const ExportButton = styled(ButtonNew)`
  position: absolute;
  top: 20px;
  right: 20px;
`

interface ContractorDashboardPageContentProps {
  devices: ApiTypes.Model.Device[]
  tableHeaderCells: UITypes.TableHeaderCell[]
  isFetching: boolean
  meta?: ApiTypes.Model.Pagination
  onExport: () => void
  onEditColumnsClick: () => void
}

const ContractorDashboardPageContent = ({
  devices,
  tableHeaderCells,
  isFetching,
  meta,
  onExport,
  onEditColumnsClick,
}: ContractorDashboardPageContentProps) => {
  const dispatch = useAppDispatch()
  const selectedDeviceIDs = useAppSelector((state) => state.tempData.selectedDeviceIDs)
  const selectedDevices = devices.filter((device) => selectedDeviceIDs.includes(device.id))
  const [isHeaderChecked, setHeaderChecked] = useState(false)
  const [showInventoryModal, setShowInventoryModal] = useState(false)
  const [showPreInstalledModal, setShowPreInstalledModal] = useState(false)

  const toggleAllDevices = () => {
    if (isHeaderChecked) {
      dispatch(setSelectedDeviceIDs([]))
      setHeaderChecked(false)
    } else {
      const result = devices.map((item) => item?.id)
      result && dispatch(setSelectedDeviceIDs(result))
      setHeaderChecked(true)
    }
  }

  const onTransferClick = () => {
    dispatch(showTransferModal(true))
  }

  const onMissingClick = () => {
    dispatch(showMissingModalForContractor(true))
  }

  const onSelectDevice = (id?: number) => {
    if (selectedDeviceIDs?.some((item) => item === id)) {
      dispatch(setSelectedDeviceIDs(selectedDeviceIDs?.filter((item) => item !== id)))
    } else {
      dispatch(setSelectedDeviceIDs([...selectedDeviceIDs, ...[id]] as number[]))
    }
  }

  const handleInventoryClick = () => {
    setShowInventoryModal(true)
  }

  const handlePreInstalledClick = () => {
    setShowPreInstalledModal(true)
  }

  const handleCancel = () => {
    setShowInventoryModal(false)
    setShowPreInstalledModal(false)
  }

  return (
    <>
      <div className={classNames(styles.widgetsWrapper, styles.widgetsWrapperJustified)}>
        <WidgetInventory />
        <WidgetMonitoring />
        <WidgetStatus />
      </div>
      <ContentWrapper title="Devices">
        <nav className={styles.tableNavWrapper}>
          <ExportButton onClick={onExport} className={classNames(styles.tableNavItem)} disabled={meta?.total === 0}>
            Export
          </ExportButton>
          <div className={styles.tableNavButtonsWrapper}>
            <div className={styles.statusButtonWrapper}>
              <span className={styles.editColumnsButton} onClick={onEditColumnsClick}>
                Edit columns
              </span>
              {selectedDeviceIDs?.length ? (
                <>
                  <span className={classNames(styles.statusButton, styles.missing)} onClick={onMissingClick}>
                    Missing
                  </span>
                  <span className={classNames(styles.statusButton, styles.transfer)} onClick={onTransferClick}>
                    Transfer
                  </span>
                </>
              ) : (
                <>
                  <span className={classNames(styles.statusButton, styles.missing, styles.disabled)}>Missing</span>
                  <span className={classNames(styles.statusButton, styles.transfer, styles.disabled)}>Transfer</span>
                </>
              )}
              <span
                className={classNames(styles.statusButton, styles.inventory, {
                  [styles.disabled]:
                    selectedDevices.length === 0 ||
                    selectedDevices.some((device) => device.phisical_status !== "preinstalled"),
                })}
                onClick={handleInventoryClick}
              >
                In Inventory
              </span>
              <span
                className={classNames(styles.statusButton, styles.preinstalled, {
                  [styles.disabled]:
                    selectedDevices.length === 0 ||
                    selectedDevices.some((device) => device.phisical_status !== "inventory"),
                })}
                onClick={handlePreInstalledClick}
              >
                Pre-Installed
              </span>
            </div>
          </div>
        </nav>
        <TableWrapper
          headerCells={tableHeaderCells}
          areHeaderColumnsDynamic
          bodyRows={convertDevicesToDashboardTableRows(devices, tableHeaderCells)}
          isLoading={isFetching}
          onCheckboxChange={onSelectDevice}
          onHeaderCheckboxChange={toggleAllDevices}
          currentItemIDs={selectedDeviceIDs}
          isHeaderChecked={isHeaderChecked}
        />
        <Pagination meta={meta} />
      </ContentWrapper>
      {showInventoryModal && <InventoryConfirmationModal onCancel={handleCancel} selectedDevices={selectedDevices} />}
      {showPreInstalledModal && (
        <PreinstalledConfirmationModal onCancel={handleCancel} selectedDevices={selectedDevices} />
      )}
    </>
  )
}

export default ContractorDashboardPageContent
