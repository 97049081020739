import { Bar } from "react-chartjs-2"
import { Chart, registerables } from "chart.js"
import { ApiTypes } from "../../types"
import styles from "./index.module.scss"

Chart.register(...registerables)

interface TemperatureBarChartProps {
  ticksCount: number
  selectedTime: string | number | boolean
  historicalData: ApiTypes.Res.MonitoringHistoricalDataBucket[]
}

const settings = {
  lastHour: {
    ticksCount: 1,
    labelY: "Runtime (minutes)",
    labelX: "Hours",
    barWidth: 0.4,
    intervalItemsCount: 60,
    hourlyDivider: 1,
  },
  last6Hours: {
    ticksCount: 6,
    labelY: "Runtime (minutes)",
    labelX: "Hours",
    barWidth: 0.7,
    intervalItemsCount: 20,
    hourlyDivider: 1,
  },
  last24Hours: {
    ticksCount: 24,
    labelY: "Runtime (minutes)",
    labelX: "Hours",
    barWidth: 0.7,
    intervalItemsCount: 6,
    hourlyDivider: 1,
  },
  yesterday: {
    ticksCount: 24,
    labelY: "Runtime (minutes)",
    labelX: "Hours",
    barWidth: 0.7,
    intervalItemsCount: 6,
    hourlyDivider: 1,
  },
  last3Days: {
    ticksCount: 3,
    labelY: "Runtime (hourly)",
    labelX: "Days",
    barWidth: 0.5,
    intervalItemsCount: 48,
    hourlyDivider: 60,
  },
  last7Days: {
    ticksCount: 7,
    labelY: "Runtime (hourly)",
    labelX: "Days",
    barWidth: 0.7,
    intervalItemsCount: 24,
    hourlyDivider: 60,
  },
  last30Days: {
    ticksCount: 30,
    labelY: "Runtime (hourly)",
    labelX: "Days",
    barWidth: 0.7,
    intervalItemsCount: 6,
    hourlyDivider: 60,
  },
  custom: {
    ticksCount: 0,
    labelY: "Runtime (hourly)",
    labelX: "Days",
    barWidth: 0.7,
    intervalItemsCount: 6,
    hourlyDivider: 60,
  },
}

const plugins = [
  {
    id: "legendMargin",
    beforeInit: (chart: Chart) => {
      const fitValue = chart.legend?.fit
      if (fitValue) {
        chart.legend!.fit = function () {
          fitValue.bind(chart.legend)()
          this.height += 8
        }
      }
    },
  },
]

const TemperatureBarChart = ({ selectedTime, historicalData, ticksCount }: TemperatureBarChartProps) => {
  const selectedSettings = settings[selectedTime as keyof typeof settings]
  const ticks = ticksCount || selectedSettings.ticksCount

  const barChartsData = (
    historicalData.reduce(
      (acc, item, index) => {
        if (index % selectedSettings.intervalItemsCount === 0) {
          acc.push({
            coolingStage1: 0,
            coolingStage2: 0,
            heatingStage1: 0,
            heatingStage2: 0,
            heatingStage3: 0,
          })
        }
        const lastItem = acc[acc.length - 1]
        lastItem.coolingStage1 += item["filters#current_cooling_stage_count"].buckets.count1.doc_count
        lastItem.coolingStage2 += item["filters#current_cooling_stage_count"].buckets.count2.doc_count
        lastItem.heatingStage1 += item["filters#current_heating_stage_count"].buckets.count1.doc_count
        lastItem.heatingStage2 += item["filters#current_heating_stage_count"].buckets.count2.doc_count
        lastItem.heatingStage3 += item["filters#current_heating_stage_count"].buckets.count3.doc_count

        return acc
      },
      [] as {
        coolingStage1: number
        coolingStage2: number
        heatingStage1: number
        heatingStage2: number
        heatingStage3: number
      }[],
    ) || []
  ).map((item) => ({
    coolingStage1: item.coolingStage1 / selectedSettings.hourlyDivider,
    coolingStage2: item.coolingStage2 / selectedSettings.hourlyDivider,
    heatingStage1: item.heatingStage1 / selectedSettings.hourlyDivider,
    heatingStage2: item.heatingStage2 / selectedSettings.hourlyDivider,
    heatingStage3: item.heatingStage3 / selectedSettings.hourlyDivider,
  }))

  const labels = [...Array(ticks)].map((_, index) => index + 1)
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Cooling - stage 1",
        backgroundColor: "#92c5fd",
        data: barChartsData.map((item) => item.coolingStage1),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
      {
        label: "Cooling - stage 2",
        backgroundColor: "#2463eb",
        data: barChartsData.map((item) => item.coolingStage2),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
      {
        label: "Heating - stage 1",
        backgroundColor: "#fca5a5",
        data: barChartsData.map((item) => item.heatingStage1),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
      {
        label: "Heating - stage 2",
        backgroundColor: "#ef4444",
        data: barChartsData.map((item) => item.heatingStage2),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
      {
        label: "Heating - stage 3",
        backgroundColor: "#b91c1c",
        data: barChartsData.map((item) => item.heatingStage3),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
    ].filter((item) => item.data.some((value) => value !== 0)),
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: true,
        align: "start" as any,
        labels: {
          color: "#94A3B8",
          usePointStyle: true,
          pointStyle: "rect",
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,

        title: {
          display: true,
          text: selectedSettings.labelX,
          color: "#94A3B8",
        },
        ticks: {
          color: "#94A3B8",
          // TODO: Fix ticks count for backend data
          maxTicksLimit: ticks + 1,
        },
      },
      y: {
        title: {
          display: true,
          text: selectedSettings.labelY,
          color: "#94A3B8",
        },
        stacked: true,
        ticks: {
          maxTicksLimit: 6,
          color: "#94A3B8",
        },
      },
    },
  }

  return (
    <div className={styles.barChartContainer}>
      <Bar data={data} options={options} plugins={plugins} />
    </div>
  )
}

export default TemperatureBarChart
