import { createPortal } from "react-dom"
import { toast } from "react-toastify"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconContractor } from "../../assets/svg/modal-icon-contractor.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { setCurrentZip } from "../../store/tempDataSlise"
import { UITypes } from "../../types"
import styles from "../EditContractorModal/index.module.scss"
import { useLazyCreateContractorQuery, useLazyGetContractorsQuery } from "../../store/api/contractorsApi"
import { ContractorModalContent } from "../components/contractorModalContent"
import SubscriptionPlan from "../../types/enums/SubscriptionPlan"
import useUrlQueryParams from "../../hooks/useUrlQueryParams"

const inputValuesInitialState: UITypes.Contractor = {
  name: "",
  brand: "",
  parentGroup: [],
  address1: "",
  address2: "",
  zip: [],
  state: [],
  city: [],
  url: "",
  schedule: "",
  fullName: "",
  phone: "",
  email: "",
  isDisable: false,
  appStoreLink: "",
  googlePlayLink: "",
  phoneForSupport: true,
  phoneForSupportCall: "",
  phoneForSupportText: "",
  stClientID: "",
  stClientSecret: "",
  stAppKey: "",
  stTenant: "",
  subscriptionPlan: SubscriptionPlan.BASIC,
}

interface Props {
  onCancel: () => void
}

export const AddContractorModal = ({ onCancel }: Props) => {
  const dispatch = useAppDispatch()
  const [triggerGetContractors] = useLazyGetContractorsQuery()
  const [triggerCreateContractor, { isFetching }] = useLazyCreateContractorQuery()
  const { urlQueryParams } = useUrlQueryParams()

  const onSubmit = async (data: FormData) => {
    try {
      const res: any = await triggerCreateContractor(data)

      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Contractor has been successfully added!")
        triggerGetContractors(urlQueryParams)
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onCancelClick = () => {
    dispatch(setCurrentZip(undefined))
    onCancel()
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="770px"
          onClose={onCancelClick}
          closeIconClassName={styles.closeIconClassName}
          isDrawer
          isWithoutFooter
          isFetching={isFetching}
        >
          <ModalHeader title="Add Contractor" borderless withShadow>
            <IconContractor />
          </ModalHeader>
          <ContractorModalContent contractor={inputValuesInitialState} onSubmit={onSubmit} isCreate />
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
