import { ApiTypes } from "../../../types"

export const dummyPerfTestWidgetData: ApiTypes.Res.PerformanceTestWidgetData = {
  scheduled: 2,
  rescheduled: 2,
  passed: 2,
  issue: 2,
  running: 2,
}

export const dummyMeta: ApiTypes.Model.Pagination = {
  total: 2,
  per_page: 10,
  current_page: 1,
  last_page: 1,
  from: 1,
  to: 2,
  links: [],
}

export const dummyPerfTestDevices = [
  {
    client: {
      email: "example@example.com",
      homeowner_name: "John Doe",
    },
    device: {
      sn: "01-000-00002",
      address: "Test 234",
      activation_date: "2024-10-29",
      state: {
        id: 60,
        name: "Alaska",
        short: "AK",
        country_id: 1,
      },
      city: {
        id: 18486,
        name: "Indian",
        state_id: 60,
      },
      zip: {
        id: 41371,
        code: "99540",
        city_id: 18486,
      },
      country: {
        id: 1,
        name: "United States",
      },
      where_installed_name: "Basement",
    },
    perftest: {
      perftest_id: 191,
      perftest_result_id: 37,
      action: "heating",
      label: "My test",
      status: "Issue",
      time: "2024-11-07 06:23:08",
      temp_diff: -0.05060196,
    },
  },
  {
    client: {
      email: "example@example.com",
      homeowner_name: "Adam Smith",
    },
    device: {
      sn: "01-000-00001",
      address: "Test 123",
      activation_date: "2024-10-29",
      state: {
        id: 60,
        name: "Alaska",
        short: "AK",
        country_id: 1,
      },
      city: {
        id: 18486,
        name: "Indian",
        state_id: 60,
      },
      zip: {
        id: 41371,
        code: "99540",
        city_id: 18486,
      },
      country: {
        id: 1,
        name: "United States",
      },
      where_installed_name: "Basement",
    },
    perftest: {
      perftest_id: 191,
      perftest_result_id: 37,
      action: "heating",
      label: "My test",
      status: "Passed",
      time: "2024-11-07 06:23:08",
      temp_diff: 2.05060196,
    },
  },
] as unknown as ApiTypes.Model.PerformanceTestHistory[]
